import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';

// A grpc-web interceptor to handle authentication and authorization details.
export default async function getRpcOptions() {
  const interceptor = await Auth.getInterceptor();
  const options: RpcOptions = {
    interceptors: [interceptor],
  };
  return options;
}
