import {Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {Card, NotificationsIcon} from '@verily-src/react-design-system';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';
import styles from './Account.module.css';

type AccountProps = {
  email: string;
  isLoading: boolean;
};

export default function Account({email, isLoading}: AccountProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <div className={styles.accountContainer}>
      {isLoading ? (
        <div style={{marginBottom: '20px'}}>
          <PillSkeleton
            width={theme.spacing(17.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <Typography
          component={'h2'}
          sx={{marginBottom: '20px'}}
          variant="display6"
        >
          {t('my-account')}
        </Typography>
      )}
      <Box sx={{width: '100%'}}>
        <Stack spacing={1}>
          <Card
            title={isLoading ? '' : t('email-box')}
            subtitle={email}
            data-testid="email-container"
            useUnlaunchedComponent
            loadingVisual={
              isLoading ? (
                <Stack spacing={1}>
                  <PillSkeleton
                    width={theme.spacing(8)}
                    height={theme.spacing(2)}
                  />
                  <PillSkeleton
                    width={theme.spacing(22.5)}
                    height={theme.spacing(2)}
                  />
                </Stack>
              ) : undefined
            }
          />
          <Card
            pendoId="update-password-button"
            title={isLoading ? '' : t('password-box')}
            action={{
              text: !isLoading ? t('update-password-button') : undefined,
              'aria-label': 'update password',
              onClick: () => {
                if (!isLoading) {
                  window.location.href = '/me/profile/update-password';
                }
              },
              hasIcon: true,
            }}
            data-testid="password-container"
            useUnlaunchedComponent
            loadingVisual={
              isLoading ? (
                <PillSkeleton
                  width={theme.spacing(13)}
                  height={theme.spacing(2)}
                />
              ) : undefined
            }
          />
          <Card
            pendoId="notification-preferences-button"
            title={isLoading ? '' : t('notification-preferences')}
            action={{
              onClick: () => {
                if (!isLoading) {
                  window.location.href = '/me/profile/notification-preferences';
                }
              },
              hasIcon: true,
              'aria-label': isLoading ? t('spinner-label') : undefined,
            }}
            data-testid="notification-preferences-container"
            useUnlaunchedComponent
            IconComponent={
              !isLoading ? (
                <NotificationsIcon sx={{color: theme.palette.primary.main}} />
              ) : undefined
            }
            loadingVisual={
              isLoading ? (
                <PillSkeleton
                  width={theme.spacing(13)}
                  height={theme.spacing(2)}
                />
              ) : undefined
            }
          />
        </Stack>
      </Box>
    </div>
  );
}
