/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "phr/past-events/bff/api/v1/past-events_bff.proto" (package "phr.past_events.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// (-- api-linter: core::0191::proto-package=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
// (-- api-linter: core::0191::filenames=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Represents all the relevant metadata of an Encounter FHIR Resource to be
 * displayed in both the Past Events Landing page and the Past Events Section
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.EncounterDetails
 */
export interface EncounterDetails {
    /**
     * AIP resource name for the Encounter
     *
     * Format: "encounter/{encounter_id}" is equivalent to the FHIR
     * Encounter path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The start time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string start_time = 2;
     */
    startTime: string;
    /**
     * The end time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string end_time = 11;
     */
    endTime: string;
    /**
     * The name of the Practitioner from Zus FHIR
     * (-- api-linter: core::0122::name-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string practitioner_name = 3;
     */
    practitionerName: string;
    /**
     * The specialty of the Practitioner from Zus FHIR
     *
     * @generated from protobuf field: string practitioner_specialty = 4;
     */
    practitionerSpecialty: string;
    /**
     * The encounter type: (TBD - https://www.hl7.org/fhir/valueset-encounter-type.html)
     *
     * @generated from protobuf field: string type = 5;
     */
    type: string;
    /**
     * The encounter classification: (TBD - https://terminology.hl7.org/5.1.0/ValueSet-encounter-class.html)
     *
     * @generated from protobuf field: string classification = 6;
     */
    classification: string;
    /**
     * The organization where encounter took place (TBD - Encounter.location.managingOrganization.name)
     *
     * @generated from protobuf field: string organization = 7;
     */
    organization: string;
    /**
     * The location of the organization where encounter took place (TBD - Encounter.location.display)
     *
     * @generated from protobuf field: string location = 8;
     */
    location: string;
    /**
     * The reason for the encounter
     *
     * @generated from protobuf field: string reason = 9;
     */
    reason: string;
    /**
     * Provider notes from the visit (TBD on if this is needed)
     *
     * @generated from protobuf field: string visit_notes = 10;
     */
    visitNotes: string;
}
/**
 * Request to get the N most recent encounters for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest
 */
export interface GetLatestEncountersDetailsRequest {
    /**
     * The number of encounters to display in the snapshot, likely configured to be 3
     * (-- api-linter: core::0141::count-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: int32 num_encounters = 1;
     */
    numEncounters: number;
}
/**
 * Request to get all encounters for the current participant.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest
 */
export interface GetAllEncountersDetailsRequest {
    /**
     * The maximum number of encounters to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 encounters will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListEncounters` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListEncounters` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of EncounterDetails.
 *
 * @generated from protobuf message phr.past_events.bff.api.v1.GetEncounterDetailsListResponse
 */
export interface GetEncounterDetailsListResponse {
    /**
     * The list of EncounterDetails.
     *
     * @generated from protobuf field: repeated phr.past_events.bff.api.v1.EncounterDetails encounter_details = 1;
     */
    encounterDetails: EncounterDetails[];
}
// @generated message type with reflection information, may provide speed optimized methods
class EncounterDetails$Type extends MessageType<EncounterDetails> {
    constructor() {
        super("phr.past_events.bff.api.v1.EncounterDetails", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "practitioner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "practitioner_specialty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "classification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "visit_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "google.api.resource": { type: "verily.health/Encounter", pattern: ["encounter/{encounter_id}"], plural: "encounters", singular: "encounter" } });
    }
    create(value?: PartialMessage<EncounterDetails>): EncounterDetails {
        const message = { name: "", startTime: "", endTime: "", practitionerName: "", practitionerSpecialty: "", type: "", classification: "", organization: "", location: "", reason: "", visitNotes: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EncounterDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EncounterDetails): EncounterDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string start_time */ 2:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 11:
                    message.endTime = reader.string();
                    break;
                case /* string practitioner_name */ 3:
                    message.practitionerName = reader.string();
                    break;
                case /* string practitioner_specialty */ 4:
                    message.practitionerSpecialty = reader.string();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                case /* string classification */ 6:
                    message.classification = reader.string();
                    break;
                case /* string organization */ 7:
                    message.organization = reader.string();
                    break;
                case /* string location */ 8:
                    message.location = reader.string();
                    break;
                case /* string reason */ 9:
                    message.reason = reader.string();
                    break;
                case /* string visit_notes */ 10:
                    message.visitNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EncounterDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string start_time = 2; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 11; */
        if (message.endTime !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.endTime);
        /* string practitioner_name = 3; */
        if (message.practitionerName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.practitionerName);
        /* string practitioner_specialty = 4; */
        if (message.practitionerSpecialty !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.practitionerSpecialty);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        /* string classification = 6; */
        if (message.classification !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classification);
        /* string organization = 7; */
        if (message.organization !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.organization);
        /* string location = 8; */
        if (message.location !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.location);
        /* string reason = 9; */
        if (message.reason !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.reason);
        /* string visit_notes = 10; */
        if (message.visitNotes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.visitNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.EncounterDetails
 */
export const EncounterDetails = new EncounterDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestEncountersDetailsRequest$Type extends MessageType<GetLatestEncountersDetailsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest", [
            { no: 1, name: "num_encounters", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetLatestEncountersDetailsRequest>): GetLatestEncountersDetailsRequest {
        const message = { numEncounters: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLatestEncountersDetailsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLatestEncountersDetailsRequest): GetLatestEncountersDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 num_encounters */ 1:
                    message.numEncounters = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLatestEncountersDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 num_encounters = 1; */
        if (message.numEncounters !== 0)
            writer.tag(1, WireType.Varint).int32(message.numEncounters);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest
 */
export const GetLatestEncountersDetailsRequest = new GetLatestEncountersDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllEncountersDetailsRequest$Type extends MessageType<GetAllEncountersDetailsRequest> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllEncountersDetailsRequest>): GetAllEncountersDetailsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllEncountersDetailsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllEncountersDetailsRequest): GetAllEncountersDetailsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllEncountersDetailsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest
 */
export const GetAllEncountersDetailsRequest = new GetAllEncountersDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEncounterDetailsListResponse$Type extends MessageType<GetEncounterDetailsListResponse> {
    constructor() {
        super("phr.past_events.bff.api.v1.GetEncounterDetailsListResponse", [
            { no: 1, name: "encounter_details", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EncounterDetails }
        ]);
    }
    create(value?: PartialMessage<GetEncounterDetailsListResponse>): GetEncounterDetailsListResponse {
        const message = { encounterDetails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetEncounterDetailsListResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEncounterDetailsListResponse): GetEncounterDetailsListResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.past_events.bff.api.v1.EncounterDetails encounter_details */ 1:
                    message.encounterDetails.push(EncounterDetails.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEncounterDetailsListResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.past_events.bff.api.v1.EncounterDetails encounter_details = 1; */
        for (let i = 0; i < message.encounterDetails.length; i++)
            EncounterDetails.internalBinaryWrite(message.encounterDetails[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.past_events.bff.api.v1.GetEncounterDetailsListResponse
 */
export const GetEncounterDetailsListResponse = new GetEncounterDetailsListResponse$Type();
/**
 * @generated ServiceType for protobuf service phr.past_events.bff.api.v1.PastEventsBFFService
 */
export const PastEventsBFFService = new ServiceType("phr.past_events.bff.api.v1.PastEventsBFFService", [
    { name: "GetLatestEncountersDetails", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetLatestEncountersDetailsRequest, O: GetEncounterDetailsListResponse },
    { name: "GetAllEncountersDetails", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllEncountersDetailsRequest, O: GetEncounterDetailsListResponse }
]);
