// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pastEventsContainer__yDK3u{margin-top:8px;padding-top:16px}.pastEventsContainer__yDK3u:focus{border-radius:8px;outline:2px solid HIGHLIGHT}`, "",{"version":3,"sources":["webpack://./src/components/PastEventsSectionContent/PastEventsSectionContent.module.css"],"names":[],"mappings":"AAAA,4BACE,cAAe,CACf,gBACF,CAEA,kCACE,iBAAkB,CAClB,2BACF","sourcesContent":[".pastEventsContainer {\n  margin-top: 8px;\n  padding-top: 16px;\n}\n\n.pastEventsContainer:focus {\n  border-radius: 8px;\n  outline: 2px solid HIGHLIGHT;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pastEventsContainer": `pastEventsContainer__yDK3u`
};
export default ___CSS_LOADER_EXPORT___;
