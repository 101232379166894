// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pastEventsContainer__igfj5{margin-top:8px;padding-top:16px}`, "",{"version":3,"sources":["webpack://./src/pages/PastEventsSectionPage/PastEventsSectionPage.module.css"],"names":[],"mappings":"AAAA,4BACE,cAAe,CACf,gBACF","sourcesContent":[".pastEventsContainer {\n  margin-top: 8px;\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pastEventsContainer": `pastEventsContainer__igfj5`
};
export default ___CSS_LOADER_EXPORT___;
