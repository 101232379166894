import {Typography} from '@mui/material';
import {TextField} from '@verily-src/react-design-system';
import {UserType} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import type {FieldValues, Path} from 'react-hook-form';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {userTypeSpecificI18Namespace} from '../../../i18n_helpers';

type ContactInfoFormProps = {
  collectPhone: boolean;
  collectEmail: boolean;
  userType: UserType;
  subheader: string;
};

/**
 * Subform layout for collecting user's contact info (phone and email)
 */
export default function ContactInfoFormLayout<T extends FieldValues>({
  collectPhone,
  collectEmail,
  userType,
  subheader,
}: ContactInfoFormProps) {
  const {control, formState} = useFormContext();
  const {t} = useTranslation();

  const userTypeSpecificContentNs = userTypeSpecificI18Namespace(userType);

  return (
    <>
      <p>
        <Typography
          variant="body1em"
          component="h2"
          gutterBottom={
            !(
              subheader ||
              t('user-info-form.contact-info-subheader', {
                ns: userTypeSpecificContentNs,
              })
            )
          }
        >
          {t('user-info-form.contact-info-header', 'Contact Information', {
            ns: userTypeSpecificContentNs,
          })}
        </Typography>
      </p>
      {(subheader ||
        t('user-info-form.contact-info-subheader', '', {
          ns: userTypeSpecificContentNs,
        })) && (
        <Typography variant="caption" gutterBottom>
          {subheader ||
            t('user-info-form.contact-info-subheader', {
              ns: userTypeSpecificContentNs,
            })}
        </Typography>
      )}

      {collectEmail && (
        <Controller
          control={control}
          name={'email' as Path<T>}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <>
              <TextField
                required
                data-cy="email"
                {...field}
                label={t('patientData.email')}
                placeholder={t('patientData.email-placeholder')}
                disabled={formState.isSubmitting}
                error={!!error}
                helperText={error?.message}
                style={{width: '70%'}}
                multiline={false}
              />
            </>
          )}
        />
      )}
      {collectPhone && (
        <Controller
          control={control}
          name={'phone' as Path<T>}
          render={({field: {ref: _, ...field}, fieldState: {error}}) => (
            <>
              <TextField
                required
                data-cy="phone-number"
                {...field}
                label={t('patientData.phone-number')}
                placeholder={t('patientData.phone-number-placeholder')}
                disabled={formState.isSubmitting}
                error={!!error}
                helperText={error?.message}
                style={{width: '70%'}}
                multiline={false}
              />
            </>
          )}
        />
      )}
    </>
  );
}
