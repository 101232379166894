import {Box, useTheme} from '@mui/material';
import {Card, ExitToAppIcon} from '@verily-src/react-design-system';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';

type LogOutProps = {
  isLoading: boolean;
  onLogOut: () => void;
};

const LogOut = ({isLoading, onLogOut}: LogOutProps) => {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <>
      {isLoading && (
        <Box sx={{paddingBottom: theme.spacing(3)}}>
          <PillSkeleton
            width={theme.spacing(27.5)}
            height={theme.spacing(3.5)}
          />
        </Box>
      )}
      <Card
        pendoId="log-out-button"
        action={{
          hasIcon: isLoading,
          'aria-label': 'Log Out',
          onClick: isLoading ? () => {} : onLogOut,
        }}
        title={isLoading ? '' : t('log-out-button')}
        IconComponent={!isLoading ? <ExitToAppIcon /> : undefined}
        color={theme.palette.error.main}
        useUnlaunchedComponent
        loadingVisual={
          isLoading ? (
            <PillSkeleton width={theme.spacing(20)} height={theme.spacing(2)} />
          ) : undefined
        }
      />
    </>
  );
};

export default LogOut;
