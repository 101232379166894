import {Box, Stack, Typography, useTheme} from '@mui/material';
import {BiotechIcon, Card} from '@verily-src/react-design-system';
import {
  ResearchStudyOverview,
  ResearchStudyOverview_StudyStatus,
} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';

type ResearchStudiesProps = {
  researchStudies: ResearchStudyOverview[];
  onClick: (researchStudy: ResearchStudyOverview) => void;
  isLoading: boolean;
};

export default function ResearchStudies({
  researchStudies,
  onClick,
  isLoading,
}: ResearchStudiesProps) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <div style={{marginTop: theme.spacing(2)}}>
      {isLoading ? (
        <div
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          <PillSkeleton
            width={theme.spacing(12.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <Typography
          component={'h2'}
          sx={{paddingY: theme.spacing(2)}}
          variant="display6"
        >
          {t('research')}
        </Typography>
      )}
      <Box
        sx={{width: '100%', paddingTop: theme.spacing(1)}}
        data-testid="study-container"
      >
        <Stack spacing={1}>
          {isLoading ? (
            <SkeletonStudies />
          ) : (
            // Order research studies by: Signed > Withdrawn > Declined
            researchStudies
              .sort(researchStudyComparator)
              .reduce((acc: JSX.Element[], study, idx) => {
                // Do not display new / unspecified studies
                if (
                  study.studyStatus !==
                  ResearchStudyOverview_StudyStatus.STUDY_STATUS_UNSPECIFIED
                ) {
                  acc.push(
                    <Card
                      key={idx}
                      data-testid="study-card"
                      title={study.displayName}
                      subtitle={study.studyStatusText}
                      useUnlaunchedComponent
                      pendo-vme-view-study-id={study.studyId}
                      pendo-vme-view-study-title={study.displayName}
                      pendo-vme-view-study-status={study.studyStatusText}
                      IconComponent={
                        <BiotechIcon sx={{color: theme.palette.primary.main}} />
                      }
                      action={{
                        onClick: () => onClick(study),
                        hasIcon: true,
                      }}
                    />
                  );
                }
                return acc;
              }, [])
          )}
        </Stack>
      </Box>
    </div>
  );
}

const SkeletonStudies = () => {
  const theme = useTheme();
  const {t} = useTranslation();
  return (
    <>
      <Card
        title={''}
        useUnlaunchedComponent
        action={{
          onClick: () => {},
          hasIcon: true,
          'aria-label': t('spinner-label'),
        }}
        loadingVisual={
          <PillSkeleton width={theme.spacing(22.5)} height={theme.spacing(2)} />
        }
      />
      <Card
        title={''}
        useUnlaunchedComponent
        action={{
          onClick: () => {},
          hasIcon: true,
          'aria-label': t('spinner-label'),
        }}
        loadingVisual={
          <PillSkeleton width={theme.spacing(22.5)} height={theme.spacing(2)} />
        }
      />
    </>
  );
};

/**
 * Compares two ResearchStudyOverview objects based on their study status.
 * Note: exported for testing purposes only
 *
 * @param {ResearchStudyOverview} a - The first ResearchStudyOverview object to compare.
 * @param {ResearchStudyOverview} b - The second ResearchStudyOverview object to compare.
 * @returns {number} A negative value if 'a' should be sorted before 'b',
 *                   a positive value if 'a' should be sorted after 'b',
 *                   and 0 if they are equal.
 */
export const researchStudyComparator = (
  a: ResearchStudyOverview,
  b: ResearchStudyOverview
): number => {
  const researchStudyOrder = (
    status: ResearchStudyOverview_StudyStatus
  ): number => {
    switch (status) {
      case ResearchStudyOverview_StudyStatus.SIGNED:
        return 1;
      case ResearchStudyOverview_StudyStatus.WITHDRAWN:
        return 2;
      case ResearchStudyOverview_StudyStatus.DECLINED:
        return 3;
      case ResearchStudyOverview_StudyStatus.STUDY_STATUS_UNSPECIFIED:
        return 4;
    }
  };
  return researchStudyOrder(a.studyStatus) - researchStudyOrder(b.studyStatus);
};
