import {
  Auth0Client,
  AuthorizationParams,
  LogoutOptions,
} from '@auth0/auth0-spa-js';
const TEST_ACCESS_TOKEN_KEY = 'test_access_token';

function isInTestMode(): boolean {
  try {
    return process.env.TEST_MODE === 'test';
  } catch (_) {
    return false;
  }
}

export class DeferredAuth0Client {
  private client: Auth0Client | null = null;
  private authorizationParams: AuthorizationParams | null = null;

  /**
   * Get the access token we should use for testing, if applicable
   * @return an access token, if we're in test mode and one is provided
   */
  private getTestAccessToken(): string | undefined {
    if (isInTestMode()) {
      const urlParams = new URLSearchParams(window.location.search);
      const queryParamTestToken = urlParams.get(TEST_ACCESS_TOKEN_KEY);
      const localstorageTestToken = localStorage.getItem(TEST_ACCESS_TOKEN_KEY);
      if (
        queryParamTestToken !== null &&
        localstorageTestToken !== null &&
        queryParamTestToken !== localstorageTestToken
      ) {
        throw new Error(
          "You set a test access token in query params *and* local storage, but they do not match. Auth MFE doesn't know which one to use"
        );
      }
      const testToken = queryParamTestToken ?? localstorageTestToken;
      if (testToken) {
        return testToken;
      }
    }
    return;
  }

  /**
   * Merges the provided params into the current ones
   * @param authorizationParams new auth params
   */
  public updateAuthorizationParams(authorizationParams: AuthorizationParams) {
    this.authorizationParams = {
      ...this.authorizationParams,
      ...authorizationParams,
    };
  }

  public async init(
    domain: string,
    clientId: string,
    authorizationParams: AuthorizationParams
  ) {
    this.updateAuthorizationParams(authorizationParams);
    if (this.getTestAccessToken() !== undefined) {
      return;
    }
    // Can use createAuth0Client instead
    // https://github.com/auth0/auth0-spa-js/blob/d72748dd09df195ac87b6cd2d9578faba3ae9d64/src/index.ts#L17-L21
    // But this somehow doesn't solve the problem of isAuthenticated() call
    // returning false without calling getTokenSilently() below.
    // TODO: If auth0-spa-js gets updated from 2.0.3, try createAuth0Client()
    // without getTokenSilently().
    this.client = new Auth0Client({
      domain,
      clientId,
      authorizationParams,
      cacheLocation: 'localstorage',
    });

    if (!isInTestMode()) {
      // Pre-fill the token cache before checking if authenticated,
      // similar to how auth0-spa-js.createAuth0Client() functions.
      // Tried auth0-spa-js.checkSession() but it doesn't work.
      // TODO: if auth0-spa-js gets updated from 2.0.3, try
      // auth0-spa-js.checkSession()
      // This is an empty try-catch block to mirror auth0-spa-js' checkSession
      // https://github.com/auth0/auth0-spa-js/blob/d72748dd09df195ac87b6cd2d9578faba3ae9d64/src/Auth0Client.ts#L579-L581
      //
      // NOTE(PHP-42126): This is required for the user state check
      // in AuthUnaryInterceptor.getAccessToken to properly function.
      try {
        await this.getTokenSilently();
      } catch (_) {}
    }
  }

  public async loginWithRedirect(extraQueryParams: Record<string, string>) {
    if (this.getTestAccessToken() !== undefined) {
      return;
    }
    return this.client?.loginWithRedirect({
      authorizationParams: {
        ...this.authorizationParams,
        ...extraQueryParams,
      },
    });
  }

  public async getTokenSilently() {
    const testAccessToken = this.getTestAccessToken();
    if (testAccessToken !== undefined) {
      return testAccessToken;
    }
    return this.client?.getTokenSilently();
  }

  public async getIdTokenClaims() {
    return this.client?.getIdTokenClaims();
  }

  public async isAuthenticated() {
    if (this.getTestAccessToken() !== undefined) {
      return true;
    }
    return this.client?.isAuthenticated();
  }

  public async handleRedirectCallback() {
    // When user logs in, CIAM is not deleting the PSAT cookie.
    // Instead, CIAM depends on the BFF to use the BFF library's PurgePSAT().
    return this.client?.handleRedirectCallback();
  }

  public async logout(options?: LogoutOptions | undefined) {
    return this.client?.logout(options);
  }

  async getUser() {
    return this.client?.getUser();
  }
}
