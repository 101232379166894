import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {
  GetPasswordResetUrlRequest,
  GetResearchStudyDetailsRequest,
  GetUserProfileRequest,
  PasswordResetUrl,
  ResearchStudyDetails,
  UserProfile,
} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service';
import {UserProfileServiceClient} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/user_profile_service.client';
import {createSessionCache} from '@verily-src/verily1-verily-me-web-common-typescript/src/services/CachedEndpoint';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class UserProfileService {
  private userProfileServiceClient!: UserProfileServiceClient;
  private userProfileCache = createSessionCache<UserProfile>(() =>
    this._getUserProfile()
  );

  async getClient() {
    if (!this.userProfileServiceClient) {
      const interceptors: RpcInterceptor[] = [
        await (api as typeof VerilyMeApi).auth.getInterceptor(),
      ];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.userProfileServiceClient = new UserProfileServiceClient(transport);
    }

    return this.userProfileServiceClient;
  }

  getUserProfile = this.userProfileCache.get;

  private async _getUserProfile(): Promise<UserProfile> {
    const request: GetUserProfileRequest = {};

    const userProfileServiceClient = await this.getClient();

    return (await userProfileServiceClient.getUserProfile(request)).response;
  }

  async getPasswordResetUrl(): Promise<PasswordResetUrl> {
    const request: GetPasswordResetUrlRequest = {};

    const userProfileServiceClient = await this.getClient();

    return (await userProfileServiceClient.getPasswordResetUrl(request))
      .response;
  }

  async getResearchStudyDetails(
    studyId: string,
    subjectId: string
  ): Promise<ResearchStudyDetails> {
    const request: GetResearchStudyDetailsRequest = {studyId, subjectId};
    const client = await this.getClient();
    const result = await client.getResearchStudyDetails(request);
    return result.response;
  }
}

export default new UserProfileService();
