import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {
  GetJiteConfigurationRequest,
  JiteConfiguration,
} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/configuration_service';
import {ConfigurationServiceClient} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/configuration_service.client';
import {createSessionCache} from '@verily-src/verily1-verily-me-web-common-typescript/src/services/CachedEndpoint';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {handleGrpcError} from './ErrorHandling';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class ConfigurationService {
  private configurationServiceClient!: ConfigurationServiceClient;
  private jiteConfigurationCache = createSessionCache<JiteConfiguration>(() =>
    this._getJiteConfiguration()
  );

  async getClient() {
    if (!this.configurationServiceClient) {
      const interceptors: RpcInterceptor[] = [
        await (api as typeof VerilyMeApi).auth.getInterceptor(),
      ];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.configurationServiceClient = new ConfigurationServiceClient(
        transport
      );
    }

    return this.configurationServiceClient;
  }

  getJiteConfiguration = this.jiteConfigurationCache.get;

  private async _getJiteConfiguration(): Promise<JiteConfiguration> {
    const request: GetJiteConfigurationRequest = {};

    const configurationServiceClient = await this.getClient();

    try {
      return (await configurationServiceClient.getJiteConfiguration(request))
        .response;
    } catch (err) {
      throw handleGrpcError(err);
    }
  }
}

export default new ConfigurationService();
