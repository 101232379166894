import {
  Address,
  AgreementConsentContent,
  GenderIdentity,
} from '@verily-src/verily1-protos/enrollment/bff/api/v1/server';
import {AppConfig} from '../lib/types/appConfig';
import {UserState} from '../lib/types/userState';

function compareBoolean(a: unknown) {
  if (typeof a === 'boolean') {
    return a;
  }
  if (typeof a === 'string') {
    return a === 'true';
  }
  return false;
}

function consentSpecificationIsNullOrEmpty(
  consent: AppConfig['consentSpecification'] | undefined
) {
  if (!consent) {
    return true;
  }

  if (!consent.consentId || !consent.consentRevision) {
    return true;
  }

  return false;
}

function getDateOfBirth(dobIsoString: string) {
  const dob = dobIsoString.substring(0, dobIsoString.indexOf('T'));
  return dob;
}

function getGenderIdentity(genderIdentityStr: string | undefined) {
  if (!genderIdentityStr || genderIdentityStr === '0') {
    return GenderIdentity.GENDER_IDENTITY_UNSPECIFIED;
  }
  return parseInt(genderIdentityStr);
}

function getAddressFromFormValues(formValues): Address {
  const address: Address = {
    streetAddress: [formValues.addressLine1, formValues.addressLine2 || ''],
    city: formValues.city,
    state: formValues.state,
    zipCode: formValues.zipCode,
    country: '',
  };
  return address;
}

function policiesFromState(config: AppConfig, state?: UserState): boolean {
  if (config.hasAgreementConsents) {
    if (!state || !state.consent) {
      return false;
    }
    return state.consent.policySetConsents?.every(policy => !!policy) ?? false;
  }
  return true;
}
function policiesAgreedTo(config: AppConfig, state?: UserState) {
  if (config.hasAgreementConsents) {
    if (!state || !state.consent) {
      return false;
    }
    return (
      state.consent.policySetConsents?.filter(date => !!date).length > 0 ??
      false
    );
  }
  return false;
}

function numCheckboxesInConsents(consents: AgreementConsentContent[]): number {
  return consents.reduce((outerAcc, consent) => {
    return (
      outerAcc +
      consent.modules.reduce((innerAcc, module) => {
        if (module.module.oneofKind === 'checkbox') {
          return innerAcc + module.module['checkbox'].signingReasons.length;
        } else {
          return innerAcc;
        }
      }, 0)
    );
  }, 0);
}

/**
 * Only allow user inputs that will be usable for FHIR searches
 *
 * See details in https://verily.atlassian.net/browse/PHP-44626
 */
//
function isValidFhirSearchString(v: string): boolean {
  const numAlphanumericChars = v.replace(/[^a-zA-Z0-9]/g, '').length;
  return (
    // Sanity check there are is at least 1 alphanumeric character
    numAlphanumericChars > 0 &&
    // Prevent starting with dash because it denotes NOT operator
    !v.startsWith('-') &&
    // Prevent FHIR special characters
    !['|', '$', '\\', ','].some(c => v.includes(c))
  );
}

export {
  compareBoolean,
  getAddressFromFormValues,
  consentSpecificationIsNullOrEmpty,
  getDateOfBirth,
  getGenderIdentity,
  numCheckboxesInConsents,
  policiesFromState,
  policiesAgreedTo,
  isValidFhirSearchString,
};
