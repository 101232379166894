import {PastEvents} from 'pages/PastEvents/PastEvents';
import {PastEventsSectionPage} from 'pages/PastEventsSectionPage/PastEventsSectionPage';
// import Example from 'pages/Example/Example';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

type RootProps = {
  name: string;
};

export default function Root(props: RootProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PastEvents />}></Route>
        <Route path="/past-events" element={<PastEventsSectionPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
