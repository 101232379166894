/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "enrollment/bff/api/v1/server.proto" (package "enrollment.bff", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Enrollment } from "./server";
import type { GetPasswordResetReturnProfileResponse } from "./server";
import type { GetPasswordResetReturnProfileRequest } from "./server";
import type { CheckAuthenticationResponse } from "./server";
import type { CheckAuthenticationRequest } from "./server";
import type { ReportErrorResponse } from "./server";
import type { ReportErrorRequest } from "./server";
import type { GetUserStateResponse } from "./server";
import type { GetUserStateRequest } from "./server";
import type { LogInResponse } from "./server";
import type { LogInRequest } from "./server";
import type { RecordUTMResponse } from "./server";
import type { RecordUTMRequest } from "./server";
import type { RecordEnrolleeEventResponse } from "./server";
import type { RecordEnrolleeEventRequest } from "./server";
import type { GetDomainConfigResponse } from "./server";
import type { GetDomainConfigRequest } from "./server";
import type { GetProfileConfigResponse } from "./server";
import type { GetProfileConfigRequest } from "./server";
import type { CheckEligibilityResponse } from "./server";
import type { CheckEligibilityRequest } from "./server";
import type { CompleteEnrollmentResponse } from "./server";
import type { CompleteEnrollmentRequest } from "./server";
import type { RecordPolicyConsentsResponse } from "./server";
import type { RecordPolicyConsentsRequest } from "./server";
import type { GetAgreementConsentsResponse } from "./server";
import type { GetAgreementConsentsRequest } from "./server";
import type { RecordExclusionScreenerDataResponse } from "./server";
import type { RecordExclusionScreenerDataRequest } from "./server";
import type { RecordInclusionScreenerDataResponse } from "./server";
import type { RecordInclusionScreenerDataRequest } from "./server";
import type { RecordPatientDataResponse } from "./server";
import type { RecordPatientDataRequest } from "./server";
import type { EnrollPatientResponse } from "./server";
import type { EnrollPatientRequest } from "./server";
import type { ResendPasswordResetEmailResponse } from "./server";
import type { ResendPasswordResetEmailRequest } from "./server";
import type { CreateAccountResponse } from "./server";
import type { CreateAccountRequest } from "./server";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { StartSessionResponse } from "./server";
import type { StartSessionRequest } from "./server";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service enrollment.bff.Enrollment
 */
export interface IEnrollmentClient {
    /**
     * @generated from protobuf rpc: StartSession(enrollment.bff.StartSessionRequest) returns (enrollment.bff.StartSessionResponse);
     */
    startSession(input: StartSessionRequest, options?: RpcOptions): UnaryCall<StartSessionRequest, StartSessionResponse>;
    /**
     * This RPC assumes that a session exists and that its information is attached
     * as metadata to the CreateAccount call.
     *
     * @generated from protobuf rpc: CreateAccount(enrollment.bff.CreateAccountRequest) returns (enrollment.bff.CreateAccountResponse);
     */
    createAccount(input: CreateAccountRequest, options?: RpcOptions): UnaryCall<CreateAccountRequest, CreateAccountResponse>;
    /**
     * @generated from protobuf rpc: ResendPasswordResetEmail(enrollment.bff.ResendPasswordResetEmailRequest) returns (enrollment.bff.ResendPasswordResetEmailResponse);
     */
    resendPasswordResetEmail(input: ResendPasswordResetEmailRequest, options?: RpcOptions): UnaryCall<ResendPasswordResetEmailRequest, ResendPasswordResetEmailResponse>;
    /**
     * TODO(PHP-6712) deprecate the Enroll RPC.
     *
     * @generated from protobuf rpc: Enroll(enrollment.bff.EnrollPatientRequest) returns (enrollment.bff.EnrollPatientResponse);
     */
    enroll(input: EnrollPatientRequest, options?: RpcOptions): UnaryCall<EnrollPatientRequest, EnrollPatientResponse>;
    /**
     * Stores data (e.g. name, date of birth) about the user in FHIR.
     *
     * @deprecated
     * @generated from protobuf rpc: RecordPatientData(enrollment.bff.RecordPatientDataRequest) returns (enrollment.bff.RecordPatientDataResponse);
     */
    recordPatientData(input: RecordPatientDataRequest, options?: RpcOptions): UnaryCall<RecordPatientDataRequest, RecordPatientDataResponse>;
    /**
     * Stores the user's responses to the inclusion screener in FHIR.
     *
     * @generated from protobuf rpc: RecordInclusionScreenerData(enrollment.bff.RecordInclusionScreenerDataRequest) returns (enrollment.bff.RecordInclusionScreenerDataResponse);
     */
    recordInclusionScreenerData(input: RecordInclusionScreenerDataRequest, options?: RpcOptions): UnaryCall<RecordInclusionScreenerDataRequest, RecordInclusionScreenerDataResponse>;
    /**
     * Stores the user's responses to the exclusion screener in FHIR.
     *
     * @generated from protobuf rpc: RecordExclusionScreenerData(enrollment.bff.RecordExclusionScreenerDataRequest) returns (enrollment.bff.RecordExclusionScreenerDataResponse);
     */
    recordExclusionScreenerData(input: RecordExclusionScreenerDataRequest, options?: RpcOptions): UnaryCall<RecordExclusionScreenerDataRequest, RecordExclusionScreenerDataResponse>;
    /**
     * Returns information about the agreement consents which were instantiated
     * for the user based on the agreement_consents_config field.
     *
     * @generated from protobuf rpc: GetAgreementConsents(enrollment.bff.GetAgreementConsentsRequest) returns (enrollment.bff.GetAgreementConsentsResponse);
     */
    getAgreementConsents(input: GetAgreementConsentsRequest, options?: RpcOptions): UnaryCall<GetAgreementConsentsRequest, GetAgreementConsentsResponse>;
    /**
     * Records the user's acceptance of policy consents, AKA agreement consents.
     *
     * @deprecated
     * @generated from protobuf rpc: RecordPolicyConsents(enrollment.bff.RecordPolicyConsentsRequest) returns (enrollment.bff.RecordPolicyConsentsResponse);
     */
    recordPolicyConsents(input: RecordPolicyConsentsRequest, options?: RpcOptions): UnaryCall<RecordPolicyConsentsRequest, RecordPolicyConsentsResponse>;
    /**
     * Marks the user's Enrollment journey as complete. This RPC's behavior is
     * partially documented at
     * verily1/enrollment/bff/docs/fhir.md#definition-of-done.
     *
     * @deprecated
     * @generated from protobuf rpc: CompleteEnrollment(enrollment.bff.CompleteEnrollmentRequest) returns (enrollment.bff.CompleteEnrollmentResponse);
     */
    completeEnrollment(input: CompleteEnrollmentRequest, options?: RpcOptions): UnaryCall<CompleteEnrollmentRequest, CompleteEnrollmentResponse>;
    /**
     * @generated from protobuf rpc: CheckEligibility(enrollment.bff.CheckEligibilityRequest) returns (enrollment.bff.CheckEligibilityResponse);
     */
    checkEligibility(input: CheckEligibilityRequest, options?: RpcOptions): UnaryCall<CheckEligibilityRequest, CheckEligibilityResponse>;
    /**
     * @generated from protobuf rpc: GetProfileConfig(enrollment.bff.GetProfileConfigRequest) returns (enrollment.bff.GetProfileConfigResponse);
     */
    getProfileConfig(input: GetProfileConfigRequest, options?: RpcOptions): UnaryCall<GetProfileConfigRequest, GetProfileConfigResponse>;
    /**
     * @generated from protobuf rpc: GetDomainConfig(enrollment.bff.GetDomainConfigRequest) returns (enrollment.bff.GetDomainConfigResponse);
     */
    getDomainConfig(input: GetDomainConfigRequest, options?: RpcOptions): UnaryCall<GetDomainConfigRequest, GetDomainConfigResponse>;
    /**
     * @generated from protobuf rpc: RecordEnrolleeEvent(enrollment.bff.RecordEnrolleeEventRequest) returns (enrollment.bff.RecordEnrolleeEventResponse);
     */
    recordEnrolleeEvent(input: RecordEnrolleeEventRequest, options?: RpcOptions): UnaryCall<RecordEnrolleeEventRequest, RecordEnrolleeEventResponse>;
    /**
     * @generated from protobuf rpc: RecordUTM(enrollment.bff.RecordUTMRequest) returns (enrollment.bff.RecordUTMResponse);
     */
    recordUTM(input: RecordUTMRequest, options?: RpcOptions): UnaryCall<RecordUTMRequest, RecordUTMResponse>;
    /**
     * @generated from protobuf rpc: LogIn(enrollment.bff.LogInRequest) returns (enrollment.bff.LogInResponse);
     */
    logIn(input: LogInRequest, options?: RpcOptions): UnaryCall<LogInRequest, LogInResponse>;
    /**
     * @generated from protobuf rpc: GetUserState(enrollment.bff.GetUserStateRequest) returns (enrollment.bff.GetUserStateResponse);
     */
    getUserState(input: GetUserStateRequest, options?: RpcOptions): UnaryCall<GetUserStateRequest, GetUserStateResponse>;
    /**
     * @generated from protobuf rpc: ReportError(enrollment.bff.ReportErrorRequest) returns (enrollment.bff.ReportErrorResponse);
     */
    reportError(input: ReportErrorRequest, options?: RpcOptions): UnaryCall<ReportErrorRequest, ReportErrorResponse>;
    /**
     * @generated from protobuf rpc: CheckAuthentication(enrollment.bff.CheckAuthenticationRequest) returns (enrollment.bff.CheckAuthenticationResponse);
     */
    checkAuthentication(input: CheckAuthenticationRequest, options?: RpcOptions): UnaryCall<CheckAuthenticationRequest, CheckAuthenticationResponse>;
    /**
     * @generated from protobuf rpc: GetPasswordResetReturnProfile(enrollment.bff.GetPasswordResetReturnProfileRequest) returns (enrollment.bff.GetPasswordResetReturnProfileResponse);
     */
    getPasswordResetReturnProfile(input: GetPasswordResetReturnProfileRequest, options?: RpcOptions): UnaryCall<GetPasswordResetReturnProfileRequest, GetPasswordResetReturnProfileResponse>;
}
/**
 * @generated from protobuf service enrollment.bff.Enrollment
 */
export class EnrollmentClient implements IEnrollmentClient, ServiceInfo {
    typeName = Enrollment.typeName;
    methods = Enrollment.methods;
    options = Enrollment.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: StartSession(enrollment.bff.StartSessionRequest) returns (enrollment.bff.StartSessionResponse);
     */
    startSession(input: StartSessionRequest, options?: RpcOptions): UnaryCall<StartSessionRequest, StartSessionResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<StartSessionRequest, StartSessionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * This RPC assumes that a session exists and that its information is attached
     * as metadata to the CreateAccount call.
     *
     * @generated from protobuf rpc: CreateAccount(enrollment.bff.CreateAccountRequest) returns (enrollment.bff.CreateAccountResponse);
     */
    createAccount(input: CreateAccountRequest, options?: RpcOptions): UnaryCall<CreateAccountRequest, CreateAccountResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAccountRequest, CreateAccountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ResendPasswordResetEmail(enrollment.bff.ResendPasswordResetEmailRequest) returns (enrollment.bff.ResendPasswordResetEmailResponse);
     */
    resendPasswordResetEmail(input: ResendPasswordResetEmailRequest, options?: RpcOptions): UnaryCall<ResendPasswordResetEmailRequest, ResendPasswordResetEmailResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResendPasswordResetEmailRequest, ResendPasswordResetEmailResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * TODO(PHP-6712) deprecate the Enroll RPC.
     *
     * @generated from protobuf rpc: Enroll(enrollment.bff.EnrollPatientRequest) returns (enrollment.bff.EnrollPatientResponse);
     */
    enroll(input: EnrollPatientRequest, options?: RpcOptions): UnaryCall<EnrollPatientRequest, EnrollPatientResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<EnrollPatientRequest, EnrollPatientResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Stores data (e.g. name, date of birth) about the user in FHIR.
     *
     * @deprecated
     * @generated from protobuf rpc: RecordPatientData(enrollment.bff.RecordPatientDataRequest) returns (enrollment.bff.RecordPatientDataResponse);
     */
    recordPatientData(input: RecordPatientDataRequest, options?: RpcOptions): UnaryCall<RecordPatientDataRequest, RecordPatientDataResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordPatientDataRequest, RecordPatientDataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Stores the user's responses to the inclusion screener in FHIR.
     *
     * @generated from protobuf rpc: RecordInclusionScreenerData(enrollment.bff.RecordInclusionScreenerDataRequest) returns (enrollment.bff.RecordInclusionScreenerDataResponse);
     */
    recordInclusionScreenerData(input: RecordInclusionScreenerDataRequest, options?: RpcOptions): UnaryCall<RecordInclusionScreenerDataRequest, RecordInclusionScreenerDataResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordInclusionScreenerDataRequest, RecordInclusionScreenerDataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Stores the user's responses to the exclusion screener in FHIR.
     *
     * @generated from protobuf rpc: RecordExclusionScreenerData(enrollment.bff.RecordExclusionScreenerDataRequest) returns (enrollment.bff.RecordExclusionScreenerDataResponse);
     */
    recordExclusionScreenerData(input: RecordExclusionScreenerDataRequest, options?: RpcOptions): UnaryCall<RecordExclusionScreenerDataRequest, RecordExclusionScreenerDataResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordExclusionScreenerDataRequest, RecordExclusionScreenerDataResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns information about the agreement consents which were instantiated
     * for the user based on the agreement_consents_config field.
     *
     * @generated from protobuf rpc: GetAgreementConsents(enrollment.bff.GetAgreementConsentsRequest) returns (enrollment.bff.GetAgreementConsentsResponse);
     */
    getAgreementConsents(input: GetAgreementConsentsRequest, options?: RpcOptions): UnaryCall<GetAgreementConsentsRequest, GetAgreementConsentsResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAgreementConsentsRequest, GetAgreementConsentsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Records the user's acceptance of policy consents, AKA agreement consents.
     *
     * @deprecated
     * @generated from protobuf rpc: RecordPolicyConsents(enrollment.bff.RecordPolicyConsentsRequest) returns (enrollment.bff.RecordPolicyConsentsResponse);
     */
    recordPolicyConsents(input: RecordPolicyConsentsRequest, options?: RpcOptions): UnaryCall<RecordPolicyConsentsRequest, RecordPolicyConsentsResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordPolicyConsentsRequest, RecordPolicyConsentsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Marks the user's Enrollment journey as complete. This RPC's behavior is
     * partially documented at
     * verily1/enrollment/bff/docs/fhir.md#definition-of-done.
     *
     * @deprecated
     * @generated from protobuf rpc: CompleteEnrollment(enrollment.bff.CompleteEnrollmentRequest) returns (enrollment.bff.CompleteEnrollmentResponse);
     */
    completeEnrollment(input: CompleteEnrollmentRequest, options?: RpcOptions): UnaryCall<CompleteEnrollmentRequest, CompleteEnrollmentResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<CompleteEnrollmentRequest, CompleteEnrollmentResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckEligibility(enrollment.bff.CheckEligibilityRequest) returns (enrollment.bff.CheckEligibilityResponse);
     */
    checkEligibility(input: CheckEligibilityRequest, options?: RpcOptions): UnaryCall<CheckEligibilityRequest, CheckEligibilityResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckEligibilityRequest, CheckEligibilityResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProfileConfig(enrollment.bff.GetProfileConfigRequest) returns (enrollment.bff.GetProfileConfigResponse);
     */
    getProfileConfig(input: GetProfileConfigRequest, options?: RpcOptions): UnaryCall<GetProfileConfigRequest, GetProfileConfigResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProfileConfigRequest, GetProfileConfigResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDomainConfig(enrollment.bff.GetDomainConfigRequest) returns (enrollment.bff.GetDomainConfigResponse);
     */
    getDomainConfig(input: GetDomainConfigRequest, options?: RpcOptions): UnaryCall<GetDomainConfigRequest, GetDomainConfigResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDomainConfigRequest, GetDomainConfigResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RecordEnrolleeEvent(enrollment.bff.RecordEnrolleeEventRequest) returns (enrollment.bff.RecordEnrolleeEventResponse);
     */
    recordEnrolleeEvent(input: RecordEnrolleeEventRequest, options?: RpcOptions): UnaryCall<RecordEnrolleeEventRequest, RecordEnrolleeEventResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordEnrolleeEventRequest, RecordEnrolleeEventResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RecordUTM(enrollment.bff.RecordUTMRequest) returns (enrollment.bff.RecordUTMResponse);
     */
    recordUTM(input: RecordUTMRequest, options?: RpcOptions): UnaryCall<RecordUTMRequest, RecordUTMResponse> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<RecordUTMRequest, RecordUTMResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: LogIn(enrollment.bff.LogInRequest) returns (enrollment.bff.LogInResponse);
     */
    logIn(input: LogInRequest, options?: RpcOptions): UnaryCall<LogInRequest, LogInResponse> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<LogInRequest, LogInResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserState(enrollment.bff.GetUserStateRequest) returns (enrollment.bff.GetUserStateResponse);
     */
    getUserState(input: GetUserStateRequest, options?: RpcOptions): UnaryCall<GetUserStateRequest, GetUserStateResponse> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserStateRequest, GetUserStateResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReportError(enrollment.bff.ReportErrorRequest) returns (enrollment.bff.ReportErrorResponse);
     */
    reportError(input: ReportErrorRequest, options?: RpcOptions): UnaryCall<ReportErrorRequest, ReportErrorResponse> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReportErrorRequest, ReportErrorResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CheckAuthentication(enrollment.bff.CheckAuthenticationRequest) returns (enrollment.bff.CheckAuthenticationResponse);
     */
    checkAuthentication(input: CheckAuthenticationRequest, options?: RpcOptions): UnaryCall<CheckAuthenticationRequest, CheckAuthenticationResponse> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckAuthenticationRequest, CheckAuthenticationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPasswordResetReturnProfile(enrollment.bff.GetPasswordResetReturnProfileRequest) returns (enrollment.bff.GetPasswordResetReturnProfileResponse);
     */
    getPasswordResetReturnProfile(input: GetPasswordResetReturnProfileRequest, options?: RpcOptions): UnaryCall<GetPasswordResetReturnProfileRequest, GetPasswordResetReturnProfileResponse> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPasswordResetReturnProfileRequest, GetPasswordResetReturnProfileResponse>("unary", this._transport, method, opt, input);
    }
}
