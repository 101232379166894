import {CssBaseline, ThemeProvider} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers';
import {VerilyMeThemeProvider} from '@verily-src/react-design-system';
import {ApplicationSuite} from '@verily-src/verily1-protos/messaging/bff/api/messaging_bff_service';
import {createContext, useContext} from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
type ComponentStyles = {
  ThreadsOverview: any;
  ThreadDetail: any;
  CreateThread: any;
  Common?: any;
};

export type ThemeContextType = {
  theme: any;
  styles: ComponentStyles;
  components: ComponentStyles;
  applicationSuite: ApplicationSuite;
  applicationSuiteData?: {
    professionalAccountRefName: string;
    participantRecordRefName: string;
  };
  hyperlinkPreviewAllowList: string[];
};

const ThemeContext = createContext<ThemeContextType>(null);
export const ThemeContextProvider = ({value, children}) => {
  if (value.applicationSuite === ApplicationSuite.VERILY_ME) {
    const isDarkModeEnabled = config.getBoolean('FEATURE_DARK_MODE_ENABLED');
    return (
      <VerilyMeThemeProvider isDarkModeEnabled={isDarkModeEnabled}>
        <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
      </VerilyMeThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={value.theme}>
      <CssBaseline />
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
