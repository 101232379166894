/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "enrollment/bff/api/v1/server.proto" (package "enrollment.bff", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ResearchSubjectStatusCode_Value } from "../../../../proto/google/fhir/proto/r4/core/codes";
import { Timestamp } from "../../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message enrollment.bff.Address
 */
export interface Address {
    /**
     * @generated from protobuf field: repeated string street_address = 1;
     */
    streetAddress: string[];
    /**
     * @generated from protobuf field: string state = 2;
     */
    state: string;
    /**
     * @generated from protobuf field: string city = 3;
     */
    city: string;
    /**
     * @generated from protobuf field: string zip_code = 4;
     */
    zipCode: string;
    /**
     * @generated from protobuf field: string country = 5;
     */
    country: string;
}
/**
 * @generated from protobuf message enrollment.bff.Name
 */
export interface Name {
    /**
     * TODO(PHP-43232) add validation rules to these fields.
     *
     * @generated from protobuf field: string family = 1;
     */
    family: string;
    /**
     * @generated from protobuf field: string given = 2;
     */
    given: string;
}
/**
 * @generated from protobuf message enrollment.bff.Date
 */
export interface Date {
    /**
     * @generated from protobuf field: int32 year = 1;
     */
    year: number;
    /**
     * @generated from protobuf field: int32 month = 2;
     */
    month: number;
    /**
     * @generated from protobuf field: int32 day = 3;
     */
    day: number;
}
/**
 * @generated from protobuf message enrollment.bff.Telecom
 */
export interface Telecom {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
    /**
     * @generated from protobuf field: enrollment.bff.Telecom.ContactPointSystem system = 2;
     */
    system: Telecom_ContactPointSystem;
}
/**
 * (-- api-linter: core::0126::unspecified=disabled
 *     aip.dev/not-precedent: Legacy enum that will be deprecated soon. --)
 *
 * @generated from protobuf enum enrollment.bff.Telecom.ContactPointSystem
 */
export enum Telecom_ContactPointSystem {
    /**
     * @generated from protobuf enum value: CONTACT_POINT_UNSPECIFIED = 0;
     */
    CONTACT_POINT_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: PHONE = 1;
     */
    PHONE = 1,
    /**
     * @generated from protobuf enum value: EMAIL = 2;
     */
    EMAIL = 2
}
/**
 * @generated from protobuf message enrollment.bff.PatientData
 */
export interface PatientData {
    /**
     * @generated from protobuf field: enrollment.bff.Name name = 1;
     */
    name?: Name;
    /**
     * @generated from protobuf field: enrollment.bff.Date date_of_birth = 2;
     */
    dateOfBirth?: Date;
    /**
     * @generated from protobuf field: repeated enrollment.bff.Telecom telecom = 4;
     */
    telecom: Telecom[];
    /**
     * @generated from protobuf field: enrollment.bff.GenderIdentity gender_identity = 5;
     */
    genderIdentity: GenderIdentity;
    /**
     * @generated from protobuf field: string enrollment_verification_key = 6;
     */
    enrollmentVerificationKey: string;
    /**
     * @generated from protobuf field: enrollment.bff.Address address = 7;
     */
    address?: Address;
}
/**
 * @generated from protobuf message enrollment.bff.RecordPatientDataRequest
 */
export interface RecordPatientDataRequest {
    /**
     * Identifies with which profile we expect any session and auth metadata to be
     * associated.
     *
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.PatientData data = 2;
     */
    data?: PatientData;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 3;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bff.RecordPatientDataResponse
 */
export interface RecordPatientDataResponse {
}
/**
 * Generic message type for overriding field labels that appear in forms
 *
 * @generated from protobuf message enrollment.bff.FormFieldLabelConfig
 */
export interface FormFieldLabelConfig {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * If true, we never change the casing of the provided value. Otherwise the system
     * adjusts casing as appropriate depending on the context, such as lowercasing the
     * first letter when used within a sentence
     *
     * @generated from protobuf field: bool exact_casing = 2;
     */
    exactCasing: boolean;
}
/**
 * Each enrollment can optionally be associated with other resources
 * through the profile config.
 *
 * @generated from protobuf message enrollment.bff.ResourceLink
 */
export interface ResourceLink {
    /**
     * @generated from protobuf oneof: link_oneof
     */
    linkOneof: {
        oneofKind: "researchStudyId";
        /**
         * Study business identifier
         * (https://build.fhir.org/researchstudy-definitions.html#ResearchStudy.identifier)
         * If specified, user will be associated with a research subject and study
         *
         * @generated from protobuf field: string research_study_id = 1;
         */
        researchStudyId: string;
    } | {
        oneofKind: "healthcareServiceId";
        /**
         * Expected to be a FHIR resource ID. If present, the Patient will be
         * associated with the specified HealthcareService via the
         * CareProgramEnrolled extension.
         *
         * @generated from protobuf field: string healthcare_service_id = 2;
         */
        healthcareServiceId: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bff.InclusionScreenerData
 */
export interface InclusionScreenerData {
    /**
     * @generated from protobuf field: repeated enrollment.bff.BooleanQuestionAnswer questions = 1;
     */
    questions: BooleanQuestionAnswer[];
}
/**
 * @generated from protobuf message enrollment.bff.CreateAccountRequest
 */
export interface CreateAccountRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: string email_address = 2;
     */
    emailAddress: string;
    /**
     * @generated from protobuf field: bool send_password_reset_email = 3;
     */
    sendPasswordResetEmail: boolean;
}
/**
 * @generated from protobuf message enrollment.bff.CreateAccountResponse
 */
export interface CreateAccountResponse {
    /**
     * @generated from protobuf field: string password_reset_url = 1;
     */
    passwordResetUrl: string;
    /**
     * @generated from protobuf field: string auth0_id = 2;
     */
    auth0Id: string;
}
/**
 * @generated from protobuf message enrollment.bff.RecordInclusionScreenerDataRequest
 */
export interface RecordInclusionScreenerDataRequest {
    /**
     * Identifies with which profile we expect any session and auth metadata to be
     * associated.
     *
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.InclusionScreenerData data = 2;
     */
    data?: InclusionScreenerData;
}
/**
 * @generated from protobuf message enrollment.bff.RecordInclusionScreenerDataResponse
 */
export interface RecordInclusionScreenerDataResponse {
}
/**
 * @generated from protobuf message enrollment.bff.ExclusionScreenerData
 */
export interface ExclusionScreenerData {
    /**
     * @generated from protobuf field: repeated enrollment.bff.BooleanQuestionAnswer questions = 1;
     */
    questions: BooleanQuestionAnswer[];
}
/**
 * @generated from protobuf message enrollment.bff.RecordExclusionScreenerDataRequest
 */
export interface RecordExclusionScreenerDataRequest {
    /**
     * Identifies with which profile we expect any session and auth metadata to be
     * associated.
     *
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.ExclusionScreenerData data = 2;
     */
    data?: ExclusionScreenerData;
}
/**
 * @generated from protobuf message enrollment.bff.RecordExclusionScreenerDataResponse
 */
export interface RecordExclusionScreenerDataResponse {
}
/**
 * @generated from protobuf message enrollment.bff.ConsentData
 */
export interface ConsentData {
    /**
     * Order matches the order from agreement_consents_config.
     *
     * @generated from protobuf field: repeated enrollment.bff.ConsentData.PolicySetConsent consents = 1;
     */
    consents: ConsentData_PolicySetConsent[];
}
/**
 * This is a misnomer; it applies to agreement consents, but policy sets are
 * obsolete. See PHP-10076 for more context.
 *
 * @generated from protobuf message enrollment.bff.ConsentData.PolicySetConsent
 */
export interface ConsentData_PolicySetConsent {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp consented_at = 1;
     */
    consentedAt?: Timestamp;
}
/**
 * @generated from protobuf message enrollment.bff.GetAgreementConsentsRequest
 */
export interface GetAgreementConsentsRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 2;
     */
    currentStep?: Step;
}
/**
 * Largely a subset of consent.backend.api.v1's ConsentContent, appropriate for
 * representing a single agreement consent in the Enrollment frontend.
 *
 * @generated from protobuf message enrollment.bff.AgreementConsentContent
 */
export interface AgreementConsentContent {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * The order of modules is significant; it is the order in which they will be
     * displayed to the user.
     *
     * @generated from protobuf field: repeated enrollment.bff.AgreementConsentContent.Module modules = 2;
     */
    modules: AgreementConsentContent_Module[];
}
/**
 * These types are meant to parallel the HtmlContentModule, LinkModule, and
 * CheckboxModule, respectively.
 *
 * @generated from protobuf message enrollment.bff.AgreementConsentContent.Html
 */
export interface AgreementConsentContent_Html {
    /**
     * @generated from protobuf field: string html = 1;
     */
    html: string;
}
/**
 * @generated from protobuf message enrollment.bff.AgreementConsentContent.Links
 */
export interface AgreementConsentContent_Links {
    /**
     * @generated from protobuf field: repeated enrollment.bff.AgreementConsentContent.Links.Link links = 1;
     */
    links: AgreementConsentContent_Links_Link[];
}
/**
 * @generated from protobuf message enrollment.bff.AgreementConsentContent.Links.Link
 */
export interface AgreementConsentContent_Links_Link {
    /**
     * @generated from protobuf field: string text = 1;
     */
    text: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message enrollment.bff.AgreementConsentContent.Checkbox
 */
export interface AgreementConsentContent_Checkbox {
    /**
     * @generated from protobuf field: repeated string signing_reasons = 1;
     */
    signingReasons: string[];
}
/**
 * @generated from protobuf message enrollment.bff.AgreementConsentContent.Module
 */
export interface AgreementConsentContent_Module {
    /**
     * @generated from protobuf oneof: module
     */
    module: {
        oneofKind: "html";
        /**
         * @generated from protobuf field: enrollment.bff.AgreementConsentContent.Html html = 1;
         */
        html: AgreementConsentContent_Html;
    } | {
        oneofKind: "links";
        /**
         * @generated from protobuf field: enrollment.bff.AgreementConsentContent.Links links = 2;
         */
        links: AgreementConsentContent_Links;
    } | {
        oneofKind: "checkbox";
        /**
         * @generated from protobuf field: enrollment.bff.AgreementConsentContent.Checkbox checkbox = 3;
         */
        checkbox: AgreementConsentContent_Checkbox;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bff.GetAgreementConsentsResponse
 */
export interface GetAgreementConsentsResponse {
    /**
     * @generated from protobuf field: repeated enrollment.bff.AgreementConsentContent consents = 1;
     */
    consents: AgreementConsentContent[];
}
/**
 * @deprecated
 * @generated from protobuf message enrollment.bff.RecordPolicyConsentsRequest
 */
export interface RecordPolicyConsentsRequest {
    /**
     * Identifies with which profile we expect any session and auth metadata to be
     * associated.
     *
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: repeated enrollment.bff.RecordPolicyConsentsRequest.ConsentAcceptance acceptance = 2;
     */
    acceptance: RecordPolicyConsentsRequest_ConsentAcceptance[];
}
/**
 * @generated from protobuf message enrollment.bff.RecordPolicyConsentsRequest.ConsentAcceptance
 */
export interface RecordPolicyConsentsRequest_ConsentAcceptance {
    /**
     * The identifier of the consent. This corresponds to the value which is
     * stored in the Policy.policy_id or ConsentSpecification.consent_id field,
     * which indicates the agreement generally, rather than a consent _record_
     * identifier, which would indicate the instance of the agreement specific
     * to this user.
     *
     * @generated from protobuf field: string consent_id = 1;
     */
    consentId: string;
}
/**
 * @deprecated
 * @generated from protobuf message enrollment.bff.RecordPolicyConsentsResponse
 */
export interface RecordPolicyConsentsResponse {
}
/**
 * @generated from protobuf message enrollment.bff.CompleteEnrollmentRequest
 */
export interface CompleteEnrollmentRequest {
    /**
     * Identifies with which profile we expect any session and auth metadata to be
     * associated.
     *
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 2;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bff.CompleteEnrollmentResponse
 */
export interface CompleteEnrollmentResponse {
}
/**
 * @generated from protobuf message enrollment.bff.EnrollPatientRequest
 */
export interface EnrollPatientRequest {
    /**
     * @generated from protobuf field: enrollment.bff.PatientData patient_data = 1;
     */
    patientData?: PatientData;
    /**
     * @generated from protobuf field: enrollment.bff.InclusionScreenerData inclusion_data = 2;
     */
    inclusionData?: InclusionScreenerData;
    /**
     * @generated from protobuf field: enrollment.bff.ExclusionScreenerData exclusion_data = 3;
     */
    exclusionData?: ExclusionScreenerData;
    /**
     * same request is used for in progress syncs as well as final enrollment
     *
     * @generated from protobuf field: bool is_complete = 4;
     */
    isComplete: boolean;
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 5;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.ConsentData consent_data = 6;
     */
    consentData?: ConsentData;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 7;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bff.EnrollPatientResponse
 */
export interface EnrollPatientResponse {
}
/**
 * @generated from protobuf message enrollment.bff.CheckEligibilityRequest
 */
export interface CheckEligibilityRequest {
    /**
     * @generated from protobuf field: enrollment.bff.Name name = 1;
     */
    name?: Name;
    /**
     * @generated from protobuf field: enrollment.bff.Date date_of_birth = 2;
     */
    dateOfBirth?: Date;
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 3;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: string enrollment_verification_key = 4;
     */
    enrollmentVerificationKey: string;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 5;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bff.CheckEligibilityResponse
 */
export interface CheckEligibilityResponse {
    /**
     * @generated from protobuf field: bool is_eligible = 1;
     */
    isEligible: boolean;
    /**
     * @deprecated
     * @generated from protobuf field: string sponsor = 2 [deprecated = true];
     */
    sponsor: string;
}
/**
 * Image shown in enrollment service
 *
 * @generated from protobuf message enrollment.bff.Image
 */
export interface Image {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string alt_text = 2;
     */
    altText: string; // Optional
}
/**
 * Styling and Display-Only Configurations
 *
 * @generated from protobuf message enrollment.bff.ProfileStyleConfig
 */
export interface ProfileStyleConfig {
    /**
     * Banner logos
     *
     * @generated from protobuf field: enrollment.bff.Image logo_1 = 1;
     */
    logo1?: Image;
    /**
     * @generated from protobuf field: enrollment.bff.Image logo_2 = 3;
     */
    logo2?: Image; // Optional secondary logo
    /**
     * Optional disclaimer text shown in content footer. This can include HTML.
     *
     * @generated from protobuf field: string disclaimer_html = 2;
     */
    disclaimerHtml: string;
    /**
     * fully qualified URL displayed on product page.
     *
     * @deprecated
     * @generated from protobuf field: string product_handoff_link = 4 [deprecated = true];
     */
    productHandoffLink: string;
    /**
     * Optional
     *
     * @generated from protobuf field: enrollment.bff.Image favicon = 5;
     */
    favicon?: Image;
    /**
     * @generated from protobuf field: string product_name = 6;
     */
    productName: string;
    /**
     * CSS color variable
     *
     * @generated from protobuf field: string primary_color_main = 7;
     */
    primaryColorMain: string;
    /**
     * Document's title shown in browser's title bar or page's tab.
     * If unspecified, we default to "Enrollment" or
     * "ProductName Enrollment" (if product name is provided).
     *
     * @generated from protobuf field: string page_title = 8;
     */
    pageTitle: string;
    /**
     * URL for product handoff to app store (will replace product_handoff_link)
     * Deprecated; replaced by flow_config.steps.handoff.app_handoff_link
     *
     * @deprecated
     * @generated from protobuf field: string app_handoff_link = 9 [deprecated = true];
     */
    appHandoffLink: string;
    /**
     * URL for product handoff to web app
     * Deprecated; replaced by flow_config.steps.handoff.web_handoff_link
     *
     * @deprecated
     * @generated from protobuf field: string web_handoff_link = 10 [deprecated = true];
     */
    webHandoffLink: string;
    /**
     * Optional URLs for product handoff to specific app stores. They will
     * supersede  app_handoff_link for their respective "Get it on ..." buttons.
     * If one or both of these are provided, app_handoff_link must be provided, as
     * even with store-specific links, a generic link is still necessary to
     * generate a store-agnostic QR code.
     * Deprecated; replaced by flow_config.steps.handoff.android_handoff_link
     *
     * @deprecated
     * @generated from protobuf field: string android_handoff_link = 11 [deprecated = true];
     */
    androidHandoffLink: string;
    /**
     * Deprecated; replaced by flow_config.steps.handoff.ios_handoff_link
     *
     * @deprecated
     * @generated from protobuf field: string ios_handoff_link = 12 [deprecated = true];
     */
    iosHandoffLink: string;
}
/**
 * Configuration related to semantic data collection fields that could appear.
 *
 * @generated from protobuf message enrollment.bff.ProfileDataFormConfig
 */
export interface ProfileDataFormConfig {
    /**
     * @generated from protobuf field: bool collect_gender_identity = 1;
     */
    collectGenderIdentity: boolean;
    /**
     * @generated from protobuf field: repeated enrollment.bff.ProfileDataFormConfig.FormFieldConstraint constraints = 2;
     */
    constraints: ProfileDataFormConfig_FormFieldConstraint[];
    /**
     * Optionally change the header and subheader for the participant data collection page:
     *
     * @generated from protobuf field: string participant_data_form_header = 4;
     */
    participantDataFormHeader: string;
    /**
     * @generated from protobuf field: string participant_data_form_subheader = 5;
     */
    participantDataFormSubheader: string;
    /**
     * Optional
     *
     * @generated from protobuf field: bool collect_address = 3;
     */
    collectAddress: boolean;
    /**
     * Optionally change the header and subheader for the address collection section; won't be rendered if address collection is not enabled:
     *
     * @generated from protobuf field: string address_collection_subform_header = 6;
     */
    addressCollectionSubformHeader: string;
    /**
     * @generated from protobuf field: string address_collection_subform_subheader = 7;
     */
    addressCollectionSubformSubheader: string;
    /**
     * Optionally change the subheader for the contact information section:
     *
     * @generated from protobuf field: string contact_info_subform_subheader = 8;
     */
    contactInfoSubformSubheader: string;
    /**
     * Optionally change the subheader for the basic information section:
     *
     * @generated from protobuf field: string basic_info_subform_subheader = 9;
     */
    basicInfoSubformSubheader: string;
    /**
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_phone = 10;
     */
    collectPhone: ProfileDataFormConfig_ShouldCollect;
    /**
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_email = 11;
     */
    collectEmail: ProfileDataFormConfig_ShouldCollect;
    /**
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_dob = 12;
     */
    collectDob: ProfileDataFormConfig_ShouldCollect;
}
/**
 * Defines a constraint on a form field.
 * Example:
 *     field: FIRST_NAME
 *     operator: LENGTH_LESS_THAN
 *     value: "21"
 *     error_message: "We can't handle names of more than 20 characters."
 *
 * @generated from protobuf message enrollment.bff.ProfileDataFormConfig.FormFieldConstraint
 */
export interface ProfileDataFormConfig_FormFieldConstraint {
    /**
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig.Field field = 1;
     */
    field: ProfileDataFormConfig_Field; // Must be a non-default value.
    /**
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig.ConstraintOperator operator = 2;
     */
    operator: ProfileDataFormConfig_ConstraintOperator; // Must be a non-default value.
    /**
     * May be interpreted as a non-string data type, depending on the
     * ConstraintOperator. May not be required by all ConstraintOperators.
     *
     * @generated from protobuf field: string value = 3;
     */
    value: string;
    /**
     * Optional. Shown to the user if this constraint fails. Will be shown
     * literally; should not include substitution strings of any kind.
     *
     * @generated from protobuf field: string error_message = 4;
     */
    errorMessage: string;
}
/**
 * (-- api-linter: core::0126::unspecified=disabled
 *     aip.dev/not-precedent: Legacy enum that will be deprecated soon. --)
 *
 * @generated from protobuf enum enrollment.bff.ProfileDataFormConfig.Field
 */
export enum ProfileDataFormConfig_Field {
    /**
     * @generated from protobuf enum value: FIELD_INVALID = 0;
     */
    FIELD_INVALID = 0,
    /**
     * @generated from protobuf enum value: FIRST_NAME = 1;
     */
    FIRST_NAME = 1,
    /**
     * @generated from protobuf enum value: LAST_NAME = 2;
     */
    LAST_NAME = 2
}
/**
 * (-- api-linter: core::0126::unspecified=disabled
 *     aip.dev/not-precedent: Legacy enum that will be deprecated soon. --)
 *
 * @generated from protobuf enum enrollment.bff.ProfileDataFormConfig.ConstraintOperator
 */
export enum ProfileDataFormConfig_ConstraintOperator {
    /**
     * @generated from protobuf enum value: CONSTRAINT_INVALID = 0;
     */
    CONSTRAINT_INVALID = 0,
    /**
     * Value will be interpreted as an integer.
     *
     * @generated from protobuf enum value: LENGTH_LESS_THAN = 1;
     */
    LENGTH_LESS_THAN = 1
}
/**
 * Optionally collect or don't collect any of these fields. We are using an enum instead of a bool
 * here for backwards compatibility. The current behavior is that these fields are all expected to
 * be collected by all existing profiles. So the code will treat SHOULD_COLLECT_UNSPECIFIED values
 * as equivalent to TRUE to preserve the existing behavior.
 *
 * @generated from protobuf enum enrollment.bff.ProfileDataFormConfig.ShouldCollect
 */
export enum ProfileDataFormConfig_ShouldCollect {
    /**
     * @generated from protobuf enum value: SHOULD_COLLECT_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: SHOULD_COLLECT_FALSE = 1;
     */
    FALSE = 1,
    /**
     * @generated from protobuf enum value: SHOULD_COLLECT_TRUE = 2;
     */
    TRUE = 2
}
/**
 * @generated from protobuf message enrollment.bff.BooleanQuestion
 */
export interface BooleanQuestion {
    /**
     * Product-specific question ID stored in FHIR store allowing a product to
     * associate meaning to the response. This is not rendered in the UI and
     * is assumed to be unique within a profile.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Title rendered in UI.
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * Optional additional text shown in UI. This can contain HTML elements.
     *
     * @generated from protobuf field: string description = 3;
     */
    description: string;
}
/**
 * User response to a boolean question.
 *
 * @generated from protobuf message enrollment.bff.BooleanQuestionAnswer
 */
export interface BooleanQuestionAnswer {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string; // Product-specific question ID
    /**
     * @generated from protobuf field: bool answer = 2;
     */
    answer: boolean;
}
/**
 * One or more boolean questions rendered to appear within the same section.
 *
 * @generated from protobuf message enrollment.bff.BooleanQuestionSection
 */
export interface BooleanQuestionSection {
    /**
     * Header and subheader are optional.
     *
     * @generated from protobuf field: string header = 1;
     */
    header: string;
    /**
     * @generated from protobuf field: string subheader = 2;
     */
    subheader: string;
    /**
     * @generated from protobuf field: repeated enrollment.bff.BooleanQuestion questions = 3;
     */
    questions: BooleanQuestion[];
}
/**
 * Configuration related to non-semantic inclusion screener data
 *
 * @generated from protobuf message enrollment.bff.InclusionScreenerConfig
 */
export interface InclusionScreenerConfig {
    /**
     * Ordering determines how they appear to user.
     *
     * @generated from protobuf field: repeated enrollment.bff.BooleanQuestionSection question_sections = 1;
     */
    questionSections: BooleanQuestionSection[];
}
/**
 * Configuration related to non-semantic exclusion screener data
 *
 * @generated from protobuf message enrollment.bff.ExclusionScreenerConfig
 */
export interface ExclusionScreenerConfig {
    /**
     * Ordering determines how they appear to user.
     *
     * @generated from protobuf field: repeated enrollment.bff.BooleanQuestionSection question_sections = 1;
     */
    questionSections: BooleanQuestionSection[];
}
/**
 * used to determine which profile-specific config to load
 *
 * @generated from protobuf message enrollment.bff.EnrollmentProfile
 */
export interface EnrollmentProfile {
    /**
     * @generated from protobuf field: string profile = 1;
     */
    profile: string;
    /**
     * @generated from protobuf field: string domain = 2;
     */
    domain: string;
    /**
     * Optional. Set only if the profile supports multiple languages.
     *
     * @generated from protobuf field: string language = 3;
     */
    language: string;
    /**
     * @deprecated
     * @generated from protobuf field: bool is_preview = 4 [deprecated = true];
     */
    isPreview: boolean;
}
/**
 * @generated from protobuf message enrollment.bff.GetProfileConfigRequest
 */
export interface GetProfileConfigRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
}
/**
 * will be used to configure eligibilty check.
 * currently, existence of message implies eligibility check should occur.
 *
 * @generated from protobuf message enrollment.bff.EligibilityConfig
 */
export interface EligibilityConfig {
    /**
     * Optional- customizes the noun referring to the eligibility key in forms/modals.
     * By default enrollment service calls it "Member ID" and adjusts first letter
     * casing based on context
     *
     * @generated from protobuf field: enrollment.bff.FormFieldLabelConfig elig_key_label = 1;
     */
    eligKeyLabel?: FormFieldLabelConfig;
    /**
     * Fields to perform eligibility check against -- defaults to all
     *
     * @generated from protobuf field: optional enrollment.bff.EligibilityConfig.EligibilityCheckFields elig_check_fields = 2;
     */
    eligCheckFields?: EligibilityConfig_EligibilityCheckFields;
}
/**
 * (-- api-linter: core::0126::unspecified=disabled
 *     aip.dev/not-precedent: Legacy enum that will be deprecated soon. --)
 *
 * @generated from protobuf enum enrollment.bff.EligibilityConfig.EligibilityCheckFields
 */
export enum EligibilityConfig_EligibilityCheckFields {
    /**
     * All fields: first name, last name, date of birth, and eligibility key.
     *
     * @generated from protobuf enum value: ALL = 0;
     */
    ALL = 0,
    /**
     * Only the date of birth and eligibility key.
     *
     * @generated from protobuf enum value: DOB_AND_ELIGIBILITY_KEY = 1;
     */
    DOB_AND_ELIGIBILITY_KEY = 1,
    /**
     * Only the email associated with the user's account. It is invalid to use
     * this option if login does not occur before th eligibility check.
     *
     * @generated from protobuf enum value: AUTH_EMAIL = 2;
     */
    AUTH_EMAIL = 2
}
/**
 * @generated from protobuf message enrollment.bff.GetProfileConfigResponse
 */
export interface GetProfileConfigResponse {
    /**
     * @generated from protobuf field: enrollment.bff.ProfileStyleConfig style_config = 1;
     */
    styleConfig?: ProfileStyleConfig;
    /**
     * Deprecated; replaced by flow_config.steps.participant_data.data_form_config
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig data_form_config = 2 [deprecated = true];
     */
    dataFormConfig?: ProfileDataFormConfig;
    /**
     * @deprecated
     * @generated from protobuf field: enrollment.bff.InclusionScreenerConfig inclusion_screener_config = 3 [deprecated = true];
     */
    inclusionScreenerConfig?: InclusionScreenerConfig;
    /**
     * @deprecated
     * @generated from protobuf field: enrollment.bff.ExclusionScreenerConfig exclusion_screener_config = 4 [deprecated = true];
     */
    exclusionScreenerConfig?: ExclusionScreenerConfig;
    /**
     * We now perform all authentication through CIAM and we determine if a given
     * RPC requires authentication on a per-call basis, depending on if the RPC
     * is called at a step before or after account creation (set in the FlowConfig)
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.AuthConfig auth_config = 5 [deprecated = true];
     */
    authConfig?: AuthConfig;
    /**
     * Deprecated; see PHP-10076. Use FlowConfig.ParticipantData.AgreementConsentsConfig instead.
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.PolicyConfig policy_config = 6 [deprecated = true];
     */
    policyConfig?: PolicyConfig;
    /**
     * Deprecated; replaced by flow_config.steps.participant_data.eligibility_config
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.EligibilityConfig eligibility_config = 7 [deprecated = true];
     */
    eligibilityConfig?: EligibilityConfig;
    /**
     * Represents the version of the configuration.  Expected to be incremented
     * any time any of the configurations for a profile change.
     *
     * @generated from protobuf field: uint32 config_version = 8;
     */
    configVersion: number;
    /**
     * Deprecated; use managing_org_id.
     *
     * @deprecated
     * @generated from protobuf field: string patient_sponsor = 9 [deprecated = true];
     */
    patientSponsor: string;
    /**
     * @generated from protobuf field: enrollment.bff.ResourceLink associated_resource = 10;
     */
    associatedResource?: ResourceLink;
    /**
     * @generated from protobuf field: enrollment.bff.ConsentSpecification consent = 11;
     */
    consent?: ConsentSpecification;
    /**
     * If this field is non-empty, potential consumers of this profile should
     * ignore it until the specified time. This can be used to schedule a profile
     * to go live without needing a human to be present to push the button.
     *
     * @generated from protobuf field: google.protobuf.Timestamp enable_on = 12;
     */
    enableOn?: Timestamp;
    /**
     * The FHIR store for data related to this profile. Deprecated; we use Cortex
     * to get a FHIR store proxy URL.
     *
     * @deprecated
     * @generated from protobuf field: string fhir_store = 13 [deprecated = true];
     */
    fhirStore: string;
    /**
     * Deprecated; replaced by flow_config.steps.participant_data.agreement_consents_config
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.AgreementConsentsConfig agreement_consents_config = 14 [deprecated = true];
     */
    agreementConsentsConfig?: AgreementConsentsConfig;
    /**
     * @generated from protobuf field: string vcms_survey_id = 15;
     */
    vcmsSurveyId: string;
    /**
     * Indicates that this profile should not use OneVerily services such as the
     * Consent Service and CIAM Service, and instead use alternatives such as
     * direct communication with FHIR and Auth0.
     * TODO(PHP-10525) remove this field.
     *
     * @generated from protobuf field: optional bool not_in_oneverily = 16;
     */
    notInOneverily?: boolean;
    /**
     * An identifier for an Organization in the style of Enterprise Organization
     * Management (see verily1/organization/common/converter/resource_name.go).
     * Currently this is a string of format "organizations/<FHIR resource ID>"
     * (case sensitive for the prefix).
     *
     * @generated from protobuf field: string managing_org_id = 17;
     */
    managingOrgId: string;
    /**
     * @generated from protobuf field: enrollment.bff.UserType user_type = 18;
     */
    userType: UserType;
    /**
     * Enrollment flow configuration which contains all 'steps' of the enrollment
     * flow; the ordering of the repeated Step field sets the order of the flow
     *
     * @generated from protobuf field: enrollment.bff.FlowConfig flow_config = 19;
     */
    flowConfig?: FlowConfig;
    /**
     * @generated from protobuf field: bool is_preview = 20;
     */
    isPreview: boolean;
    /**
     * @generated from protobuf field: repeated enrollment.bff.Prequalification prequalifications = 21;
     */
    prequalifications: Prequalification[];
}
/**
 * @generated from protobuf message enrollment.bff.Prequalification
 */
export interface Prequalification {
    /**
     * @generated from protobuf oneof: prequalification_type
     */
    prequalificationType: {
        oneofKind: "researchSubject";
        /**
         * @generated from protobuf field: enrollment.bff.Prequalification.ResearchSubjectQualification research_subject = 1;
         */
        researchSubject: Prequalification_ResearchSubjectQualification;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bff.Prequalification.ResearchSubjectQualification
 */
export interface Prequalification_ResearchSubjectQualification {
    /**
     * enum value should follow the pattern of the FHIR ResearchSubject.status
     * https://github.com/google/fhir/blob/master/proto/google/fhir/proto/r4/core/codes.proto
     * The required status of the ResearchSubject
     *
     * @generated from protobuf field: google.fhir.r4.core.ResearchSubjectStatusCode.Value required_status = 1;
     */
    requiredStatus: ResearchSubjectStatusCode_Value;
}
/**
 * @generated from protobuf message enrollment.bff.FlowConfig
 */
export interface FlowConfig {
    /**
     * @generated from protobuf field: repeated enrollment.bff.Step steps = 1;
     */
    steps: Step[];
}
/**
 * @generated from protobuf message enrollment.bff.Step
 */
export interface Step {
    /**
     * @generated from protobuf oneof: step
     */
    step: {
        oneofKind: "accountCreation";
        /**
         * @generated from protobuf field: enrollment.bff.AccountCreation account_creation = 1;
         */
        accountCreation: AccountCreation;
    } | {
        oneofKind: "participantData";
        /**
         * @generated from protobuf field: enrollment.bff.ParticipantData participant_data = 2;
         */
        participantData: ParticipantData;
    } | {
        oneofKind: "handoff";
        /**
         * Successful enrollment should end with handoff page or redirect to somewhere else
         *
         * @generated from protobuf field: enrollment.bff.Handoff handoff = 3;
         */
        handoff: Handoff;
    } | {
        oneofKind: "redirectUser";
        /**
         * @generated from protobuf field: enrollment.bff.RedirectUserStep redirect_user = 4;
         */
        redirectUser: RedirectUserStep;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bff.AccountCreation
 */
export interface AccountCreation {
}
/**
 * @generated from protobuf message enrollment.bff.ParticipantData
 */
export interface ParticipantData {
    /**
     * @generated from protobuf field: enrollment.bff.ProfileDataFormConfig data_form_config = 1;
     */
    dataFormConfig?: ProfileDataFormConfig;
    /**
     * @generated from protobuf field: enrollment.bff.EligibilityConfig eligibility_config = 2;
     */
    eligibilityConfig?: EligibilityConfig;
    /**
     * @generated from protobuf field: enrollment.bff.AgreementConsentsConfig agreement_consents_config = 3;
     */
    agreementConsentsConfig?: AgreementConsentsConfig;
}
/**
 * Enrollment step representing user completion that shows the product handoff page
 *
 * @generated from protobuf message enrollment.bff.Handoff
 */
export interface Handoff {
    /**
     * URL for product handoff to app store via QR code. This link should be
     * app-store agnostic and when scanned by a QR scanner, be able to
     * direct the user to their device's app store
     *
     * @generated from protobuf field: string app_handoff_link = 1;
     */
    appHandoffLink: string;
    /**
     * URL for product handoff to web app
     *
     * @generated from protobuf field: string web_handoff_link = 2;
     */
    webHandoffLink: string;
    /**
     * Optional URLs for product handoff to specific app stores. They will
     * supersede  app_handoff_link for their respective "Get it on ..." buttons.
     * If one or both of these are provided, app_handoff_link must be provided, as
     * even with store-specific links, a generic link is still necessary to
     * generate a store-agnostic QR code.
     *
     * @generated from protobuf field: string android_handoff_link = 3;
     */
    androidHandoffLink: string;
    /**
     * @generated from protobuf field: string ios_handoff_link = 4;
     */
    iosHandoffLink: string;
}
/**
 * Enrollment step representing user redirection to somewhere else.
 * Currently this is expected to be used in lieu of Handoff page as the final completion step
 * for flows that desire this
 *
 * @generated from protobuf message enrollment.bff.RedirectUserStep
 */
export interface RedirectUserStep {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * used to determine which Consent to give user
 *
 * @generated from protobuf message enrollment.bff.ConsentSpecification
 */
export interface ConsentSpecification {
    /**
     * @generated from protobuf field: string consent_id = 1;
     */
    consentId: string;
    /**
     * @generated from protobuf field: int32 consent_revision = 2;
     */
    consentRevision: number;
}
/**
 * @generated from protobuf message enrollment.bff.AuthConfig
 */
export interface AuthConfig {
    /**
     * Auth0 connection.
     *
     * @generated from protobuf field: string connection = 1;
     */
    connection: string;
    /**
     * Whether this connection is for an identity provider for which Auth0 is only
     * acting as an intermediary.
     * Deprecated; we no longer support IdP usage.
     *
     * @deprecated
     * @generated from protobuf field: bool is_idp = 2 [deprecated = true];
     */
    isIdp: boolean;
    /**
     * Additional query params to pass to the auth0 universal login flow.
     *
     * @generated from protobuf field: map<string, string> login_options = 3;
     */
    loginOptions: {
        [key: string]: string;
    };
    /**
     * Optional. If set to true email verification will be performed.
     *
     * @generated from protobuf field: bool require_email_verification = 4;
     */
    requireEmailVerification: boolean;
}
/**
 * TODO(PHP-5139) deprecate this message type and corresponding fields.
 *
 * @deprecated
 * @generated from protobuf message enrollment.bff.PolicyConfig
 */
export interface PolicyConfig {
    /**
     * @generated from protobuf field: repeated enrollment.bff.PolicyConfig.PolicySet policy_sets = 1;
     */
    policySets: PolicyConfig_PolicySet[];
}
/**
 * @generated from protobuf message enrollment.bff.PolicyConfig.Policy
 */
export interface PolicyConfig_Policy {
    /**
     * @generated from protobuf field: string policy_id = 1;
     */
    policyId: string;
    /**
     * @generated from protobuf field: string content_url = 2;
     */
    contentUrl: string;
    /**
     * @generated from protobuf field: string version = 3;
     */
    version: string;
    /**
     * @generated from protobuf field: string display_name = 4;
     */
    displayName: string;
}
/**
 * @generated from protobuf message enrollment.bff.PolicyConfig.PolicySet
 */
export interface PolicyConfig_PolicySet {
    /**
     * @generated from protobuf field: repeated enrollment.bff.PolicyConfig.Policy policies = 1;
     */
    policies: PolicyConfig_Policy[];
}
/**
 * Refers to one or more agreement consents from the Consent service.
 * Should not be used for informed consents.
 *
 * @generated from protobuf message enrollment.bff.AgreementConsentsConfig
 */
export interface AgreementConsentsConfig {
    /**
     * @generated from protobuf field: repeated enrollment.bff.AgreementConsentsConfig.AgreementConsentsGroup groups = 1;
     */
    groups: AgreementConsentsConfig_AgreementConsentsGroup[];
}
/**
 * @generated from protobuf message enrollment.bff.AgreementConsentsConfig.AgreementConsent
 */
export interface AgreementConsentsConfig_AgreementConsent {
    /**
     * @generated from protobuf field: enrollment.bff.ConsentSpecification consent = 1;
     */
    consent?: ConsentSpecification;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * @generated from protobuf message enrollment.bff.AgreementConsentsConfig.AgreementConsentsGroup
 */
export interface AgreementConsentsConfig_AgreementConsentsGroup {
    /**
     * @generated from protobuf field: repeated enrollment.bff.AgreementConsentsConfig.AgreementConsent consents = 1;
     */
    consents: AgreementConsentsConfig_AgreementConsent[];
}
/**
 * @generated from protobuf message enrollment.bff.GetDomainConfigRequest
 */
export interface GetDomainConfigRequest {
    /**
     * @generated from protobuf field: string domain = 1;
     */
    domain: string;
}
/**
 * @generated from protobuf message enrollment.bff.GetDomainConfigResponse
 */
export interface GetDomainConfigResponse {
    /**
     * @deprecated
     * @generated from protobuf field: enrollment.bff.AuthDomainConfig auth_config = 1 [deprecated = true];
     */
    authConfig?: AuthDomainConfig;
    /**
     * @deprecated
     * @generated from protobuf field: string fhir_path = 2 [deprecated = true];
     */
    fhirPath: string;
    /**
     * @deprecated
     * @generated from protobuf field: enrollment.bff.PostEnrollerConfig post_enroller_config = 3 [deprecated = true];
     */
    postEnrollerConfig?: PostEnrollerConfig;
    /**
     * @generated from protobuf field: string econsent_base_url = 4;
     */
    econsentBaseUrl: string;
    /**
     * We now use CIAM's Auth MFE for all domain configurations and
     * the values provided by this message are sourced from
     * enrollment-root-config's config.yaml and passed into the Auth MFE
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.FrontendAuthConfig frontend_auth_config = 5 [deprecated = true];
     */
    frontendAuthConfig?: FrontendAuthConfig;
    /**
     * We no longer support direct communication with non-CIAM owned
     * auth0 tenants. Any future feature requests which might require
     * this (such as supporting SSO) should be handled by the CIAM team.
     *
     * @deprecated
     * @generated from protobuf field: enrollment.bff.BackendAuthConfig backend_auth_config = 6 [deprecated = true];
     */
    backendAuthConfig?: BackendAuthConfig;
}
/**
 * Empty AuthDomainConfig implies that authentication is not required and
 * therefore configuration details are not needed
 *
 * @generated from protobuf message enrollment.bff.AuthDomainConfig
 */
export interface AuthDomainConfig {
    /**
     * @generated from protobuf field: string domain = 1;
     */
    domain: string;
    /**
     * @generated from protobuf field: string client_id = 2;
     */
    clientId: string;
    /**
     * @generated from protobuf field: string audience = 3;
     */
    audience: string;
    /**
     * @generated from protobuf field: string scope = 4;
     */
    scope: string;
}
/**
 * @generated from protobuf message enrollment.bff.FrontendAuthConfig
 */
export interface FrontendAuthConfig {
    /**
     * @generated from protobuf field: enrollment.bff.AuthDomainConfig auth_config = 1;
     */
    authConfig?: AuthDomainConfig;
}
/**
 * @generated from protobuf message enrollment.bff.BackendAuthConfig
 */
export interface BackendAuthConfig {
    /**
     * @generated from protobuf field: enrollment.bff.SecretConfig secret_config = 1;
     */
    secretConfig?: SecretConfig;
    /**
     * @generated from protobuf field: enrollment.bff.AuthDomainConfig auth_config = 2;
     */
    authConfig?: AuthDomainConfig;
    /**
     * @generated from protobuf field: enrollment.bff.ManagementApi management_api = 3;
     */
    managementApi?: ManagementApi;
}
/**
 * @generated from protobuf message enrollment.bff.SecretConfig
 */
export interface SecretConfig {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string secret_name = 2;
     */
    secretName: string;
}
/**
 * @generated from protobuf message enrollment.bff.ManagementApi
 */
export interface ManagementApi {
    /**
     * @generated from protobuf field: string domain = 1;
     */
    domain: string;
    /**
     * @generated from protobuf field: string client_id = 2;
     */
    clientId: string;
}
/**
 * @deprecated
 * @generated from protobuf message enrollment.bff.PostEnrollerConfig
 */
export interface PostEnrollerConfig {
    /**
     * @generated from protobuf oneof: config
     */
    config: {
        oneofKind: "onduo";
        /**
         * @generated from protobuf field: enrollment.bff.PostEnrollerConfig.Onduo onduo = 1;
         */
        onduo: PostEnrollerConfig_Onduo;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message enrollment.bff.PostEnrollerConfig.Onduo
 */
export interface PostEnrollerConfig_Onduo {
    /**
     * @generated from protobuf field: string hostname = 1;
     */
    hostname: string;
    /**
     * @generated from protobuf field: string firebase = 2;
     */
    firebase: string;
}
/**
 * @generated from protobuf message enrollment.bff.RecordEnrolleeEventRequest
 */
export interface RecordEnrolleeEventRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp observed_at = 1;
     */
    observedAt?: Timestamp;
    /**
     * IANA time zone, e.g. "America/New_York"
     *
     * @generated from protobuf field: string observed_time_zone = 2;
     */
    observedTimeZone: string;
    /**
     * @generated from protobuf field: enrollment.bff.EnrolleeEvent event = 3;
     */
    event: EnrolleeEvent;
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 4;
     */
    profile?: EnrollmentProfile;
}
/**
 * @generated from protobuf message enrollment.bff.RecordEnrolleeEventResponse
 */
export interface RecordEnrolleeEventResponse {
}
/**
 * @generated from protobuf message enrollment.bff.RecordUTMRequest
 */
export interface RecordUTMRequest {
    /**
     * @generated from protobuf field: repeated enrollment.bff.RecordUTMRequest.UTMTag utm_tags = 1;
     */
    utmTags: RecordUTMRequest_UTMTag[];
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 2;
     */
    profile?: EnrollmentProfile;
}
/**
 * @generated from protobuf message enrollment.bff.RecordUTMRequest.UTMTag
 */
export interface RecordUTMRequest_UTMTag {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message enrollment.bff.RecordUTMResponse
 */
export interface RecordUTMResponse {
}
/**
 * @generated from protobuf message enrollment.bff.LogInRequest
 */
export interface LogInRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
}
/**
 * @generated from protobuf message enrollment.bff.LogInResponse
 */
export interface LogInResponse {
}
/**
 * @generated from protobuf message enrollment.bff.GetUserStateRequest
 */
export interface GetUserStateRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 2;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bff.GetUserStateResponse
 */
export interface GetUserStateResponse {
    /**
     * @generated from protobuf field: enrollment.bff.PatientData patient_data = 1;
     */
    patientData?: PatientData;
    /**
     * @deprecated
     * @generated from protobuf field: enrollment.bff.ConsentData consent_data = 2 [deprecated = true];
     */
    consentData?: ConsentData;
    /**
     * @generated from protobuf field: enrollment.bff.InclusionScreenerData inclusion_data = 3;
     */
    inclusionData?: InclusionScreenerData;
    /**
     * @generated from protobuf field: enrollment.bff.ExclusionScreenerData exclusion_data = 4;
     */
    exclusionData?: ExclusionScreenerData;
    /**
     * @generated from protobuf field: bool is_complete = 5;
     */
    isComplete: boolean;
    /**
     * (Patient ID From https://www.hl7.org/fhir/resource.html), valid and unique
     * only within a specific FHIR store
     *
     * @generated from protobuf field: string patient_id = 6;
     */
    patientId: string;
}
/**
 * @generated from protobuf message enrollment.bff.StartSessionRequest
 */
export interface StartSessionRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 4;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: repeated enrollment.bff.StartSessionRequest.QueryParam query_params = 1;
     */
    queryParams: StartSessionRequest_QueryParam[];
    /**
     * The ISO8601 formatted user's time zone relative to UTC (±hh:mm). We set the
     * time zone here because we expect the session scope to be short-lived and
     * the user's time zone to be relatively stable within it.
     *
     * @generated from protobuf field: string time_zone = 2;
     */
    timeZone: string;
}
/**
 * @generated from protobuf message enrollment.bff.StartSessionRequest.QueryParam
 */
export interface StartSessionRequest_QueryParam {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message enrollment.bff.StartSessionResponse
 */
export interface StartSessionResponse {
    /**
     * @deprecated
     * @generated from protobuf field: string session_token = 1 [deprecated = true];
     */
    sessionToken: string;
}
/**
 * @generated from protobuf message enrollment.bff.ReportErrorRequest
 */
export interface ReportErrorRequest {
    /**
     * @generated from protobuf field: string location = 1;
     */
    location: string;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * @generated from protobuf field: string stack = 3;
     */
    stack: string;
}
/**
 * @generated from protobuf message enrollment.bff.ReportErrorResponse
 */
export interface ReportErrorResponse {
}
/**
 * @generated from protobuf message enrollment.bff.CheckAuthenticationRequest
 */
export interface CheckAuthenticationRequest {
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 1;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: enrollment.bff.Step current_step = 2;
     */
    currentStep?: Step;
}
/**
 * @generated from protobuf message enrollment.bff.CheckAuthenticationResponse
 */
export interface CheckAuthenticationResponse {
    /**
     * @deprecated
     * @generated from protobuf field: string session_token = 1 [deprecated = true];
     */
    sessionToken: string;
}
/**
 * @generated from protobuf message enrollment.bff.GetPasswordResetReturnProfileRequest
 */
export interface GetPasswordResetReturnProfileRequest {
    /**
     * @generated from protobuf field: string domain = 1;
     */
    domain: string;
}
/**
 * @generated from protobuf message enrollment.bff.GetPasswordResetReturnProfileResponse
 */
export interface GetPasswordResetReturnProfileResponse {
    /**
     * @generated from protobuf field: string profile = 1;
     */
    profile: string;
}
/**
 * @generated from protobuf message enrollment.bff.ResendPasswordResetEmailRequest
 */
export interface ResendPasswordResetEmailRequest {
    /**
     * @generated from protobuf field: string email_address = 1;
     */
    emailAddress: string;
    /**
     * @generated from protobuf field: enrollment.bff.EnrollmentProfile profile = 2;
     */
    profile?: EnrollmentProfile;
    /**
     * @generated from protobuf field: string auth0_id = 3;
     */
    auth0Id: string;
}
/**
 * @generated from protobuf message enrollment.bff.ResendPasswordResetEmailResponse
 */
export interface ResendPasswordResetEmailResponse {
}
/**
 * http://hl7.org/fhir/R4/valueset-gender-identity.html
 *
 * @generated from protobuf enum enrollment.bff.GenderIdentity
 */
export enum GenderIdentity {
    /**
     * @generated from protobuf enum value: GENDER_IDENTITY_UNSPECIFIED = 0;
     */
    GENDER_IDENTITY_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: MALE = 1;
     */
    MALE = 1,
    /**
     * @generated from protobuf enum value: FEMALE = 2;
     */
    FEMALE = 2,
    /**
     * @generated from protobuf enum value: NON_BINARY = 3;
     */
    NON_BINARY = 3,
    /**
     * @generated from protobuf enum value: TRANSGENDER_MALE = 4;
     */
    TRANSGENDER_MALE = 4,
    /**
     * @generated from protobuf enum value: TRANSGENDER_FEMALE = 5;
     */
    TRANSGENDER_FEMALE = 5,
    /**
     * @generated from protobuf enum value: OTHER = 6;
     */
    OTHER = 6,
    /**
     * @generated from protobuf enum value: NON_DISCLOSE = 7;
     */
    NON_DISCLOSE = 7
}
/**
 * The type of user completing the enrollment flow. Most of the time
 * the user completing the flow is the patient themselves, but there
 * are cases in which the patient is not the one completing the flow, such as
 * when a caregiver completes the flow for a dependent.
 * (-- api-linter: core::0126::unspecified=disabled
 *     aip.dev/not-precedent: Legacy enum that will be deprecated soon. --)
 *
 * @generated from protobuf enum enrollment.bff.UserType
 */
export enum UserType {
    /**
     * @generated from protobuf enum value: INVALID_USER_TYPE = 0;
     */
    INVALID_USER_TYPE = 0,
    /**
     * Individual user completing enrollment on their own behalf -- default
     *
     * @generated from protobuf enum value: INDIVIDUAL = 1;
     */
    INDIVIDUAL = 1,
    /**
     * Adult user completing enrollment flow on behalf of a minor
     *
     * @generated from protobuf enum value: PEDIATRIC = 2;
     */
    PEDIATRIC = 2
}
/**
 * @generated from protobuf enum enrollment.bff.EnrolleeEvent
 */
export enum EnrolleeEvent {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * Events to fire when pages of enrollment flow are visited.
     *
     * @generated from protobuf enum value: VISIT_PATIENT_DATA = 1;
     */
    VISIT_PATIENT_DATA = 1,
    /**
     * @generated from protobuf enum value: VISIT_INELIGIBLE = 2;
     */
    VISIT_INELIGIBLE = 2,
    /**
     * @generated from protobuf enum value: VISIT_INCLUSION = 3;
     */
    VISIT_INCLUSION = 3,
    /**
     * @generated from protobuf enum value: VISIT_EXCLUSION = 4;
     */
    VISIT_EXCLUSION = 4,
    /**
     * @generated from protobuf enum value: VISIT_PRODUCT_HANDOFF = 5;
     */
    VISIT_PRODUCT_HANDOFF = 5,
    /**
     * VISIT_WELCOME = 6 referred to pre-auth page deprecated in
     * https://github.com/verily-src/vhp-enrollment-fe-client/pull/225
     *
     * @generated from protobuf enum value: VISIT_SCREENED_OUT = 7;
     */
    VISIT_SCREENED_OUT = 7,
    /**
     * @generated from protobuf enum value: VISIT_ELIGIBILITY = 8;
     */
    VISIT_ELIGIBILITY = 8,
    /**
     * @generated from protobuf enum value: LEAVE_FOR_ECONSENT = 9;
     */
    LEAVE_FOR_ECONSENT = 9,
    /**
     * @generated from protobuf enum value: VISIT_CONSENT_DECLINED = 10;
     */
    VISIT_CONSENT_DECLINED = 10,
    /**
     * @generated from protobuf enum value: VISIT_ELIGIBLE_USER_ALREADY_ENROLLED = 11;
     */
    VISIT_ELIGIBLE_USER_ALREADY_ENROLLED = 11,
    /**
     * Enrollment completion that ends in external redirect
     *
     * @generated from protobuf enum value: VISIT_USER_REDIRECT = 12;
     */
    VISIT_USER_REDIRECT = 12,
    /**
     * @generated from protobuf enum value: VISIT_RECRUIT_INITIALIZATION = 13;
     */
    VISIT_RECRUIT_INITIALIZATION = 13,
    /**
     * @generated from protobuf enum value: VISIT_NUX_INITIALIZATION = 14;
     */
    VISIT_NUX_INITIALIZATION = 14,
    /**
     * @generated from protobuf enum value: VISIT_NUX_PATIENT_DATA = 15;
     */
    VISIT_NUX_PATIENT_DATA = 15
}
// @generated message type with reflection information, may provide speed optimized methods
class Address$Type extends MessageType<Address> {
    constructor() {
        super("enrollment.bff.Address", [
            { no: 1, name: "street_address", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { repeated: { items: { string: { pattern: "^[A-Za-z0-9][A-Za-z0-9\\s'\u2014\\-,.]*$", ignoreEmpty: true } } } } } },
            { no: 2, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[A-Za-z\\s]+$" } } } },
            { no: 3, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[a-zA-Z][a-zA-Z\\s'\u2014\\-. ,]*$" } } } },
            { no: 4, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[0-9\u2014-]+$" } } } },
            { no: 5, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[A-Za-z\\s]+$", ignoreEmpty: true } } } }
        ]);
    }
    create(value?: PartialMessage<Address>): Address {
        const message = { streetAddress: [], state: "", city: "", zipCode: "", country: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Address>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Address): Address {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string street_address */ 1:
                    message.streetAddress.push(reader.string());
                    break;
                case /* string state */ 2:
                    message.state = reader.string();
                    break;
                case /* string city */ 3:
                    message.city = reader.string();
                    break;
                case /* string zip_code */ 4:
                    message.zipCode = reader.string();
                    break;
                case /* string country */ 5:
                    message.country = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Address, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string street_address = 1; */
        for (let i = 0; i < message.streetAddress.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.streetAddress[i]);
        /* string state = 2; */
        if (message.state !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.state);
        /* string city = 3; */
        if (message.city !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.city);
        /* string zip_code = 4; */
        if (message.zipCode !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.zipCode);
        /* string country = 5; */
        if (message.country !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.country);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Address
 */
export const Address = new Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Name$Type extends MessageType<Name> {
    constructor() {
        super("enrollment.bff.Name", [
            { no: 1, name: "family", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "given", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Name>): Name {
        const message = { family: "", given: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Name>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Name): Name {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string family */ 1:
                    message.family = reader.string();
                    break;
                case /* string given */ 2:
                    message.given = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Name, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string family = 1; */
        if (message.family !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.family);
        /* string given = 2; */
        if (message.given !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.given);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Name
 */
export const Name = new Name$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Date$Type extends MessageType<Date> {
    constructor() {
        super("enrollment.bff.Date", [
            { no: 1, name: "year", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "month", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "day", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Date>): Date {
        const message = { year: 0, month: 0, day: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Date>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Date): Date {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 year */ 1:
                    message.year = reader.int32();
                    break;
                case /* int32 month */ 2:
                    message.month = reader.int32();
                    break;
                case /* int32 day */ 3:
                    message.day = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Date, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 year = 1; */
        if (message.year !== 0)
            writer.tag(1, WireType.Varint).int32(message.year);
        /* int32 month = 2; */
        if (message.month !== 0)
            writer.tag(2, WireType.Varint).int32(message.month);
        /* int32 day = 3; */
        if (message.day !== 0)
            writer.tag(3, WireType.Varint).int32(message.day);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Date
 */
export const Date = new Date$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Telecom$Type extends MessageType<Telecom> {
    constructor() {
        super("enrollment.bff.Telecom", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "system", kind: "enum", T: () => ["enrollment.bff.Telecom.ContactPointSystem", Telecom_ContactPointSystem] }
        ]);
    }
    create(value?: PartialMessage<Telecom>): Telecom {
        const message = { value: "", system: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Telecom>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Telecom): Telecom {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                case /* enrollment.bff.Telecom.ContactPointSystem system */ 2:
                    message.system = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Telecom, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        /* enrollment.bff.Telecom.ContactPointSystem system = 2; */
        if (message.system !== 0)
            writer.tag(2, WireType.Varint).int32(message.system);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Telecom
 */
export const Telecom = new Telecom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PatientData$Type extends MessageType<PatientData> {
    constructor() {
        super("enrollment.bff.PatientData", [
            { no: 1, name: "name", kind: "message", T: () => Name },
            { no: 2, name: "date_of_birth", kind: "message", T: () => Date },
            { no: 4, name: "telecom", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Telecom },
            { no: 5, name: "gender_identity", kind: "enum", T: () => ["enrollment.bff.GenderIdentity", GenderIdentity] },
            { no: 6, name: "enrollment_verification_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[A-Za-z0-9_\\s\u2014-]+$", ignoreEmpty: true } } } },
            { no: 7, name: "address", kind: "message", T: () => Address }
        ]);
    }
    create(value?: PartialMessage<PatientData>): PatientData {
        const message = { telecom: [], genderIdentity: 0, enrollmentVerificationKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PatientData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PatientData): PatientData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.Name name */ 1:
                    message.name = Name.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* enrollment.bff.Date date_of_birth */ 2:
                    message.dateOfBirth = Date.internalBinaryRead(reader, reader.uint32(), options, message.dateOfBirth);
                    break;
                case /* repeated enrollment.bff.Telecom telecom */ 4:
                    message.telecom.push(Telecom.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* enrollment.bff.GenderIdentity gender_identity */ 5:
                    message.genderIdentity = reader.int32();
                    break;
                case /* string enrollment_verification_key */ 6:
                    message.enrollmentVerificationKey = reader.string();
                    break;
                case /* enrollment.bff.Address address */ 7:
                    message.address = Address.internalBinaryRead(reader, reader.uint32(), options, message.address);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PatientData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.Name name = 1; */
        if (message.name)
            Name.internalBinaryWrite(message.name, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Date date_of_birth = 2; */
        if (message.dateOfBirth)
            Date.internalBinaryWrite(message.dateOfBirth, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated enrollment.bff.Telecom telecom = 4; */
        for (let i = 0; i < message.telecom.length; i++)
            Telecom.internalBinaryWrite(message.telecom[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.GenderIdentity gender_identity = 5; */
        if (message.genderIdentity !== 0)
            writer.tag(5, WireType.Varint).int32(message.genderIdentity);
        /* string enrollment_verification_key = 6; */
        if (message.enrollmentVerificationKey !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.enrollmentVerificationKey);
        /* enrollment.bff.Address address = 7; */
        if (message.address)
            Address.internalBinaryWrite(message.address, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.PatientData
 */
export const PatientData = new PatientData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordPatientDataRequest$Type extends MessageType<RecordPatientDataRequest> {
    constructor() {
        super("enrollment.bff.RecordPatientDataRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "data", kind: "message", T: () => PatientData },
            { no: 3, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<RecordPatientDataRequest>): RecordPatientDataRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordPatientDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordPatientDataRequest): RecordPatientDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.PatientData data */ 2:
                    message.data = PatientData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* enrollment.bff.Step current_step */ 3:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordPatientDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.PatientData data = 2; */
        if (message.data)
            PatientData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Step current_step = 3; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordPatientDataRequest
 */
export const RecordPatientDataRequest = new RecordPatientDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordPatientDataResponse$Type extends MessageType<RecordPatientDataResponse> {
    constructor() {
        super("enrollment.bff.RecordPatientDataResponse", []);
    }
    create(value?: PartialMessage<RecordPatientDataResponse>): RecordPatientDataResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordPatientDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordPatientDataResponse): RecordPatientDataResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordPatientDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordPatientDataResponse
 */
export const RecordPatientDataResponse = new RecordPatientDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormFieldLabelConfig$Type extends MessageType<FormFieldLabelConfig> {
    constructor() {
        super("enrollment.bff.FormFieldLabelConfig", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 2, name: "exact_casing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FormFieldLabelConfig>): FormFieldLabelConfig {
        const message = { label: "", exactCasing: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FormFieldLabelConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormFieldLabelConfig): FormFieldLabelConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* bool exact_casing */ 2:
                    message.exactCasing = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FormFieldLabelConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* bool exact_casing = 2; */
        if (message.exactCasing !== false)
            writer.tag(2, WireType.Varint).bool(message.exactCasing);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.FormFieldLabelConfig
 */
export const FormFieldLabelConfig = new FormFieldLabelConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceLink$Type extends MessageType<ResourceLink> {
    constructor() {
        super("enrollment.bff.ResourceLink", [
            { no: 1, name: "research_study_id", kind: "scalar", oneof: "linkOneof", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "healthcare_service_id", kind: "scalar", oneof: "linkOneof", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResourceLink>): ResourceLink {
        const message = { linkOneof: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResourceLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResourceLink): ResourceLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string research_study_id */ 1:
                    message.linkOneof = {
                        oneofKind: "researchStudyId",
                        researchStudyId: reader.string()
                    };
                    break;
                case /* string healthcare_service_id */ 2:
                    message.linkOneof = {
                        oneofKind: "healthcareServiceId",
                        healthcareServiceId: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResourceLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string research_study_id = 1; */
        if (message.linkOneof.oneofKind === "researchStudyId")
            writer.tag(1, WireType.LengthDelimited).string(message.linkOneof.researchStudyId);
        /* string healthcare_service_id = 2; */
        if (message.linkOneof.oneofKind === "healthcareServiceId")
            writer.tag(2, WireType.LengthDelimited).string(message.linkOneof.healthcareServiceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ResourceLink
 */
export const ResourceLink = new ResourceLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InclusionScreenerData$Type extends MessageType<InclusionScreenerData> {
    constructor() {
        super("enrollment.bff.InclusionScreenerData", [
            { no: 1, name: "questions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BooleanQuestionAnswer }
        ]);
    }
    create(value?: PartialMessage<InclusionScreenerData>): InclusionScreenerData {
        const message = { questions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InclusionScreenerData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InclusionScreenerData): InclusionScreenerData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.BooleanQuestionAnswer questions */ 1:
                    message.questions.push(BooleanQuestionAnswer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InclusionScreenerData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.BooleanQuestionAnswer questions = 1; */
        for (let i = 0; i < message.questions.length; i++)
            BooleanQuestionAnswer.internalBinaryWrite(message.questions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.InclusionScreenerData
 */
export const InclusionScreenerData = new InclusionScreenerData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAccountRequest$Type extends MessageType<CreateAccountRequest> {
    constructor() {
        super("enrollment.bff.CreateAccountRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "send_password_reset_email", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAccountRequest>): CreateAccountRequest {
        const message = { emailAddress: "", sendPasswordResetEmail: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAccountRequest): CreateAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* string email_address */ 2:
                    message.emailAddress = reader.string();
                    break;
                case /* bool send_password_reset_email */ 3:
                    message.sendPasswordResetEmail = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string email_address = 2; */
        if (message.emailAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.emailAddress);
        /* bool send_password_reset_email = 3; */
        if (message.sendPasswordResetEmail !== false)
            writer.tag(3, WireType.Varint).bool(message.sendPasswordResetEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CreateAccountRequest
 */
export const CreateAccountRequest = new CreateAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAccountResponse$Type extends MessageType<CreateAccountResponse> {
    constructor() {
        super("enrollment.bff.CreateAccountResponse", [
            { no: 1, name: "password_reset_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "auth0_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAccountResponse>): CreateAccountResponse {
        const message = { passwordResetUrl: "", auth0Id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAccountResponse): CreateAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string password_reset_url */ 1:
                    message.passwordResetUrl = reader.string();
                    break;
                case /* string auth0_id */ 2:
                    message.auth0Id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string password_reset_url = 1; */
        if (message.passwordResetUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.passwordResetUrl);
        /* string auth0_id = 2; */
        if (message.auth0Id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.auth0Id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CreateAccountResponse
 */
export const CreateAccountResponse = new CreateAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordInclusionScreenerDataRequest$Type extends MessageType<RecordInclusionScreenerDataRequest> {
    constructor() {
        super("enrollment.bff.RecordInclusionScreenerDataRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "data", kind: "message", T: () => InclusionScreenerData }
        ]);
    }
    create(value?: PartialMessage<RecordInclusionScreenerDataRequest>): RecordInclusionScreenerDataRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordInclusionScreenerDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordInclusionScreenerDataRequest): RecordInclusionScreenerDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.InclusionScreenerData data */ 2:
                    message.data = InclusionScreenerData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordInclusionScreenerDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.InclusionScreenerData data = 2; */
        if (message.data)
            InclusionScreenerData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordInclusionScreenerDataRequest
 */
export const RecordInclusionScreenerDataRequest = new RecordInclusionScreenerDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordInclusionScreenerDataResponse$Type extends MessageType<RecordInclusionScreenerDataResponse> {
    constructor() {
        super("enrollment.bff.RecordInclusionScreenerDataResponse", []);
    }
    create(value?: PartialMessage<RecordInclusionScreenerDataResponse>): RecordInclusionScreenerDataResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordInclusionScreenerDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordInclusionScreenerDataResponse): RecordInclusionScreenerDataResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordInclusionScreenerDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordInclusionScreenerDataResponse
 */
export const RecordInclusionScreenerDataResponse = new RecordInclusionScreenerDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExclusionScreenerData$Type extends MessageType<ExclusionScreenerData> {
    constructor() {
        super("enrollment.bff.ExclusionScreenerData", [
            { no: 1, name: "questions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BooleanQuestionAnswer }
        ]);
    }
    create(value?: PartialMessage<ExclusionScreenerData>): ExclusionScreenerData {
        const message = { questions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExclusionScreenerData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExclusionScreenerData): ExclusionScreenerData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.BooleanQuestionAnswer questions */ 1:
                    message.questions.push(BooleanQuestionAnswer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExclusionScreenerData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.BooleanQuestionAnswer questions = 1; */
        for (let i = 0; i < message.questions.length; i++)
            BooleanQuestionAnswer.internalBinaryWrite(message.questions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ExclusionScreenerData
 */
export const ExclusionScreenerData = new ExclusionScreenerData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordExclusionScreenerDataRequest$Type extends MessageType<RecordExclusionScreenerDataRequest> {
    constructor() {
        super("enrollment.bff.RecordExclusionScreenerDataRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "data", kind: "message", T: () => ExclusionScreenerData }
        ]);
    }
    create(value?: PartialMessage<RecordExclusionScreenerDataRequest>): RecordExclusionScreenerDataRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordExclusionScreenerDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordExclusionScreenerDataRequest): RecordExclusionScreenerDataRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.ExclusionScreenerData data */ 2:
                    message.data = ExclusionScreenerData.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordExclusionScreenerDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ExclusionScreenerData data = 2; */
        if (message.data)
            ExclusionScreenerData.internalBinaryWrite(message.data, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordExclusionScreenerDataRequest
 */
export const RecordExclusionScreenerDataRequest = new RecordExclusionScreenerDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordExclusionScreenerDataResponse$Type extends MessageType<RecordExclusionScreenerDataResponse> {
    constructor() {
        super("enrollment.bff.RecordExclusionScreenerDataResponse", []);
    }
    create(value?: PartialMessage<RecordExclusionScreenerDataResponse>): RecordExclusionScreenerDataResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordExclusionScreenerDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordExclusionScreenerDataResponse): RecordExclusionScreenerDataResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordExclusionScreenerDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordExclusionScreenerDataResponse
 */
export const RecordExclusionScreenerDataResponse = new RecordExclusionScreenerDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentData$Type extends MessageType<ConsentData> {
    constructor() {
        super("enrollment.bff.ConsentData", [
            { no: 1, name: "consents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ConsentData_PolicySetConsent }
        ]);
    }
    create(value?: PartialMessage<ConsentData>): ConsentData {
        const message = { consents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentData): ConsentData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.ConsentData.PolicySetConsent consents */ 1:
                    message.consents.push(ConsentData_PolicySetConsent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.ConsentData.PolicySetConsent consents = 1; */
        for (let i = 0; i < message.consents.length; i++)
            ConsentData_PolicySetConsent.internalBinaryWrite(message.consents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ConsentData
 */
export const ConsentData = new ConsentData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentData_PolicySetConsent$Type extends MessageType<ConsentData_PolicySetConsent> {
    constructor() {
        super("enrollment.bff.ConsentData.PolicySetConsent", [
            { no: 1, name: "consented_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ConsentData_PolicySetConsent>): ConsentData_PolicySetConsent {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentData_PolicySetConsent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentData_PolicySetConsent): ConsentData_PolicySetConsent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp consented_at */ 1:
                    message.consentedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.consentedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentData_PolicySetConsent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp consented_at = 1; */
        if (message.consentedAt)
            Timestamp.internalBinaryWrite(message.consentedAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ConsentData.PolicySetConsent
 */
export const ConsentData_PolicySetConsent = new ConsentData_PolicySetConsent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgreementConsentsRequest$Type extends MessageType<GetAgreementConsentsRequest> {
    constructor() {
        super("enrollment.bff.GetAgreementConsentsRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<GetAgreementConsentsRequest>): GetAgreementConsentsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgreementConsentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgreementConsentsRequest): GetAgreementConsentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.Step current_step */ 2:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgreementConsentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Step current_step = 2; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetAgreementConsentsRequest
 */
export const GetAgreementConsentsRequest = new GetAgreementConsentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent$Type extends MessageType<AgreementConsentContent> {
    constructor() {
        super("enrollment.bff.AgreementConsentContent", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "modules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentContent_Module }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent>): AgreementConsentContent {
        const message = { title: "", modules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent): AgreementConsentContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* repeated enrollment.bff.AgreementConsentContent.Module modules */ 2:
                    message.modules.push(AgreementConsentContent_Module.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* repeated enrollment.bff.AgreementConsentContent.Module modules = 2; */
        for (let i = 0; i < message.modules.length; i++)
            AgreementConsentContent_Module.internalBinaryWrite(message.modules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentContent
 */
export const AgreementConsentContent = new AgreementConsentContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Html$Type extends MessageType<AgreementConsentContent_Html> {
    constructor() {
        super("enrollment.bff.AgreementConsentContent.Html", [
            { no: 1, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Html>): AgreementConsentContent_Html {
        const message = { html: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Html>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Html): AgreementConsentContent_Html {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string html */ 1:
                    message.html = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Html, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string html = 1; */
        if (message.html !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.html);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentContent.Html
 */
export const AgreementConsentContent_Html = new AgreementConsentContent_Html$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Links$Type extends MessageType<AgreementConsentContent_Links> {
    constructor() {
        super("enrollment.bff.AgreementConsentContent.Links", [
            { no: 1, name: "links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentContent_Links_Link }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Links>): AgreementConsentContent_Links {
        const message = { links: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Links>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Links): AgreementConsentContent_Links {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.AgreementConsentContent.Links.Link links */ 1:
                    message.links.push(AgreementConsentContent_Links_Link.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Links, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.AgreementConsentContent.Links.Link links = 1; */
        for (let i = 0; i < message.links.length; i++)
            AgreementConsentContent_Links_Link.internalBinaryWrite(message.links[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentContent.Links
 */
export const AgreementConsentContent_Links = new AgreementConsentContent_Links$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Links_Link$Type extends MessageType<AgreementConsentContent_Links_Link> {
    constructor() {
        super("enrollment.bff.AgreementConsentContent.Links.Link", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Links_Link>): AgreementConsentContent_Links_Link {
        const message = { text: "", url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Links_Link>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Links_Link): AgreementConsentContent_Links_Link {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Links_Link, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentContent.Links.Link
 */
export const AgreementConsentContent_Links_Link = new AgreementConsentContent_Links_Link$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Checkbox$Type extends MessageType<AgreementConsentContent_Checkbox> {
    constructor() {
        super("enrollment.bff.AgreementConsentContent.Checkbox", [
            { no: 1, name: "signing_reasons", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Checkbox>): AgreementConsentContent_Checkbox {
        const message = { signingReasons: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Checkbox>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Checkbox): AgreementConsentContent_Checkbox {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string signing_reasons */ 1:
                    message.signingReasons.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Checkbox, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string signing_reasons = 1; */
        for (let i = 0; i < message.signingReasons.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.signingReasons[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentContent.Checkbox
 */
export const AgreementConsentContent_Checkbox = new AgreementConsentContent_Checkbox$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentContent_Module$Type extends MessageType<AgreementConsentContent_Module> {
    constructor() {
        super("enrollment.bff.AgreementConsentContent.Module", [
            { no: 1, name: "html", kind: "message", oneof: "module", T: () => AgreementConsentContent_Html },
            { no: 2, name: "links", kind: "message", oneof: "module", T: () => AgreementConsentContent_Links },
            { no: 3, name: "checkbox", kind: "message", oneof: "module", T: () => AgreementConsentContent_Checkbox }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentContent_Module>): AgreementConsentContent_Module {
        const message = { module: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentContent_Module>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentContent_Module): AgreementConsentContent_Module {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.AgreementConsentContent.Html html */ 1:
                    message.module = {
                        oneofKind: "html",
                        html: AgreementConsentContent_Html.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).html)
                    };
                    break;
                case /* enrollment.bff.AgreementConsentContent.Links links */ 2:
                    message.module = {
                        oneofKind: "links",
                        links: AgreementConsentContent_Links.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).links)
                    };
                    break;
                case /* enrollment.bff.AgreementConsentContent.Checkbox checkbox */ 3:
                    message.module = {
                        oneofKind: "checkbox",
                        checkbox: AgreementConsentContent_Checkbox.internalBinaryRead(reader, reader.uint32(), options, (message.module as any).checkbox)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentContent_Module, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.AgreementConsentContent.Html html = 1; */
        if (message.module.oneofKind === "html")
            AgreementConsentContent_Html.internalBinaryWrite(message.module.html, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.AgreementConsentContent.Links links = 2; */
        if (message.module.oneofKind === "links")
            AgreementConsentContent_Links.internalBinaryWrite(message.module.links, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.AgreementConsentContent.Checkbox checkbox = 3; */
        if (message.module.oneofKind === "checkbox")
            AgreementConsentContent_Checkbox.internalBinaryWrite(message.module.checkbox, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentContent.Module
 */
export const AgreementConsentContent_Module = new AgreementConsentContent_Module$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgreementConsentsResponse$Type extends MessageType<GetAgreementConsentsResponse> {
    constructor() {
        super("enrollment.bff.GetAgreementConsentsResponse", [
            { no: 1, name: "consents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentContent }
        ]);
    }
    create(value?: PartialMessage<GetAgreementConsentsResponse>): GetAgreementConsentsResponse {
        const message = { consents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAgreementConsentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgreementConsentsResponse): GetAgreementConsentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.AgreementConsentContent consents */ 1:
                    message.consents.push(AgreementConsentContent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgreementConsentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.AgreementConsentContent consents = 1; */
        for (let i = 0; i < message.consents.length; i++)
            AgreementConsentContent.internalBinaryWrite(message.consents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetAgreementConsentsResponse
 */
export const GetAgreementConsentsResponse = new GetAgreementConsentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordPolicyConsentsRequest$Type extends MessageType<RecordPolicyConsentsRequest> {
    constructor() {
        super("enrollment.bff.RecordPolicyConsentsRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "acceptance", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RecordPolicyConsentsRequest_ConsentAcceptance }
        ]);
    }
    create(value?: PartialMessage<RecordPolicyConsentsRequest>): RecordPolicyConsentsRequest {
        const message = { acceptance: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordPolicyConsentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordPolicyConsentsRequest): RecordPolicyConsentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* repeated enrollment.bff.RecordPolicyConsentsRequest.ConsentAcceptance acceptance */ 2:
                    message.acceptance.push(RecordPolicyConsentsRequest_ConsentAcceptance.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordPolicyConsentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated enrollment.bff.RecordPolicyConsentsRequest.ConsentAcceptance acceptance = 2; */
        for (let i = 0; i < message.acceptance.length; i++)
            RecordPolicyConsentsRequest_ConsentAcceptance.internalBinaryWrite(message.acceptance[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message enrollment.bff.RecordPolicyConsentsRequest
 */
export const RecordPolicyConsentsRequest = new RecordPolicyConsentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordPolicyConsentsRequest_ConsentAcceptance$Type extends MessageType<RecordPolicyConsentsRequest_ConsentAcceptance> {
    constructor() {
        super("enrollment.bff.RecordPolicyConsentsRequest.ConsentAcceptance", [
            { no: 1, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecordPolicyConsentsRequest_ConsentAcceptance>): RecordPolicyConsentsRequest_ConsentAcceptance {
        const message = { consentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordPolicyConsentsRequest_ConsentAcceptance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordPolicyConsentsRequest_ConsentAcceptance): RecordPolicyConsentsRequest_ConsentAcceptance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_id */ 1:
                    message.consentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordPolicyConsentsRequest_ConsentAcceptance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_id = 1; */
        if (message.consentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordPolicyConsentsRequest.ConsentAcceptance
 */
export const RecordPolicyConsentsRequest_ConsentAcceptance = new RecordPolicyConsentsRequest_ConsentAcceptance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordPolicyConsentsResponse$Type extends MessageType<RecordPolicyConsentsResponse> {
    constructor() {
        super("enrollment.bff.RecordPolicyConsentsResponse", []);
    }
    create(value?: PartialMessage<RecordPolicyConsentsResponse>): RecordPolicyConsentsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordPolicyConsentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordPolicyConsentsResponse): RecordPolicyConsentsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordPolicyConsentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message enrollment.bff.RecordPolicyConsentsResponse
 */
export const RecordPolicyConsentsResponse = new RecordPolicyConsentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteEnrollmentRequest$Type extends MessageType<CompleteEnrollmentRequest> {
    constructor() {
        super("enrollment.bff.CompleteEnrollmentRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<CompleteEnrollmentRequest>): CompleteEnrollmentRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteEnrollmentRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteEnrollmentRequest): CompleteEnrollmentRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.Step current_step */ 2:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteEnrollmentRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Step current_step = 2; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CompleteEnrollmentRequest
 */
export const CompleteEnrollmentRequest = new CompleteEnrollmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteEnrollmentResponse$Type extends MessageType<CompleteEnrollmentResponse> {
    constructor() {
        super("enrollment.bff.CompleteEnrollmentResponse", []);
    }
    create(value?: PartialMessage<CompleteEnrollmentResponse>): CompleteEnrollmentResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteEnrollmentResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteEnrollmentResponse): CompleteEnrollmentResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CompleteEnrollmentResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CompleteEnrollmentResponse
 */
export const CompleteEnrollmentResponse = new CompleteEnrollmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnrollPatientRequest$Type extends MessageType<EnrollPatientRequest> {
    constructor() {
        super("enrollment.bff.EnrollPatientRequest", [
            { no: 1, name: "patient_data", kind: "message", T: () => PatientData },
            { no: 2, name: "inclusion_data", kind: "message", T: () => InclusionScreenerData },
            { no: 3, name: "exclusion_data", kind: "message", T: () => ExclusionScreenerData },
            { no: 4, name: "is_complete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 6, name: "consent_data", kind: "message", T: () => ConsentData },
            { no: 7, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<EnrollPatientRequest>): EnrollPatientRequest {
        const message = { isComplete: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnrollPatientRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnrollPatientRequest): EnrollPatientRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.PatientData patient_data */ 1:
                    message.patientData = PatientData.internalBinaryRead(reader, reader.uint32(), options, message.patientData);
                    break;
                case /* enrollment.bff.InclusionScreenerData inclusion_data */ 2:
                    message.inclusionData = InclusionScreenerData.internalBinaryRead(reader, reader.uint32(), options, message.inclusionData);
                    break;
                case /* enrollment.bff.ExclusionScreenerData exclusion_data */ 3:
                    message.exclusionData = ExclusionScreenerData.internalBinaryRead(reader, reader.uint32(), options, message.exclusionData);
                    break;
                case /* bool is_complete */ 4:
                    message.isComplete = reader.bool();
                    break;
                case /* enrollment.bff.EnrollmentProfile profile */ 5:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.ConsentData consent_data */ 6:
                    message.consentData = ConsentData.internalBinaryRead(reader, reader.uint32(), options, message.consentData);
                    break;
                case /* enrollment.bff.Step current_step */ 7:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnrollPatientRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.PatientData patient_data = 1; */
        if (message.patientData)
            PatientData.internalBinaryWrite(message.patientData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.InclusionScreenerData inclusion_data = 2; */
        if (message.inclusionData)
            InclusionScreenerData.internalBinaryWrite(message.inclusionData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ExclusionScreenerData exclusion_data = 3; */
        if (message.exclusionData)
            ExclusionScreenerData.internalBinaryWrite(message.exclusionData, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool is_complete = 4; */
        if (message.isComplete !== false)
            writer.tag(4, WireType.Varint).bool(message.isComplete);
        /* enrollment.bff.EnrollmentProfile profile = 5; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ConsentData consent_data = 6; */
        if (message.consentData)
            ConsentData.internalBinaryWrite(message.consentData, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Step current_step = 7; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.EnrollPatientRequest
 */
export const EnrollPatientRequest = new EnrollPatientRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnrollPatientResponse$Type extends MessageType<EnrollPatientResponse> {
    constructor() {
        super("enrollment.bff.EnrollPatientResponse", []);
    }
    create(value?: PartialMessage<EnrollPatientResponse>): EnrollPatientResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnrollPatientResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnrollPatientResponse): EnrollPatientResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: EnrollPatientResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.EnrollPatientResponse
 */
export const EnrollPatientResponse = new EnrollPatientResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckEligibilityRequest$Type extends MessageType<CheckEligibilityRequest> {
    constructor() {
        super("enrollment.bff.CheckEligibilityRequest", [
            { no: 1, name: "name", kind: "message", T: () => Name },
            { no: 2, name: "date_of_birth", kind: "message", T: () => Date },
            { no: 3, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 4, name: "enrollment_verification_key", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[A-Za-z0-9_\\s\u2014-]+$", ignoreEmpty: true } } } },
            { no: 5, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<CheckEligibilityRequest>): CheckEligibilityRequest {
        const message = { enrollmentVerificationKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckEligibilityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckEligibilityRequest): CheckEligibilityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.Name name */ 1:
                    message.name = Name.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* enrollment.bff.Date date_of_birth */ 2:
                    message.dateOfBirth = Date.internalBinaryRead(reader, reader.uint32(), options, message.dateOfBirth);
                    break;
                case /* enrollment.bff.EnrollmentProfile profile */ 3:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* string enrollment_verification_key */ 4:
                    message.enrollmentVerificationKey = reader.string();
                    break;
                case /* enrollment.bff.Step current_step */ 5:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckEligibilityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.Name name = 1; */
        if (message.name)
            Name.internalBinaryWrite(message.name, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Date date_of_birth = 2; */
        if (message.dateOfBirth)
            Date.internalBinaryWrite(message.dateOfBirth, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.EnrollmentProfile profile = 3; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string enrollment_verification_key = 4; */
        if (message.enrollmentVerificationKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.enrollmentVerificationKey);
        /* enrollment.bff.Step current_step = 5; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CheckEligibilityRequest
 */
export const CheckEligibilityRequest = new CheckEligibilityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckEligibilityResponse$Type extends MessageType<CheckEligibilityResponse> {
    constructor() {
        super("enrollment.bff.CheckEligibilityResponse", [
            { no: 1, name: "is_eligible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "sponsor", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckEligibilityResponse>): CheckEligibilityResponse {
        const message = { isEligible: false, sponsor: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckEligibilityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckEligibilityResponse): CheckEligibilityResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_eligible */ 1:
                    message.isEligible = reader.bool();
                    break;
                case /* string sponsor = 2 [deprecated = true];*/ 2:
                    message.sponsor = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckEligibilityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_eligible = 1; */
        if (message.isEligible !== false)
            writer.tag(1, WireType.Varint).bool(message.isEligible);
        /* string sponsor = 2 [deprecated = true]; */
        if (message.sponsor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sponsor);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CheckEligibilityResponse
 */
export const CheckEligibilityResponse = new CheckEligibilityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Image$Type extends MessageType<Image> {
    constructor() {
        super("enrollment.bff.Image", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "alt_text", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<Image>): Image {
        const message = { uri: "", altText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Image>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Image): Image {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string alt_text */ 2:
                    message.altText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Image, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string alt_text = 2; */
        if (message.altText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.altText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Image
 */
export const Image = new Image$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileStyleConfig$Type extends MessageType<ProfileStyleConfig> {
    constructor() {
        super("enrollment.bff.ProfileStyleConfig", [
            { no: 1, name: "logo_1", kind: "message", T: () => Image },
            { no: 3, name: "logo_2", kind: "message", T: () => Image },
            { no: 2, name: "disclaimer_html", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 4, name: "product_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "favicon", kind: "message", T: () => Image },
            { no: 6, name: "product_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 7, name: "primary_color_main", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 9, name: "app_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "web_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "android_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "ios_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProfileStyleConfig>): ProfileStyleConfig {
        const message = { disclaimerHtml: "", productHandoffLink: "", productName: "", primaryColorMain: "", pageTitle: "", appHandoffLink: "", webHandoffLink: "", androidHandoffLink: "", iosHandoffLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfileStyleConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfileStyleConfig): ProfileStyleConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.Image logo_1 */ 1:
                    message.logo1 = Image.internalBinaryRead(reader, reader.uint32(), options, message.logo1);
                    break;
                case /* enrollment.bff.Image logo_2 */ 3:
                    message.logo2 = Image.internalBinaryRead(reader, reader.uint32(), options, message.logo2);
                    break;
                case /* string disclaimer_html */ 2:
                    message.disclaimerHtml = reader.string();
                    break;
                case /* string product_handoff_link = 4 [deprecated = true];*/ 4:
                    message.productHandoffLink = reader.string();
                    break;
                case /* enrollment.bff.Image favicon */ 5:
                    message.favicon = Image.internalBinaryRead(reader, reader.uint32(), options, message.favicon);
                    break;
                case /* string product_name */ 6:
                    message.productName = reader.string();
                    break;
                case /* string primary_color_main */ 7:
                    message.primaryColorMain = reader.string();
                    break;
                case /* string page_title */ 8:
                    message.pageTitle = reader.string();
                    break;
                case /* string app_handoff_link = 9 [deprecated = true];*/ 9:
                    message.appHandoffLink = reader.string();
                    break;
                case /* string web_handoff_link = 10 [deprecated = true];*/ 10:
                    message.webHandoffLink = reader.string();
                    break;
                case /* string android_handoff_link = 11 [deprecated = true];*/ 11:
                    message.androidHandoffLink = reader.string();
                    break;
                case /* string ios_handoff_link = 12 [deprecated = true];*/ 12:
                    message.iosHandoffLink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfileStyleConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.Image logo_1 = 1; */
        if (message.logo1)
            Image.internalBinaryWrite(message.logo1, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Image logo_2 = 3; */
        if (message.logo2)
            Image.internalBinaryWrite(message.logo2, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string disclaimer_html = 2; */
        if (message.disclaimerHtml !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.disclaimerHtml);
        /* string product_handoff_link = 4 [deprecated = true]; */
        if (message.productHandoffLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.productHandoffLink);
        /* enrollment.bff.Image favicon = 5; */
        if (message.favicon)
            Image.internalBinaryWrite(message.favicon, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string product_name = 6; */
        if (message.productName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.productName);
        /* string primary_color_main = 7; */
        if (message.primaryColorMain !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.primaryColorMain);
        /* string page_title = 8; */
        if (message.pageTitle !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.pageTitle);
        /* string app_handoff_link = 9 [deprecated = true]; */
        if (message.appHandoffLink !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.appHandoffLink);
        /* string web_handoff_link = 10 [deprecated = true]; */
        if (message.webHandoffLink !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.webHandoffLink);
        /* string android_handoff_link = 11 [deprecated = true]; */
        if (message.androidHandoffLink !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.androidHandoffLink);
        /* string ios_handoff_link = 12 [deprecated = true]; */
        if (message.iosHandoffLink !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.iosHandoffLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ProfileStyleConfig
 */
export const ProfileStyleConfig = new ProfileStyleConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileDataFormConfig$Type extends MessageType<ProfileDataFormConfig> {
    constructor() {
        super("enrollment.bff.ProfileDataFormConfig", [
            { no: 1, name: "collect_gender_identity", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "constraints", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProfileDataFormConfig_FormFieldConstraint },
            { no: 4, name: "participant_data_form_header", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 5, name: "participant_data_form_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 3, name: "collect_address", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "address_collection_subform_header", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 7, name: "address_collection_subform_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 8, name: "contact_info_subform_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 9, name: "basic_info_subform_subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 10, name: "collect_phone", kind: "enum", T: () => ["enrollment.bff.ProfileDataFormConfig.ShouldCollect", ProfileDataFormConfig_ShouldCollect, "SHOULD_COLLECT_"] },
            { no: 11, name: "collect_email", kind: "enum", T: () => ["enrollment.bff.ProfileDataFormConfig.ShouldCollect", ProfileDataFormConfig_ShouldCollect, "SHOULD_COLLECT_"] },
            { no: 12, name: "collect_dob", kind: "enum", T: () => ["enrollment.bff.ProfileDataFormConfig.ShouldCollect", ProfileDataFormConfig_ShouldCollect, "SHOULD_COLLECT_"] }
        ]);
    }
    create(value?: PartialMessage<ProfileDataFormConfig>): ProfileDataFormConfig {
        const message = { collectGenderIdentity: false, constraints: [], participantDataFormHeader: "", participantDataFormSubheader: "", collectAddress: false, addressCollectionSubformHeader: "", addressCollectionSubformSubheader: "", contactInfoSubformSubheader: "", basicInfoSubformSubheader: "", collectPhone: 0, collectEmail: 0, collectDob: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfileDataFormConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfileDataFormConfig): ProfileDataFormConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool collect_gender_identity */ 1:
                    message.collectGenderIdentity = reader.bool();
                    break;
                case /* repeated enrollment.bff.ProfileDataFormConfig.FormFieldConstraint constraints */ 2:
                    message.constraints.push(ProfileDataFormConfig_FormFieldConstraint.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string participant_data_form_header */ 4:
                    message.participantDataFormHeader = reader.string();
                    break;
                case /* string participant_data_form_subheader */ 5:
                    message.participantDataFormSubheader = reader.string();
                    break;
                case /* bool collect_address */ 3:
                    message.collectAddress = reader.bool();
                    break;
                case /* string address_collection_subform_header */ 6:
                    message.addressCollectionSubformHeader = reader.string();
                    break;
                case /* string address_collection_subform_subheader */ 7:
                    message.addressCollectionSubformSubheader = reader.string();
                    break;
                case /* string contact_info_subform_subheader */ 8:
                    message.contactInfoSubformSubheader = reader.string();
                    break;
                case /* string basic_info_subform_subheader */ 9:
                    message.basicInfoSubformSubheader = reader.string();
                    break;
                case /* enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_phone */ 10:
                    message.collectPhone = reader.int32();
                    break;
                case /* enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_email */ 11:
                    message.collectEmail = reader.int32();
                    break;
                case /* enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_dob */ 12:
                    message.collectDob = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfileDataFormConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool collect_gender_identity = 1; */
        if (message.collectGenderIdentity !== false)
            writer.tag(1, WireType.Varint).bool(message.collectGenderIdentity);
        /* repeated enrollment.bff.ProfileDataFormConfig.FormFieldConstraint constraints = 2; */
        for (let i = 0; i < message.constraints.length; i++)
            ProfileDataFormConfig_FormFieldConstraint.internalBinaryWrite(message.constraints[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string participant_data_form_header = 4; */
        if (message.participantDataFormHeader !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.participantDataFormHeader);
        /* string participant_data_form_subheader = 5; */
        if (message.participantDataFormSubheader !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.participantDataFormSubheader);
        /* bool collect_address = 3; */
        if (message.collectAddress !== false)
            writer.tag(3, WireType.Varint).bool(message.collectAddress);
        /* string address_collection_subform_header = 6; */
        if (message.addressCollectionSubformHeader !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.addressCollectionSubformHeader);
        /* string address_collection_subform_subheader = 7; */
        if (message.addressCollectionSubformSubheader !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.addressCollectionSubformSubheader);
        /* string contact_info_subform_subheader = 8; */
        if (message.contactInfoSubformSubheader !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.contactInfoSubformSubheader);
        /* string basic_info_subform_subheader = 9; */
        if (message.basicInfoSubformSubheader !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.basicInfoSubformSubheader);
        /* enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_phone = 10; */
        if (message.collectPhone !== 0)
            writer.tag(10, WireType.Varint).int32(message.collectPhone);
        /* enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_email = 11; */
        if (message.collectEmail !== 0)
            writer.tag(11, WireType.Varint).int32(message.collectEmail);
        /* enrollment.bff.ProfileDataFormConfig.ShouldCollect collect_dob = 12; */
        if (message.collectDob !== 0)
            writer.tag(12, WireType.Varint).int32(message.collectDob);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ProfileDataFormConfig
 */
export const ProfileDataFormConfig = new ProfileDataFormConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProfileDataFormConfig_FormFieldConstraint$Type extends MessageType<ProfileDataFormConfig_FormFieldConstraint> {
    constructor() {
        super("enrollment.bff.ProfileDataFormConfig.FormFieldConstraint", [
            { no: 1, name: "field", kind: "enum", T: () => ["enrollment.bff.ProfileDataFormConfig.Field", ProfileDataFormConfig_Field] },
            { no: 2, name: "operator", kind: "enum", T: () => ["enrollment.bff.ProfileDataFormConfig.ConstraintOperator", ProfileDataFormConfig_ConstraintOperator] },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "error_message", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<ProfileDataFormConfig_FormFieldConstraint>): ProfileDataFormConfig_FormFieldConstraint {
        const message = { field: 0, operator: 0, value: "", errorMessage: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProfileDataFormConfig_FormFieldConstraint>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProfileDataFormConfig_FormFieldConstraint): ProfileDataFormConfig_FormFieldConstraint {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.ProfileDataFormConfig.Field field */ 1:
                    message.field = reader.int32();
                    break;
                case /* enrollment.bff.ProfileDataFormConfig.ConstraintOperator operator */ 2:
                    message.operator = reader.int32();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* string error_message */ 4:
                    message.errorMessage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProfileDataFormConfig_FormFieldConstraint, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.ProfileDataFormConfig.Field field = 1; */
        if (message.field !== 0)
            writer.tag(1, WireType.Varint).int32(message.field);
        /* enrollment.bff.ProfileDataFormConfig.ConstraintOperator operator = 2; */
        if (message.operator !== 0)
            writer.tag(2, WireType.Varint).int32(message.operator);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* string error_message = 4; */
        if (message.errorMessage !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.errorMessage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ProfileDataFormConfig.FormFieldConstraint
 */
export const ProfileDataFormConfig_FormFieldConstraint = new ProfileDataFormConfig_FormFieldConstraint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooleanQuestion$Type extends MessageType<BooleanQuestion> {
    constructor() {
        super("enrollment.bff.BooleanQuestion", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<BooleanQuestion>): BooleanQuestion {
        const message = { id: "", title: "", description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BooleanQuestion>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BooleanQuestion): BooleanQuestion {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BooleanQuestion, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.BooleanQuestion
 */
export const BooleanQuestion = new BooleanQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooleanQuestionAnswer$Type extends MessageType<BooleanQuestionAnswer> {
    constructor() {
        super("enrollment.bff.BooleanQuestionAnswer", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "answer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BooleanQuestionAnswer>): BooleanQuestionAnswer {
        const message = { id: "", answer: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BooleanQuestionAnswer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BooleanQuestionAnswer): BooleanQuestionAnswer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool answer */ 2:
                    message.answer = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BooleanQuestionAnswer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool answer = 2; */
        if (message.answer !== false)
            writer.tag(2, WireType.Varint).bool(message.answer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.BooleanQuestionAnswer
 */
export const BooleanQuestionAnswer = new BooleanQuestionAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooleanQuestionSection$Type extends MessageType<BooleanQuestionSection> {
    constructor() {
        super("enrollment.bff.BooleanQuestionSection", [
            { no: 1, name: "header", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 2, name: "subheader", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } },
            { no: 3, name: "questions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BooleanQuestion }
        ]);
    }
    create(value?: PartialMessage<BooleanQuestionSection>): BooleanQuestionSection {
        const message = { header: "", subheader: "", questions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BooleanQuestionSection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BooleanQuestionSection): BooleanQuestionSection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string header */ 1:
                    message.header = reader.string();
                    break;
                case /* string subheader */ 2:
                    message.subheader = reader.string();
                    break;
                case /* repeated enrollment.bff.BooleanQuestion questions */ 3:
                    message.questions.push(BooleanQuestion.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BooleanQuestionSection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string header = 1; */
        if (message.header !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.header);
        /* string subheader = 2; */
        if (message.subheader !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subheader);
        /* repeated enrollment.bff.BooleanQuestion questions = 3; */
        for (let i = 0; i < message.questions.length; i++)
            BooleanQuestion.internalBinaryWrite(message.questions[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.BooleanQuestionSection
 */
export const BooleanQuestionSection = new BooleanQuestionSection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InclusionScreenerConfig$Type extends MessageType<InclusionScreenerConfig> {
    constructor() {
        super("enrollment.bff.InclusionScreenerConfig", [
            { no: 1, name: "question_sections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BooleanQuestionSection }
        ]);
    }
    create(value?: PartialMessage<InclusionScreenerConfig>): InclusionScreenerConfig {
        const message = { questionSections: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InclusionScreenerConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InclusionScreenerConfig): InclusionScreenerConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.BooleanQuestionSection question_sections */ 1:
                    message.questionSections.push(BooleanQuestionSection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InclusionScreenerConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.BooleanQuestionSection question_sections = 1; */
        for (let i = 0; i < message.questionSections.length; i++)
            BooleanQuestionSection.internalBinaryWrite(message.questionSections[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.InclusionScreenerConfig
 */
export const InclusionScreenerConfig = new InclusionScreenerConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExclusionScreenerConfig$Type extends MessageType<ExclusionScreenerConfig> {
    constructor() {
        super("enrollment.bff.ExclusionScreenerConfig", [
            { no: 1, name: "question_sections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BooleanQuestionSection }
        ]);
    }
    create(value?: PartialMessage<ExclusionScreenerConfig>): ExclusionScreenerConfig {
        const message = { questionSections: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ExclusionScreenerConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExclusionScreenerConfig): ExclusionScreenerConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.BooleanQuestionSection question_sections */ 1:
                    message.questionSections.push(BooleanQuestionSection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExclusionScreenerConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.BooleanQuestionSection question_sections = 1; */
        for (let i = 0; i < message.questionSections.length; i++)
            BooleanQuestionSection.internalBinaryWrite(message.questionSections[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ExclusionScreenerConfig
 */
export const ExclusionScreenerConfig = new ExclusionScreenerConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnrollmentProfile$Type extends MessageType<EnrollmentProfile> {
    constructor() {
        super("enrollment.bff.EnrollmentProfile", [
            { no: 1, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_preview", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<EnrollmentProfile>): EnrollmentProfile {
        const message = { profile: "", domain: "", language: "", isPreview: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EnrollmentProfile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnrollmentProfile): EnrollmentProfile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string profile */ 1:
                    message.profile = reader.string();
                    break;
                case /* string domain */ 2:
                    message.domain = reader.string();
                    break;
                case /* string language */ 3:
                    message.language = reader.string();
                    break;
                case /* bool is_preview = 4 [deprecated = true];*/ 4:
                    message.isPreview = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnrollmentProfile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string profile = 1; */
        if (message.profile !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.profile);
        /* string domain = 2; */
        if (message.domain !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.domain);
        /* string language = 3; */
        if (message.language !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.language);
        /* bool is_preview = 4 [deprecated = true]; */
        if (message.isPreview !== false)
            writer.tag(4, WireType.Varint).bool(message.isPreview);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.EnrollmentProfile
 */
export const EnrollmentProfile = new EnrollmentProfile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfileConfigRequest$Type extends MessageType<GetProfileConfigRequest> {
    constructor() {
        super("enrollment.bff.GetProfileConfigRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile }
        ]);
    }
    create(value?: PartialMessage<GetProfileConfigRequest>): GetProfileConfigRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfileConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfileConfigRequest): GetProfileConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfileConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetProfileConfigRequest
 */
export const GetProfileConfigRequest = new GetProfileConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EligibilityConfig$Type extends MessageType<EligibilityConfig> {
    constructor() {
        super("enrollment.bff.EligibilityConfig", [
            { no: 1, name: "elig_key_label", kind: "message", T: () => FormFieldLabelConfig },
            { no: 2, name: "elig_check_fields", kind: "enum", opt: true, T: () => ["enrollment.bff.EligibilityConfig.EligibilityCheckFields", EligibilityConfig_EligibilityCheckFields] }
        ]);
    }
    create(value?: PartialMessage<EligibilityConfig>): EligibilityConfig {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EligibilityConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EligibilityConfig): EligibilityConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.FormFieldLabelConfig elig_key_label */ 1:
                    message.eligKeyLabel = FormFieldLabelConfig.internalBinaryRead(reader, reader.uint32(), options, message.eligKeyLabel);
                    break;
                case /* optional enrollment.bff.EligibilityConfig.EligibilityCheckFields elig_check_fields */ 2:
                    message.eligCheckFields = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EligibilityConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.FormFieldLabelConfig elig_key_label = 1; */
        if (message.eligKeyLabel)
            FormFieldLabelConfig.internalBinaryWrite(message.eligKeyLabel, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* optional enrollment.bff.EligibilityConfig.EligibilityCheckFields elig_check_fields = 2; */
        if (message.eligCheckFields !== undefined)
            writer.tag(2, WireType.Varint).int32(message.eligCheckFields);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.EligibilityConfig
 */
export const EligibilityConfig = new EligibilityConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfileConfigResponse$Type extends MessageType<GetProfileConfigResponse> {
    constructor() {
        super("enrollment.bff.GetProfileConfigResponse", [
            { no: 1, name: "style_config", kind: "message", T: () => ProfileStyleConfig },
            { no: 2, name: "data_form_config", kind: "message", T: () => ProfileDataFormConfig },
            { no: 3, name: "inclusion_screener_config", kind: "message", T: () => InclusionScreenerConfig },
            { no: 4, name: "exclusion_screener_config", kind: "message", T: () => ExclusionScreenerConfig },
            { no: 5, name: "auth_config", kind: "message", T: () => AuthConfig },
            { no: 6, name: "policy_config", kind: "message", T: () => PolicyConfig },
            { no: 7, name: "eligibility_config", kind: "message", T: () => EligibilityConfig },
            { no: 8, name: "config_version", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "patient_sponsor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "associated_resource", kind: "message", T: () => ResourceLink },
            { no: 11, name: "consent", kind: "message", T: () => ConsentSpecification },
            { no: 12, name: "enable_on", kind: "message", T: () => Timestamp },
            { no: 13, name: "fhir_store", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "agreement_consents_config", kind: "message", T: () => AgreementConsentsConfig },
            { no: 15, name: "vcms_survey_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "not_in_oneverily", kind: "scalar", opt: true, T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "managing_org_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "user_type", kind: "enum", T: () => ["enrollment.bff.UserType", UserType] },
            { no: 19, name: "flow_config", kind: "message", T: () => FlowConfig },
            { no: 20, name: "is_preview", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "prequalifications", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Prequalification }
        ]);
    }
    create(value?: PartialMessage<GetProfileConfigResponse>): GetProfileConfigResponse {
        const message = { configVersion: 0, patientSponsor: "", fhirStore: "", vcmsSurveyId: "", managingOrgId: "", userType: 0, isPreview: false, prequalifications: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfileConfigResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfileConfigResponse): GetProfileConfigResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.ProfileStyleConfig style_config */ 1:
                    message.styleConfig = ProfileStyleConfig.internalBinaryRead(reader, reader.uint32(), options, message.styleConfig);
                    break;
                case /* enrollment.bff.ProfileDataFormConfig data_form_config = 2 [deprecated = true];*/ 2:
                    message.dataFormConfig = ProfileDataFormConfig.internalBinaryRead(reader, reader.uint32(), options, message.dataFormConfig);
                    break;
                case /* enrollment.bff.InclusionScreenerConfig inclusion_screener_config = 3 [deprecated = true];*/ 3:
                    message.inclusionScreenerConfig = InclusionScreenerConfig.internalBinaryRead(reader, reader.uint32(), options, message.inclusionScreenerConfig);
                    break;
                case /* enrollment.bff.ExclusionScreenerConfig exclusion_screener_config = 4 [deprecated = true];*/ 4:
                    message.exclusionScreenerConfig = ExclusionScreenerConfig.internalBinaryRead(reader, reader.uint32(), options, message.exclusionScreenerConfig);
                    break;
                case /* enrollment.bff.AuthConfig auth_config = 5 [deprecated = true];*/ 5:
                    message.authConfig = AuthConfig.internalBinaryRead(reader, reader.uint32(), options, message.authConfig);
                    break;
                case /* enrollment.bff.PolicyConfig policy_config = 6 [deprecated = true];*/ 6:
                    message.policyConfig = PolicyConfig.internalBinaryRead(reader, reader.uint32(), options, message.policyConfig);
                    break;
                case /* enrollment.bff.EligibilityConfig eligibility_config = 7 [deprecated = true];*/ 7:
                    message.eligibilityConfig = EligibilityConfig.internalBinaryRead(reader, reader.uint32(), options, message.eligibilityConfig);
                    break;
                case /* uint32 config_version */ 8:
                    message.configVersion = reader.uint32();
                    break;
                case /* string patient_sponsor = 9 [deprecated = true];*/ 9:
                    message.patientSponsor = reader.string();
                    break;
                case /* enrollment.bff.ResourceLink associated_resource */ 10:
                    message.associatedResource = ResourceLink.internalBinaryRead(reader, reader.uint32(), options, message.associatedResource);
                    break;
                case /* enrollment.bff.ConsentSpecification consent */ 11:
                    message.consent = ConsentSpecification.internalBinaryRead(reader, reader.uint32(), options, message.consent);
                    break;
                case /* google.protobuf.Timestamp enable_on */ 12:
                    message.enableOn = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.enableOn);
                    break;
                case /* string fhir_store = 13 [deprecated = true];*/ 13:
                    message.fhirStore = reader.string();
                    break;
                case /* enrollment.bff.AgreementConsentsConfig agreement_consents_config = 14 [deprecated = true];*/ 14:
                    message.agreementConsentsConfig = AgreementConsentsConfig.internalBinaryRead(reader, reader.uint32(), options, message.agreementConsentsConfig);
                    break;
                case /* string vcms_survey_id */ 15:
                    message.vcmsSurveyId = reader.string();
                    break;
                case /* optional bool not_in_oneverily */ 16:
                    message.notInOneverily = reader.bool();
                    break;
                case /* string managing_org_id */ 17:
                    message.managingOrgId = reader.string();
                    break;
                case /* enrollment.bff.UserType user_type */ 18:
                    message.userType = reader.int32();
                    break;
                case /* enrollment.bff.FlowConfig flow_config */ 19:
                    message.flowConfig = FlowConfig.internalBinaryRead(reader, reader.uint32(), options, message.flowConfig);
                    break;
                case /* bool is_preview */ 20:
                    message.isPreview = reader.bool();
                    break;
                case /* repeated enrollment.bff.Prequalification prequalifications */ 21:
                    message.prequalifications.push(Prequalification.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfileConfigResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.ProfileStyleConfig style_config = 1; */
        if (message.styleConfig)
            ProfileStyleConfig.internalBinaryWrite(message.styleConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ProfileDataFormConfig data_form_config = 2 [deprecated = true]; */
        if (message.dataFormConfig)
            ProfileDataFormConfig.internalBinaryWrite(message.dataFormConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.InclusionScreenerConfig inclusion_screener_config = 3 [deprecated = true]; */
        if (message.inclusionScreenerConfig)
            InclusionScreenerConfig.internalBinaryWrite(message.inclusionScreenerConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ExclusionScreenerConfig exclusion_screener_config = 4 [deprecated = true]; */
        if (message.exclusionScreenerConfig)
            ExclusionScreenerConfig.internalBinaryWrite(message.exclusionScreenerConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.AuthConfig auth_config = 5 [deprecated = true]; */
        if (message.authConfig)
            AuthConfig.internalBinaryWrite(message.authConfig, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.PolicyConfig policy_config = 6 [deprecated = true]; */
        if (message.policyConfig)
            PolicyConfig.internalBinaryWrite(message.policyConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.EligibilityConfig eligibility_config = 7 [deprecated = true]; */
        if (message.eligibilityConfig)
            EligibilityConfig.internalBinaryWrite(message.eligibilityConfig, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* uint32 config_version = 8; */
        if (message.configVersion !== 0)
            writer.tag(8, WireType.Varint).uint32(message.configVersion);
        /* string patient_sponsor = 9 [deprecated = true]; */
        if (message.patientSponsor !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.patientSponsor);
        /* enrollment.bff.ResourceLink associated_resource = 10; */
        if (message.associatedResource)
            ResourceLink.internalBinaryWrite(message.associatedResource, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ConsentSpecification consent = 11; */
        if (message.consent)
            ConsentSpecification.internalBinaryWrite(message.consent, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp enable_on = 12; */
        if (message.enableOn)
            Timestamp.internalBinaryWrite(message.enableOn, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string fhir_store = 13 [deprecated = true]; */
        if (message.fhirStore !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.fhirStore);
        /* enrollment.bff.AgreementConsentsConfig agreement_consents_config = 14 [deprecated = true]; */
        if (message.agreementConsentsConfig)
            AgreementConsentsConfig.internalBinaryWrite(message.agreementConsentsConfig, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string vcms_survey_id = 15; */
        if (message.vcmsSurveyId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.vcmsSurveyId);
        /* optional bool not_in_oneverily = 16; */
        if (message.notInOneverily !== undefined)
            writer.tag(16, WireType.Varint).bool(message.notInOneverily);
        /* string managing_org_id = 17; */
        if (message.managingOrgId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.managingOrgId);
        /* enrollment.bff.UserType user_type = 18; */
        if (message.userType !== 0)
            writer.tag(18, WireType.Varint).int32(message.userType);
        /* enrollment.bff.FlowConfig flow_config = 19; */
        if (message.flowConfig)
            FlowConfig.internalBinaryWrite(message.flowConfig, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* bool is_preview = 20; */
        if (message.isPreview !== false)
            writer.tag(20, WireType.Varint).bool(message.isPreview);
        /* repeated enrollment.bff.Prequalification prequalifications = 21; */
        for (let i = 0; i < message.prequalifications.length; i++)
            Prequalification.internalBinaryWrite(message.prequalifications[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetProfileConfigResponse
 */
export const GetProfileConfigResponse = new GetProfileConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Prequalification$Type extends MessageType<Prequalification> {
    constructor() {
        super("enrollment.bff.Prequalification", [
            { no: 1, name: "research_subject", kind: "message", oneof: "prequalificationType", T: () => Prequalification_ResearchSubjectQualification }
        ]);
    }
    create(value?: PartialMessage<Prequalification>): Prequalification {
        const message = { prequalificationType: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Prequalification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Prequalification): Prequalification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.Prequalification.ResearchSubjectQualification research_subject */ 1:
                    message.prequalificationType = {
                        oneofKind: "researchSubject",
                        researchSubject: Prequalification_ResearchSubjectQualification.internalBinaryRead(reader, reader.uint32(), options, (message.prequalificationType as any).researchSubject)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Prequalification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.Prequalification.ResearchSubjectQualification research_subject = 1; */
        if (message.prequalificationType.oneofKind === "researchSubject")
            Prequalification_ResearchSubjectQualification.internalBinaryWrite(message.prequalificationType.researchSubject, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Prequalification
 */
export const Prequalification = new Prequalification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Prequalification_ResearchSubjectQualification$Type extends MessageType<Prequalification_ResearchSubjectQualification> {
    constructor() {
        super("enrollment.bff.Prequalification.ResearchSubjectQualification", [
            { no: 1, name: "required_status", kind: "enum", T: () => ["google.fhir.r4.core.ResearchSubjectStatusCode.Value", ResearchSubjectStatusCode_Value] }
        ]);
    }
    create(value?: PartialMessage<Prequalification_ResearchSubjectQualification>): Prequalification_ResearchSubjectQualification {
        const message = { requiredStatus: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Prequalification_ResearchSubjectQualification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Prequalification_ResearchSubjectQualification): Prequalification_ResearchSubjectQualification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.ResearchSubjectStatusCode.Value required_status */ 1:
                    message.requiredStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Prequalification_ResearchSubjectQualification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.ResearchSubjectStatusCode.Value required_status = 1; */
        if (message.requiredStatus !== 0)
            writer.tag(1, WireType.Varint).int32(message.requiredStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Prequalification.ResearchSubjectQualification
 */
export const Prequalification_ResearchSubjectQualification = new Prequalification_ResearchSubjectQualification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FlowConfig$Type extends MessageType<FlowConfig> {
    constructor() {
        super("enrollment.bff.FlowConfig", [
            { no: 1, name: "steps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Step }
        ]);
    }
    create(value?: PartialMessage<FlowConfig>): FlowConfig {
        const message = { steps: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FlowConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FlowConfig): FlowConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.Step steps */ 1:
                    message.steps.push(Step.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FlowConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.Step steps = 1; */
        for (let i = 0; i < message.steps.length; i++)
            Step.internalBinaryWrite(message.steps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.FlowConfig
 */
export const FlowConfig = new FlowConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Step$Type extends MessageType<Step> {
    constructor() {
        super("enrollment.bff.Step", [
            { no: 1, name: "account_creation", kind: "message", oneof: "step", T: () => AccountCreation },
            { no: 2, name: "participant_data", kind: "message", oneof: "step", T: () => ParticipantData },
            { no: 3, name: "handoff", kind: "message", oneof: "step", T: () => Handoff },
            { no: 4, name: "redirect_user", kind: "message", oneof: "step", T: () => RedirectUserStep }
        ]);
    }
    create(value?: PartialMessage<Step>): Step {
        const message = { step: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Step>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Step): Step {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.AccountCreation account_creation */ 1:
                    message.step = {
                        oneofKind: "accountCreation",
                        accountCreation: AccountCreation.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).accountCreation)
                    };
                    break;
                case /* enrollment.bff.ParticipantData participant_data */ 2:
                    message.step = {
                        oneofKind: "participantData",
                        participantData: ParticipantData.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).participantData)
                    };
                    break;
                case /* enrollment.bff.Handoff handoff */ 3:
                    message.step = {
                        oneofKind: "handoff",
                        handoff: Handoff.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).handoff)
                    };
                    break;
                case /* enrollment.bff.RedirectUserStep redirect_user */ 4:
                    message.step = {
                        oneofKind: "redirectUser",
                        redirectUser: RedirectUserStep.internalBinaryRead(reader, reader.uint32(), options, (message.step as any).redirectUser)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Step, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.AccountCreation account_creation = 1; */
        if (message.step.oneofKind === "accountCreation")
            AccountCreation.internalBinaryWrite(message.step.accountCreation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ParticipantData participant_data = 2; */
        if (message.step.oneofKind === "participantData")
            ParticipantData.internalBinaryWrite(message.step.participantData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Handoff handoff = 3; */
        if (message.step.oneofKind === "handoff")
            Handoff.internalBinaryWrite(message.step.handoff, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.RedirectUserStep redirect_user = 4; */
        if (message.step.oneofKind === "redirectUser")
            RedirectUserStep.internalBinaryWrite(message.step.redirectUser, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Step
 */
export const Step = new Step$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountCreation$Type extends MessageType<AccountCreation> {
    constructor() {
        super("enrollment.bff.AccountCreation", []);
    }
    create(value?: PartialMessage<AccountCreation>): AccountCreation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountCreation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountCreation): AccountCreation {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AccountCreation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AccountCreation
 */
export const AccountCreation = new AccountCreation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantData$Type extends MessageType<ParticipantData> {
    constructor() {
        super("enrollment.bff.ParticipantData", [
            { no: 1, name: "data_form_config", kind: "message", T: () => ProfileDataFormConfig },
            { no: 2, name: "eligibility_config", kind: "message", T: () => EligibilityConfig },
            { no: 3, name: "agreement_consents_config", kind: "message", T: () => AgreementConsentsConfig }
        ]);
    }
    create(value?: PartialMessage<ParticipantData>): ParticipantData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ParticipantData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipantData): ParticipantData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.ProfileDataFormConfig data_form_config */ 1:
                    message.dataFormConfig = ProfileDataFormConfig.internalBinaryRead(reader, reader.uint32(), options, message.dataFormConfig);
                    break;
                case /* enrollment.bff.EligibilityConfig eligibility_config */ 2:
                    message.eligibilityConfig = EligibilityConfig.internalBinaryRead(reader, reader.uint32(), options, message.eligibilityConfig);
                    break;
                case /* enrollment.bff.AgreementConsentsConfig agreement_consents_config */ 3:
                    message.agreementConsentsConfig = AgreementConsentsConfig.internalBinaryRead(reader, reader.uint32(), options, message.agreementConsentsConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipantData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.ProfileDataFormConfig data_form_config = 1; */
        if (message.dataFormConfig)
            ProfileDataFormConfig.internalBinaryWrite(message.dataFormConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.EligibilityConfig eligibility_config = 2; */
        if (message.eligibilityConfig)
            EligibilityConfig.internalBinaryWrite(message.eligibilityConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.AgreementConsentsConfig agreement_consents_config = 3; */
        if (message.agreementConsentsConfig)
            AgreementConsentsConfig.internalBinaryWrite(message.agreementConsentsConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ParticipantData
 */
export const ParticipantData = new ParticipantData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Handoff$Type extends MessageType<Handoff> {
    constructor() {
        super("enrollment.bff.Handoff", [
            { no: 1, name: "app_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "web_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "android_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ios_handoff_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Handoff>): Handoff {
        const message = { appHandoffLink: "", webHandoffLink: "", androidHandoffLink: "", iosHandoffLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Handoff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Handoff): Handoff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string app_handoff_link */ 1:
                    message.appHandoffLink = reader.string();
                    break;
                case /* string web_handoff_link */ 2:
                    message.webHandoffLink = reader.string();
                    break;
                case /* string android_handoff_link */ 3:
                    message.androidHandoffLink = reader.string();
                    break;
                case /* string ios_handoff_link */ 4:
                    message.iosHandoffLink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Handoff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string app_handoff_link = 1; */
        if (message.appHandoffLink !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.appHandoffLink);
        /* string web_handoff_link = 2; */
        if (message.webHandoffLink !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.webHandoffLink);
        /* string android_handoff_link = 3; */
        if (message.androidHandoffLink !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.androidHandoffLink);
        /* string ios_handoff_link = 4; */
        if (message.iosHandoffLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iosHandoffLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.Handoff
 */
export const Handoff = new Handoff$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedirectUserStep$Type extends MessageType<RedirectUserStep> {
    constructor() {
        super("enrollment.bff.RedirectUserStep", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RedirectUserStep>): RedirectUserStep {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RedirectUserStep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedirectUserStep): RedirectUserStep {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedirectUserStep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RedirectUserStep
 */
export const RedirectUserStep = new RedirectUserStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConsentSpecification$Type extends MessageType<ConsentSpecification> {
    constructor() {
        super("enrollment.bff.ConsentSpecification", [
            { no: 1, name: "consent_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "consent_revision", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ConsentSpecification>): ConsentSpecification {
        const message = { consentId: "", consentRevision: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ConsentSpecification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConsentSpecification): ConsentSpecification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_id */ 1:
                    message.consentId = reader.string();
                    break;
                case /* int32 consent_revision */ 2:
                    message.consentRevision = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConsentSpecification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_id = 1; */
        if (message.consentId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentId);
        /* int32 consent_revision = 2; */
        if (message.consentRevision !== 0)
            writer.tag(2, WireType.Varint).int32(message.consentRevision);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ConsentSpecification
 */
export const ConsentSpecification = new ConsentSpecification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthConfig$Type extends MessageType<AuthConfig> {
    constructor() {
        super("enrollment.bff.AuthConfig", [
            { no: 1, name: "connection", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_idp", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "login_options", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "require_email_verification", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AuthConfig>): AuthConfig {
        const message = { connection: "", isIdp: false, loginOptions: {}, requireEmailVerification: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthConfig): AuthConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string connection */ 1:
                    message.connection = reader.string();
                    break;
                case /* bool is_idp = 2 [deprecated = true];*/ 2:
                    message.isIdp = reader.bool();
                    break;
                case /* map<string, string> login_options */ 3:
                    this.binaryReadMap3(message.loginOptions, reader, options);
                    break;
                case /* bool require_email_verification */ 4:
                    message.requireEmailVerification = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: AuthConfig["loginOptions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AuthConfig["loginOptions"] | undefined, val: AuthConfig["loginOptions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field enrollment.bff.AuthConfig.login_options");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: AuthConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string connection = 1; */
        if (message.connection !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.connection);
        /* bool is_idp = 2 [deprecated = true]; */
        if (message.isIdp !== false)
            writer.tag(2, WireType.Varint).bool(message.isIdp);
        /* map<string, string> login_options = 3; */
        for (let k of Object.keys(message.loginOptions))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.loginOptions[k]).join();
        /* bool require_email_verification = 4; */
        if (message.requireEmailVerification !== false)
            writer.tag(4, WireType.Varint).bool(message.requireEmailVerification);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AuthConfig
 */
export const AuthConfig = new AuthConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyConfig$Type extends MessageType<PolicyConfig> {
    constructor() {
        super("enrollment.bff.PolicyConfig", [
            { no: 1, name: "policy_sets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyConfig_PolicySet }
        ]);
    }
    create(value?: PartialMessage<PolicyConfig>): PolicyConfig {
        const message = { policySets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PolicyConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicyConfig): PolicyConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.PolicyConfig.PolicySet policy_sets */ 1:
                    message.policySets.push(PolicyConfig_PolicySet.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicyConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.PolicyConfig.PolicySet policy_sets = 1; */
        for (let i = 0; i < message.policySets.length; i++)
            PolicyConfig_PolicySet.internalBinaryWrite(message.policySets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message enrollment.bff.PolicyConfig
 */
export const PolicyConfig = new PolicyConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyConfig_Policy$Type extends MessageType<PolicyConfig_Policy> {
    constructor() {
        super("enrollment.bff.PolicyConfig.Policy", [
            { no: 1, name: "policy_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<PolicyConfig_Policy>): PolicyConfig_Policy {
        const message = { policyId: "", contentUrl: "", version: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PolicyConfig_Policy>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicyConfig_Policy): PolicyConfig_Policy {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string policy_id */ 1:
                    message.policyId = reader.string();
                    break;
                case /* string content_url */ 2:
                    message.contentUrl = reader.string();
                    break;
                case /* string version */ 3:
                    message.version = reader.string();
                    break;
                case /* string display_name */ 4:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicyConfig_Policy, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string policy_id = 1; */
        if (message.policyId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.policyId);
        /* string content_url = 2; */
        if (message.contentUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contentUrl);
        /* string version = 3; */
        if (message.version !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.version);
        /* string display_name = 4; */
        if (message.displayName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.PolicyConfig.Policy
 */
export const PolicyConfig_Policy = new PolicyConfig_Policy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PolicyConfig_PolicySet$Type extends MessageType<PolicyConfig_PolicySet> {
    constructor() {
        super("enrollment.bff.PolicyConfig.PolicySet", [
            { no: 1, name: "policies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PolicyConfig_Policy }
        ]);
    }
    create(value?: PartialMessage<PolicyConfig_PolicySet>): PolicyConfig_PolicySet {
        const message = { policies: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PolicyConfig_PolicySet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PolicyConfig_PolicySet): PolicyConfig_PolicySet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.PolicyConfig.Policy policies */ 1:
                    message.policies.push(PolicyConfig_Policy.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PolicyConfig_PolicySet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.PolicyConfig.Policy policies = 1; */
        for (let i = 0; i < message.policies.length; i++)
            PolicyConfig_Policy.internalBinaryWrite(message.policies[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.PolicyConfig.PolicySet
 */
export const PolicyConfig_PolicySet = new PolicyConfig_PolicySet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentsConfig$Type extends MessageType<AgreementConsentsConfig> {
    constructor() {
        super("enrollment.bff.AgreementConsentsConfig", [
            { no: 1, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentsConfig_AgreementConsentsGroup }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentsConfig>): AgreementConsentsConfig {
        const message = { groups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentsConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentsConfig): AgreementConsentsConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.AgreementConsentsConfig.AgreementConsentsGroup groups */ 1:
                    message.groups.push(AgreementConsentsConfig_AgreementConsentsGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentsConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.AgreementConsentsConfig.AgreementConsentsGroup groups = 1; */
        for (let i = 0; i < message.groups.length; i++)
            AgreementConsentsConfig_AgreementConsentsGroup.internalBinaryWrite(message.groups[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentsConfig
 */
export const AgreementConsentsConfig = new AgreementConsentsConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentsConfig_AgreementConsent$Type extends MessageType<AgreementConsentsConfig_AgreementConsent> {
    constructor() {
        super("enrollment.bff.AgreementConsentsConfig.AgreementConsent", [
            { no: 1, name: "consent", kind: "message", T: () => ConsentSpecification },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "enrollment.bff.localizable_string": true } }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentsConfig_AgreementConsent>): AgreementConsentsConfig_AgreementConsent {
        const message = { displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentsConfig_AgreementConsent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentsConfig_AgreementConsent): AgreementConsentsConfig_AgreementConsent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.ConsentSpecification consent */ 1:
                    message.consent = ConsentSpecification.internalBinaryRead(reader, reader.uint32(), options, message.consent);
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentsConfig_AgreementConsent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.ConsentSpecification consent = 1; */
        if (message.consent)
            ConsentSpecification.internalBinaryWrite(message.consent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentsConfig.AgreementConsent
 */
export const AgreementConsentsConfig_AgreementConsent = new AgreementConsentsConfig_AgreementConsent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgreementConsentsConfig_AgreementConsentsGroup$Type extends MessageType<AgreementConsentsConfig_AgreementConsentsGroup> {
    constructor() {
        super("enrollment.bff.AgreementConsentsConfig.AgreementConsentsGroup", [
            { no: 1, name: "consents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgreementConsentsConfig_AgreementConsent }
        ]);
    }
    create(value?: PartialMessage<AgreementConsentsConfig_AgreementConsentsGroup>): AgreementConsentsConfig_AgreementConsentsGroup {
        const message = { consents: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AgreementConsentsConfig_AgreementConsentsGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgreementConsentsConfig_AgreementConsentsGroup): AgreementConsentsConfig_AgreementConsentsGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.AgreementConsentsConfig.AgreementConsent consents */ 1:
                    message.consents.push(AgreementConsentsConfig_AgreementConsent.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgreementConsentsConfig_AgreementConsentsGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.AgreementConsentsConfig.AgreementConsent consents = 1; */
        for (let i = 0; i < message.consents.length; i++)
            AgreementConsentsConfig_AgreementConsent.internalBinaryWrite(message.consents[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AgreementConsentsConfig.AgreementConsentsGroup
 */
export const AgreementConsentsConfig_AgreementConsentsGroup = new AgreementConsentsConfig_AgreementConsentsGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDomainConfigRequest$Type extends MessageType<GetDomainConfigRequest> {
    constructor() {
        super("enrollment.bff.GetDomainConfigRequest", [
            { no: 1, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDomainConfigRequest>): GetDomainConfigRequest {
        const message = { domain: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDomainConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDomainConfigRequest): GetDomainConfigRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string domain */ 1:
                    message.domain = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDomainConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string domain = 1; */
        if (message.domain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.domain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetDomainConfigRequest
 */
export const GetDomainConfigRequest = new GetDomainConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDomainConfigResponse$Type extends MessageType<GetDomainConfigResponse> {
    constructor() {
        super("enrollment.bff.GetDomainConfigResponse", [
            { no: 1, name: "auth_config", kind: "message", T: () => AuthDomainConfig },
            { no: 2, name: "fhir_path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "post_enroller_config", kind: "message", T: () => PostEnrollerConfig },
            { no: 4, name: "econsent_base_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "frontend_auth_config", kind: "message", T: () => FrontendAuthConfig },
            { no: 6, name: "backend_auth_config", kind: "message", T: () => BackendAuthConfig }
        ]);
    }
    create(value?: PartialMessage<GetDomainConfigResponse>): GetDomainConfigResponse {
        const message = { fhirPath: "", econsentBaseUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDomainConfigResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDomainConfigResponse): GetDomainConfigResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.AuthDomainConfig auth_config = 1 [deprecated = true];*/ 1:
                    message.authConfig = AuthDomainConfig.internalBinaryRead(reader, reader.uint32(), options, message.authConfig);
                    break;
                case /* string fhir_path = 2 [deprecated = true];*/ 2:
                    message.fhirPath = reader.string();
                    break;
                case /* enrollment.bff.PostEnrollerConfig post_enroller_config = 3 [deprecated = true];*/ 3:
                    message.postEnrollerConfig = PostEnrollerConfig.internalBinaryRead(reader, reader.uint32(), options, message.postEnrollerConfig);
                    break;
                case /* string econsent_base_url */ 4:
                    message.econsentBaseUrl = reader.string();
                    break;
                case /* enrollment.bff.FrontendAuthConfig frontend_auth_config = 5 [deprecated = true];*/ 5:
                    message.frontendAuthConfig = FrontendAuthConfig.internalBinaryRead(reader, reader.uint32(), options, message.frontendAuthConfig);
                    break;
                case /* enrollment.bff.BackendAuthConfig backend_auth_config = 6 [deprecated = true];*/ 6:
                    message.backendAuthConfig = BackendAuthConfig.internalBinaryRead(reader, reader.uint32(), options, message.backendAuthConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDomainConfigResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.AuthDomainConfig auth_config = 1 [deprecated = true]; */
        if (message.authConfig)
            AuthDomainConfig.internalBinaryWrite(message.authConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string fhir_path = 2 [deprecated = true]; */
        if (message.fhirPath !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fhirPath);
        /* enrollment.bff.PostEnrollerConfig post_enroller_config = 3 [deprecated = true]; */
        if (message.postEnrollerConfig)
            PostEnrollerConfig.internalBinaryWrite(message.postEnrollerConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string econsent_base_url = 4; */
        if (message.econsentBaseUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.econsentBaseUrl);
        /* enrollment.bff.FrontendAuthConfig frontend_auth_config = 5 [deprecated = true]; */
        if (message.frontendAuthConfig)
            FrontendAuthConfig.internalBinaryWrite(message.frontendAuthConfig, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.BackendAuthConfig backend_auth_config = 6 [deprecated = true]; */
        if (message.backendAuthConfig)
            BackendAuthConfig.internalBinaryWrite(message.backendAuthConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetDomainConfigResponse
 */
export const GetDomainConfigResponse = new GetDomainConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthDomainConfig$Type extends MessageType<AuthDomainConfig> {
    constructor() {
        super("enrollment.bff.AuthDomainConfig", [
            { no: 1, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "audience", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "scope", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthDomainConfig>): AuthDomainConfig {
        const message = { domain: "", clientId: "", audience: "", scope: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthDomainConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthDomainConfig): AuthDomainConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string domain */ 1:
                    message.domain = reader.string();
                    break;
                case /* string client_id */ 2:
                    message.clientId = reader.string();
                    break;
                case /* string audience */ 3:
                    message.audience = reader.string();
                    break;
                case /* string scope */ 4:
                    message.scope = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthDomainConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string domain = 1; */
        if (message.domain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.domain);
        /* string client_id = 2; */
        if (message.clientId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.clientId);
        /* string audience = 3; */
        if (message.audience !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.audience);
        /* string scope = 4; */
        if (message.scope !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.scope);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.AuthDomainConfig
 */
export const AuthDomainConfig = new AuthDomainConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FrontendAuthConfig$Type extends MessageType<FrontendAuthConfig> {
    constructor() {
        super("enrollment.bff.FrontendAuthConfig", [
            { no: 1, name: "auth_config", kind: "message", T: () => AuthDomainConfig }
        ]);
    }
    create(value?: PartialMessage<FrontendAuthConfig>): FrontendAuthConfig {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FrontendAuthConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FrontendAuthConfig): FrontendAuthConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.AuthDomainConfig auth_config */ 1:
                    message.authConfig = AuthDomainConfig.internalBinaryRead(reader, reader.uint32(), options, message.authConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FrontendAuthConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.AuthDomainConfig auth_config = 1; */
        if (message.authConfig)
            AuthDomainConfig.internalBinaryWrite(message.authConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.FrontendAuthConfig
 */
export const FrontendAuthConfig = new FrontendAuthConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BackendAuthConfig$Type extends MessageType<BackendAuthConfig> {
    constructor() {
        super("enrollment.bff.BackendAuthConfig", [
            { no: 1, name: "secret_config", kind: "message", T: () => SecretConfig },
            { no: 2, name: "auth_config", kind: "message", T: () => AuthDomainConfig },
            { no: 3, name: "management_api", kind: "message", T: () => ManagementApi }
        ]);
    }
    create(value?: PartialMessage<BackendAuthConfig>): BackendAuthConfig {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BackendAuthConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BackendAuthConfig): BackendAuthConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.SecretConfig secret_config */ 1:
                    message.secretConfig = SecretConfig.internalBinaryRead(reader, reader.uint32(), options, message.secretConfig);
                    break;
                case /* enrollment.bff.AuthDomainConfig auth_config */ 2:
                    message.authConfig = AuthDomainConfig.internalBinaryRead(reader, reader.uint32(), options, message.authConfig);
                    break;
                case /* enrollment.bff.ManagementApi management_api */ 3:
                    message.managementApi = ManagementApi.internalBinaryRead(reader, reader.uint32(), options, message.managementApi);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BackendAuthConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.SecretConfig secret_config = 1; */
        if (message.secretConfig)
            SecretConfig.internalBinaryWrite(message.secretConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.AuthDomainConfig auth_config = 2; */
        if (message.authConfig)
            AuthDomainConfig.internalBinaryWrite(message.authConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ManagementApi management_api = 3; */
        if (message.managementApi)
            ManagementApi.internalBinaryWrite(message.managementApi, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.BackendAuthConfig
 */
export const BackendAuthConfig = new BackendAuthConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SecretConfig$Type extends MessageType<SecretConfig> {
    constructor() {
        super("enrollment.bff.SecretConfig", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "secret_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SecretConfig>): SecretConfig {
        const message = { projectId: "", secretName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SecretConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SecretConfig): SecretConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string secret_name */ 2:
                    message.secretName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SecretConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string secret_name = 2; */
        if (message.secretName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.secretName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.SecretConfig
 */
export const SecretConfig = new SecretConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManagementApi$Type extends MessageType<ManagementApi> {
    constructor() {
        super("enrollment.bff.ManagementApi", [
            { no: 1, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ManagementApi>): ManagementApi {
        const message = { domain: "", clientId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ManagementApi>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManagementApi): ManagementApi {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string domain */ 1:
                    message.domain = reader.string();
                    break;
                case /* string client_id */ 2:
                    message.clientId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManagementApi, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string domain = 1; */
        if (message.domain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.domain);
        /* string client_id = 2; */
        if (message.clientId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.clientId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ManagementApi
 */
export const ManagementApi = new ManagementApi$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostEnrollerConfig$Type extends MessageType<PostEnrollerConfig> {
    constructor() {
        super("enrollment.bff.PostEnrollerConfig", [
            { no: 1, name: "onduo", kind: "message", oneof: "config", T: () => PostEnrollerConfig_Onduo }
        ]);
    }
    create(value?: PartialMessage<PostEnrollerConfig>): PostEnrollerConfig {
        const message = { config: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostEnrollerConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostEnrollerConfig): PostEnrollerConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.PostEnrollerConfig.Onduo onduo */ 1:
                    message.config = {
                        oneofKind: "onduo",
                        onduo: PostEnrollerConfig_Onduo.internalBinaryRead(reader, reader.uint32(), options, (message.config as any).onduo)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostEnrollerConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.PostEnrollerConfig.Onduo onduo = 1; */
        if (message.config.oneofKind === "onduo")
            PostEnrollerConfig_Onduo.internalBinaryWrite(message.config.onduo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message enrollment.bff.PostEnrollerConfig
 */
export const PostEnrollerConfig = new PostEnrollerConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostEnrollerConfig_Onduo$Type extends MessageType<PostEnrollerConfig_Onduo> {
    constructor() {
        super("enrollment.bff.PostEnrollerConfig.Onduo", [
            { no: 1, name: "hostname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "firebase", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostEnrollerConfig_Onduo>): PostEnrollerConfig_Onduo {
        const message = { hostname: "", firebase: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostEnrollerConfig_Onduo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostEnrollerConfig_Onduo): PostEnrollerConfig_Onduo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string hostname */ 1:
                    message.hostname = reader.string();
                    break;
                case /* string firebase */ 2:
                    message.firebase = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostEnrollerConfig_Onduo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string hostname = 1; */
        if (message.hostname !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hostname);
        /* string firebase = 2; */
        if (message.firebase !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firebase);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.PostEnrollerConfig.Onduo
 */
export const PostEnrollerConfig_Onduo = new PostEnrollerConfig_Onduo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordEnrolleeEventRequest$Type extends MessageType<RecordEnrolleeEventRequest> {
    constructor() {
        super("enrollment.bff.RecordEnrolleeEventRequest", [
            { no: 1, name: "observed_at", kind: "message", T: () => Timestamp },
            { no: 2, name: "observed_time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "event", kind: "enum", T: () => ["enrollment.bff.EnrolleeEvent", EnrolleeEvent] },
            { no: 4, name: "profile", kind: "message", T: () => EnrollmentProfile }
        ]);
    }
    create(value?: PartialMessage<RecordEnrolleeEventRequest>): RecordEnrolleeEventRequest {
        const message = { observedTimeZone: "", event: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordEnrolleeEventRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordEnrolleeEventRequest): RecordEnrolleeEventRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp observed_at */ 1:
                    message.observedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.observedAt);
                    break;
                case /* string observed_time_zone */ 2:
                    message.observedTimeZone = reader.string();
                    break;
                case /* enrollment.bff.EnrolleeEvent event */ 3:
                    message.event = reader.int32();
                    break;
                case /* enrollment.bff.EnrollmentProfile profile */ 4:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordEnrolleeEventRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp observed_at = 1; */
        if (message.observedAt)
            Timestamp.internalBinaryWrite(message.observedAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string observed_time_zone = 2; */
        if (message.observedTimeZone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.observedTimeZone);
        /* enrollment.bff.EnrolleeEvent event = 3; */
        if (message.event !== 0)
            writer.tag(3, WireType.Varint).int32(message.event);
        /* enrollment.bff.EnrollmentProfile profile = 4; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordEnrolleeEventRequest
 */
export const RecordEnrolleeEventRequest = new RecordEnrolleeEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordEnrolleeEventResponse$Type extends MessageType<RecordEnrolleeEventResponse> {
    constructor() {
        super("enrollment.bff.RecordEnrolleeEventResponse", []);
    }
    create(value?: PartialMessage<RecordEnrolleeEventResponse>): RecordEnrolleeEventResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordEnrolleeEventResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordEnrolleeEventResponse): RecordEnrolleeEventResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordEnrolleeEventResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordEnrolleeEventResponse
 */
export const RecordEnrolleeEventResponse = new RecordEnrolleeEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordUTMRequest$Type extends MessageType<RecordUTMRequest> {
    constructor() {
        super("enrollment.bff.RecordUTMRequest", [
            { no: 1, name: "utm_tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RecordUTMRequest_UTMTag },
            { no: 2, name: "profile", kind: "message", T: () => EnrollmentProfile }
        ]);
    }
    create(value?: PartialMessage<RecordUTMRequest>): RecordUTMRequest {
        const message = { utmTags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordUTMRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordUTMRequest): RecordUTMRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated enrollment.bff.RecordUTMRequest.UTMTag utm_tags */ 1:
                    message.utmTags.push(RecordUTMRequest_UTMTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* enrollment.bff.EnrollmentProfile profile */ 2:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordUTMRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated enrollment.bff.RecordUTMRequest.UTMTag utm_tags = 1; */
        for (let i = 0; i < message.utmTags.length; i++)
            RecordUTMRequest_UTMTag.internalBinaryWrite(message.utmTags[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.EnrollmentProfile profile = 2; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordUTMRequest
 */
export const RecordUTMRequest = new RecordUTMRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordUTMRequest_UTMTag$Type extends MessageType<RecordUTMRequest_UTMTag> {
    constructor() {
        super("enrollment.bff.RecordUTMRequest.UTMTag", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecordUTMRequest_UTMTag>): RecordUTMRequest_UTMTag {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordUTMRequest_UTMTag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordUTMRequest_UTMTag): RecordUTMRequest_UTMTag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecordUTMRequest_UTMTag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordUTMRequest.UTMTag
 */
export const RecordUTMRequest_UTMTag = new RecordUTMRequest_UTMTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordUTMResponse$Type extends MessageType<RecordUTMResponse> {
    constructor() {
        super("enrollment.bff.RecordUTMResponse", []);
    }
    create(value?: PartialMessage<RecordUTMResponse>): RecordUTMResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RecordUTMResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecordUTMResponse): RecordUTMResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RecordUTMResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.RecordUTMResponse
 */
export const RecordUTMResponse = new RecordUTMResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogInRequest$Type extends MessageType<LogInRequest> {
    constructor() {
        super("enrollment.bff.LogInRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile }
        ]);
    }
    create(value?: PartialMessage<LogInRequest>): LogInRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogInRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogInRequest): LogInRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogInRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.LogInRequest
 */
export const LogInRequest = new LogInRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogInResponse$Type extends MessageType<LogInResponse> {
    constructor() {
        super("enrollment.bff.LogInResponse", []);
    }
    create(value?: PartialMessage<LogInResponse>): LogInResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LogInResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogInResponse): LogInResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LogInResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.LogInResponse
 */
export const LogInResponse = new LogInResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStateRequest$Type extends MessageType<GetUserStateRequest> {
    constructor() {
        super("enrollment.bff.GetUserStateRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<GetUserStateRequest>): GetUserStateRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserStateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserStateRequest): GetUserStateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.Step current_step */ 2:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserStateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Step current_step = 2; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetUserStateRequest
 */
export const GetUserStateRequest = new GetUserStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStateResponse$Type extends MessageType<GetUserStateResponse> {
    constructor() {
        super("enrollment.bff.GetUserStateResponse", [
            { no: 1, name: "patient_data", kind: "message", T: () => PatientData },
            { no: 2, name: "consent_data", kind: "message", T: () => ConsentData },
            { no: 3, name: "inclusion_data", kind: "message", T: () => InclusionScreenerData },
            { no: 4, name: "exclusion_data", kind: "message", T: () => ExclusionScreenerData },
            { no: 5, name: "is_complete", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "patient_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserStateResponse>): GetUserStateResponse {
        const message = { isComplete: false, patientId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetUserStateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserStateResponse): GetUserStateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.PatientData patient_data */ 1:
                    message.patientData = PatientData.internalBinaryRead(reader, reader.uint32(), options, message.patientData);
                    break;
                case /* enrollment.bff.ConsentData consent_data = 2 [deprecated = true];*/ 2:
                    message.consentData = ConsentData.internalBinaryRead(reader, reader.uint32(), options, message.consentData);
                    break;
                case /* enrollment.bff.InclusionScreenerData inclusion_data */ 3:
                    message.inclusionData = InclusionScreenerData.internalBinaryRead(reader, reader.uint32(), options, message.inclusionData);
                    break;
                case /* enrollment.bff.ExclusionScreenerData exclusion_data */ 4:
                    message.exclusionData = ExclusionScreenerData.internalBinaryRead(reader, reader.uint32(), options, message.exclusionData);
                    break;
                case /* bool is_complete */ 5:
                    message.isComplete = reader.bool();
                    break;
                case /* string patient_id */ 6:
                    message.patientId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserStateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.PatientData patient_data = 1; */
        if (message.patientData)
            PatientData.internalBinaryWrite(message.patientData, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ConsentData consent_data = 2 [deprecated = true]; */
        if (message.consentData)
            ConsentData.internalBinaryWrite(message.consentData, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.InclusionScreenerData inclusion_data = 3; */
        if (message.inclusionData)
            InclusionScreenerData.internalBinaryWrite(message.inclusionData, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.ExclusionScreenerData exclusion_data = 4; */
        if (message.exclusionData)
            ExclusionScreenerData.internalBinaryWrite(message.exclusionData, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bool is_complete = 5; */
        if (message.isComplete !== false)
            writer.tag(5, WireType.Varint).bool(message.isComplete);
        /* string patient_id = 6; */
        if (message.patientId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.patientId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetUserStateResponse
 */
export const GetUserStateResponse = new GetUserStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartSessionRequest$Type extends MessageType<StartSessionRequest> {
    constructor() {
        super("enrollment.bff.StartSessionRequest", [
            { no: 4, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 1, name: "query_params", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StartSessionRequest_QueryParam },
            { no: 2, name: "time_zone", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^[+-](?:2[0-3]|[01][0-9]):[0-5][0-9]$" } } } }
        ]);
    }
    create(value?: PartialMessage<StartSessionRequest>): StartSessionRequest {
        const message = { queryParams: [], timeZone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartSessionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartSessionRequest): StartSessionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 4:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* repeated enrollment.bff.StartSessionRequest.QueryParam query_params */ 1:
                    message.queryParams.push(StartSessionRequest_QueryParam.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string time_zone */ 2:
                    message.timeZone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartSessionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 4; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated enrollment.bff.StartSessionRequest.QueryParam query_params = 1; */
        for (let i = 0; i < message.queryParams.length; i++)
            StartSessionRequest_QueryParam.internalBinaryWrite(message.queryParams[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string time_zone = 2; */
        if (message.timeZone !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.timeZone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.StartSessionRequest
 */
export const StartSessionRequest = new StartSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartSessionRequest_QueryParam$Type extends MessageType<StartSessionRequest_QueryParam> {
    constructor() {
        super("enrollment.bff.StartSessionRequest.QueryParam", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartSessionRequest_QueryParam>): StartSessionRequest_QueryParam {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartSessionRequest_QueryParam>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartSessionRequest_QueryParam): StartSessionRequest_QueryParam {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartSessionRequest_QueryParam, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.StartSessionRequest.QueryParam
 */
export const StartSessionRequest_QueryParam = new StartSessionRequest_QueryParam$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartSessionResponse$Type extends MessageType<StartSessionResponse> {
    constructor() {
        super("enrollment.bff.StartSessionResponse", [
            { no: 1, name: "session_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartSessionResponse>): StartSessionResponse {
        const message = { sessionToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartSessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartSessionResponse): StartSessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_token = 1 [deprecated = true];*/ 1:
                    message.sessionToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartSessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string session_token = 1 [deprecated = true]; */
        if (message.sessionToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.StartSessionResponse
 */
export const StartSessionResponse = new StartSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportErrorRequest$Type extends MessageType<ReportErrorRequest> {
    constructor() {
        super("enrollment.bff.ReportErrorRequest", [
            { no: 1, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "stack", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReportErrorRequest>): ReportErrorRequest {
        const message = { location: "", message: "", stack: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportErrorRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportErrorRequest): ReportErrorRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string location */ 1:
                    message.location = reader.string();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* string stack */ 3:
                    message.stack = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportErrorRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string location = 1; */
        if (message.location !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.location);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* string stack = 3; */
        if (message.stack !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.stack);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ReportErrorRequest
 */
export const ReportErrorRequest = new ReportErrorRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportErrorResponse$Type extends MessageType<ReportErrorResponse> {
    constructor() {
        super("enrollment.bff.ReportErrorResponse", []);
    }
    create(value?: PartialMessage<ReportErrorResponse>): ReportErrorResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportErrorResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportErrorResponse): ReportErrorResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReportErrorResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ReportErrorResponse
 */
export const ReportErrorResponse = new ReportErrorResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthenticationRequest$Type extends MessageType<CheckAuthenticationRequest> {
    constructor() {
        super("enrollment.bff.CheckAuthenticationRequest", [
            { no: 1, name: "profile", kind: "message", T: () => EnrollmentProfile },
            { no: 2, name: "current_step", kind: "message", T: () => Step }
        ]);
    }
    create(value?: PartialMessage<CheckAuthenticationRequest>): CheckAuthenticationRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckAuthenticationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthenticationRequest): CheckAuthenticationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* enrollment.bff.EnrollmentProfile profile */ 1:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* enrollment.bff.Step current_step */ 2:
                    message.currentStep = Step.internalBinaryRead(reader, reader.uint32(), options, message.currentStep);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckAuthenticationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* enrollment.bff.EnrollmentProfile profile = 1; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* enrollment.bff.Step current_step = 2; */
        if (message.currentStep)
            Step.internalBinaryWrite(message.currentStep, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CheckAuthenticationRequest
 */
export const CheckAuthenticationRequest = new CheckAuthenticationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthenticationResponse$Type extends MessageType<CheckAuthenticationResponse> {
    constructor() {
        super("enrollment.bff.CheckAuthenticationResponse", [
            { no: 1, name: "session_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckAuthenticationResponse>): CheckAuthenticationResponse {
        const message = { sessionToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckAuthenticationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthenticationResponse): CheckAuthenticationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string session_token = 1 [deprecated = true];*/ 1:
                    message.sessionToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckAuthenticationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string session_token = 1 [deprecated = true]; */
        if (message.sessionToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sessionToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.CheckAuthenticationResponse
 */
export const CheckAuthenticationResponse = new CheckAuthenticationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordResetReturnProfileRequest$Type extends MessageType<GetPasswordResetReturnProfileRequest> {
    constructor() {
        super("enrollment.bff.GetPasswordResetReturnProfileRequest", [
            { no: 1, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPasswordResetReturnProfileRequest>): GetPasswordResetReturnProfileRequest {
        const message = { domain: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordResetReturnProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordResetReturnProfileRequest): GetPasswordResetReturnProfileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string domain */ 1:
                    message.domain = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPasswordResetReturnProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string domain = 1; */
        if (message.domain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.domain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetPasswordResetReturnProfileRequest
 */
export const GetPasswordResetReturnProfileRequest = new GetPasswordResetReturnProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordResetReturnProfileResponse$Type extends MessageType<GetPasswordResetReturnProfileResponse> {
    constructor() {
        super("enrollment.bff.GetPasswordResetReturnProfileResponse", [
            { no: 1, name: "profile", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPasswordResetReturnProfileResponse>): GetPasswordResetReturnProfileResponse {
        const message = { profile: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordResetReturnProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordResetReturnProfileResponse): GetPasswordResetReturnProfileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string profile */ 1:
                    message.profile = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPasswordResetReturnProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string profile = 1; */
        if (message.profile !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.profile);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.GetPasswordResetReturnProfileResponse
 */
export const GetPasswordResetReturnProfileResponse = new GetPasswordResetReturnProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResendPasswordResetEmailRequest$Type extends MessageType<ResendPasswordResetEmailRequest> {
    constructor() {
        super("enrollment.bff.ResendPasswordResetEmailRequest", [
            { no: 1, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "profile", kind: "message", T: () => EnrollmentProfile, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "auth0_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<ResendPasswordResetEmailRequest>): ResendPasswordResetEmailRequest {
        const message = { emailAddress: "", auth0Id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResendPasswordResetEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResendPasswordResetEmailRequest): ResendPasswordResetEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email_address */ 1:
                    message.emailAddress = reader.string();
                    break;
                case /* enrollment.bff.EnrollmentProfile profile */ 2:
                    message.profile = EnrollmentProfile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                case /* string auth0_id */ 3:
                    message.auth0Id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResendPasswordResetEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email_address = 1; */
        if (message.emailAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.emailAddress);
        /* enrollment.bff.EnrollmentProfile profile = 2; */
        if (message.profile)
            EnrollmentProfile.internalBinaryWrite(message.profile, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string auth0_id = 3; */
        if (message.auth0Id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.auth0Id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ResendPasswordResetEmailRequest
 */
export const ResendPasswordResetEmailRequest = new ResendPasswordResetEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResendPasswordResetEmailResponse$Type extends MessageType<ResendPasswordResetEmailResponse> {
    constructor() {
        super("enrollment.bff.ResendPasswordResetEmailResponse", []);
    }
    create(value?: PartialMessage<ResendPasswordResetEmailResponse>): ResendPasswordResetEmailResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ResendPasswordResetEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResendPasswordResetEmailResponse): ResendPasswordResetEmailResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResendPasswordResetEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message enrollment.bff.ResendPasswordResetEmailResponse
 */
export const ResendPasswordResetEmailResponse = new ResendPasswordResetEmailResponse$Type();
/**
 * @generated ServiceType for protobuf service enrollment.bff.Enrollment
 */
export const Enrollment = new ServiceType("enrollment.bff.Enrollment", [
    { name: "StartSession", options: {}, I: StartSessionRequest, O: StartSessionResponse },
    { name: "CreateAccount", options: {}, I: CreateAccountRequest, O: CreateAccountResponse },
    { name: "ResendPasswordResetEmail", options: {}, I: ResendPasswordResetEmailRequest, O: ResendPasswordResetEmailResponse },
    { name: "Enroll", options: {}, I: EnrollPatientRequest, O: EnrollPatientResponse },
    { name: "RecordPatientData", options: {}, I: RecordPatientDataRequest, O: RecordPatientDataResponse },
    { name: "RecordInclusionScreenerData", options: {}, I: RecordInclusionScreenerDataRequest, O: RecordInclusionScreenerDataResponse },
    { name: "RecordExclusionScreenerData", options: {}, I: RecordExclusionScreenerDataRequest, O: RecordExclusionScreenerDataResponse },
    { name: "GetAgreementConsents", options: {}, I: GetAgreementConsentsRequest, O: GetAgreementConsentsResponse },
    { name: "RecordPolicyConsents", options: {}, I: RecordPolicyConsentsRequest, O: RecordPolicyConsentsResponse },
    { name: "CompleteEnrollment", options: {}, I: CompleteEnrollmentRequest, O: CompleteEnrollmentResponse },
    { name: "CheckEligibility", options: {}, I: CheckEligibilityRequest, O: CheckEligibilityResponse },
    { name: "GetProfileConfig", options: {}, I: GetProfileConfigRequest, O: GetProfileConfigResponse },
    { name: "GetDomainConfig", options: {}, I: GetDomainConfigRequest, O: GetDomainConfigResponse },
    { name: "RecordEnrolleeEvent", options: {}, I: RecordEnrolleeEventRequest, O: RecordEnrolleeEventResponse },
    { name: "RecordUTM", options: {}, I: RecordUTMRequest, O: RecordUTMResponse },
    { name: "LogIn", options: {}, I: LogInRequest, O: LogInResponse },
    { name: "GetUserState", options: {}, I: GetUserStateRequest, O: GetUserStateResponse },
    { name: "ReportError", options: {}, I: ReportErrorRequest, O: ReportErrorResponse },
    { name: "CheckAuthentication", options: {}, I: CheckAuthenticationRequest, O: CheckAuthenticationResponse },
    { name: "GetPasswordResetReturnProfile", options: {}, I: GetPasswordResetReturnProfileRequest, O: GetPasswordResetReturnProfileResponse }
]);
