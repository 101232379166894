import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  CheckEligibilityRequest,
  CheckEligibilityResponse,
  Date as ProtoDate,
  Name,
} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server';
import {getRpcOptionsMaybeMockingCiam} from '../../utils/clientCaller';
import {client} from './index';

export const makeCheckEligibilityCall = async (
  req: CheckEligibilityRequest,
  opts: RpcOptions
): Promise<CheckEligibilityResponse> => {
  const call = await client.checkEligibility(req, opts);
  return call.response;
};

export const checkEligibility = async (
  actionName: string,
  args: {
    name: Name;
    dateOfBirth: ProtoDate;
    enrollmentVerificationKey: string;
  }
): Promise<{isEligible: boolean}> => {
  const req: CheckEligibilityRequest = {
    actionName: actionName,
    name: args.name,
    dateOfBirth: args.dateOfBirth,
    enrollmentVerificationKey: args.enrollmentVerificationKey,
  };
  const res = await makeCheckEligibilityCall(
    req,
    await getRpcOptionsMaybeMockingCiam()
  );
  return {isEligible: res.isEligible};
};
