export const AUTOMATION_IDS = {
  LANDING_PAGE: {
    LATEST_ENCOUNTERS: 'latest-encounters',
    LATEST_ENCOUNTERS_PRACTITIONER_NAME: 'latest-encounters-practitioner-name',
    LATEST_ENCOUNTERS_START_TIME: 'latest-encounters-start-time',
    LATEST_ENCOUNTERS_END_TIME: 'latest-encounters-end-time',
    LATEST_ENCOUNTERS_LOCATION: 'latest-encounters-location',
  },
  SECTION_PAGE: {
    ALL_ENCOUNTERS: 'all-encounters',
    ALL_ENCOUNTERS_PRACTITIONER_NAME: 'all-encounters-practitioner-name',
    ALL_ENCOUNTERS_START_TIME: 'all-encounters-start-time',
    ALL_ENCOUNTERS_END_TIME: 'all-encounters-end-time',
    ALL_ENCOUNTERS_LOCATION: 'all-encounters-location',
  },
  NO_PAST_EVENTS_MESSAGE: 'no-past-events-message',
};
