import {Box, CircularProgress, Typography, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {TaskCard_IllustrationType} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/card_service';
import {ContentCard as ContentCardPb} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/discover_service';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {ContentCard} from '../ContentCard';
import {EducationFeedPlaceholderImage} from './EducationFeedPlaceholderImage';

interface EducationFeedProps {
  isLoading: boolean;
  contentCards: ContentCardPb[];
}

const EducationFeed = ({contentCards, isLoading}: EducationFeedProps) => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  const skeletonsEnabled = config.getBoolean('FEATURE_SKELETONS_ENABLED');

  const hasNoData = !isLoading && contentCards.length === 0;
  const hasData = !isLoading && contentCards.length > 0;

  if (isLoading) {
    return skeletonsEnabled ? (
      <Grid container spacing={isMobile ? 1 : 2} paddingBottom={8}>
        {[...Array(2).keys()].map(idx => (
          <Grid
            item
            xs={12}
            key={idx}
            flexDirection={'column'}
            display={'flex'}
          >
            <ContentCard loading />
          </Grid>
        ))}
      </Grid>
    ) : (
      <CircularProgress
        aria-label={t('spinner-label')}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  if (hasNoData) {
    return <EmptyContent />;
  }

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 2}
      data-id="edu-feed"
      paddingBottom={8}
    >
      {hasData &&
        contentCards.map(contentCard => {
          // Create a Blob from the Uint8Array
          const blob = new Blob([contentCard.headerImage], {
            type: 'image/jpeg',
          });
          // Create a URL for the Blob
          const imageUrl = URL.createObjectURL(blob);
          return (
            <Grid
              item
              xs={12}
              key={contentCard.id}
              flexDirection={'column'}
              display={'flex'}
            >
              <ContentCard
                cardId={contentCard.id}
                title={contentCard.title}
                date={contentCard.elapsedTime}
                contentUrl={contentCard.externalUrl}
                source={{
                  type: contentCard.contentSource,
                  title: contentCard.sourceName,
                }}
                contentImage={{
                  altText: contentCard.altText || contentCard.title,
                  href: imageUrl,
                  imageType:
                    TaskCard_IllustrationType.ILLUSTRATION_TYPE_UNSPECIFIED,
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

const EmptyContent = () => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 4,
        gap: 0,
      }}
    >
      <Box
        sx={{
          m: 4,
          boxSizing: 'border-box',
          width: '295px',
        }}
      >
        <EducationFeedPlaceholderImage />
      </Box>
      <Typography
        variant="display5"
        component="h2"
        // TODO(ONEVERILY-17471): Import Poppins `500` weight font & replace `600` instances
        sx={{
          mb: 2,
          fontSize: isMobile ? 21 : undefined,
          fontWeight: 600,
        }}
      >
        {t('well-personalize-your-feed')}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          px: isMobile ? 3 : 4,
          color: theme.palette.text.muted,
        }}
      >
        {t('start-by-taking-a-survey')}
      </Typography>
    </Box>
  );
};

const arePropsEqual = (
  props1: EducationFeedProps,
  props2: EducationFeedProps
): boolean => {
  // For efficiency, we have shortcuts to return and only compare a unique prop on each card.
  if (
    props1.isLoading !== props2.isLoading ||
    props1.contentCards.length !== props2.contentCards.length
  )
    return false;

  for (let i = 0; i < props1.contentCards.length; i++) {
    if (props1.contentCards[i].id !== props2.contentCards[i].id) return false;
  }

  return true;
};

// Only re-render on prop changes
export default memo(EducationFeed, arePropsEqual);
