/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "phr/past-events/bff/api/v1/past-events_bff.proto" (package "phr.past_events.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// (-- api-linter: core::0191::proto-package=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
// (-- api-linter: core::0191::filenames=disabled
//     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
//
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PastEventsBFFService } from "./past-events_bff";
import type { GetAllEncountersDetailsRequest } from "./past-events_bff";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetEncounterDetailsListResponse } from "./past-events_bff";
import type { GetLatestEncountersDetailsRequest } from "./past-events_bff";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * APIs for PHR MFE to display historical patient health record data
 *
 * @generated from protobuf service phr.past_events.bff.api.v1.PastEventsBFFService
 */
export interface IPastEventsBFFServiceClient {
    /**
     * Get full details of N most recent encounters
     *
     * @generated from protobuf rpc: GetLatestEncountersDetails(phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getLatestEncountersDetails(input: GetLatestEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetLatestEncountersDetailsRequest, GetEncounterDetailsListResponse>;
    /**
     * Get full details of all encounters
     *
     * @generated from protobuf rpc: GetAllEncountersDetails(phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getAllEncountersDetails(input: GetAllEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetAllEncountersDetailsRequest, GetEncounterDetailsListResponse>;
}
/**
 * APIs for PHR MFE to display historical patient health record data
 *
 * @generated from protobuf service phr.past_events.bff.api.v1.PastEventsBFFService
 */
export class PastEventsBFFServiceClient implements IPastEventsBFFServiceClient, ServiceInfo {
    typeName = PastEventsBFFService.typeName;
    methods = PastEventsBFFService.methods;
    options = PastEventsBFFService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Get full details of N most recent encounters
     *
     * @generated from protobuf rpc: GetLatestEncountersDetails(phr.past_events.bff.api.v1.GetLatestEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getLatestEncountersDetails(input: GetLatestEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetLatestEncountersDetailsRequest, GetEncounterDetailsListResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLatestEncountersDetailsRequest, GetEncounterDetailsListResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Get full details of all encounters
     *
     * @generated from protobuf rpc: GetAllEncountersDetails(phr.past_events.bff.api.v1.GetAllEncountersDetailsRequest) returns (phr.past_events.bff.api.v1.GetEncounterDetailsListResponse);
     */
    getAllEncountersDetails(input: GetAllEncountersDetailsRequest, options?: RpcOptions): UnaryCall<GetAllEncountersDetailsRequest, GetEncounterDetailsListResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllEncountersDetailsRequest, GetEncounterDetailsListResponse>("unary", this._transport, method, opt, input);
    }
}
