import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import getRpcOptions from '../api/getRpcOptions';

import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  GetAllEncountersDetailsRequest,
  GetEncounterDetailsListResponse,
  GetLatestEncountersDetailsRequest,
} from '@verily-src/verily1-protos/phr/past-events/bff/api/v1/past-events_bff';
import {PastEventsBFFServiceClient} from '@verily-src/verily1-protos/phr/past-events/bff/api/v1/past-events_bff.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {createSessionCache} from './CachedEndpoint';

let host = '/api';
const LATEST_ENCOUNTERS_DISPLAYED = 3;
let isLocal = false;
let rpcOptions: RpcOptions = {};

if (process.env.NODE_ENV === 'development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '8997';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
  isLocal = true;
}

class PastEventsService {
  private pastEventsServiceClient?: PastEventsBFFServiceClient;
  private latestEncounterDetailsCache =
    createSessionCache<GetEncounterDetailsListResponse>(() =>
      this._getLatestEncountersDetails()
    );
  private allEncounterDetailsCache =
    createSessionCache<GetEncounterDetailsListResponse>(() =>
      this._getAllEncountersDetails()
    );

  async getClient() {
    if (!isLocal) {
      rpcOptions = await getRpcOptions(); // set the rpcOptions a single time at client level
    }
    if (!this.pastEventsServiceClient) {
      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors: [new LogGrpcUnaryInterceptor()],
      });

      this.pastEventsServiceClient = new PastEventsBFFServiceClient(transport);
    }

    return this.pastEventsServiceClient;
  }

  getLatestEncountersDetails = this.latestEncounterDetailsCache.get;

  private async _getLatestEncountersDetails(): Promise<GetEncounterDetailsListResponse> {
    const request: GetLatestEncountersDetailsRequest = {
      numEncounters: LATEST_ENCOUNTERS_DISPLAYED,
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getLatestEncountersDetails(
      request,
      rpcOptions
    ).response;
  }

  getAllEncountersDetails = this.allEncounterDetailsCache.get;

  private async _getAllEncountersDetails(): Promise<GetEncounterDetailsListResponse> {
    const request: GetAllEncountersDetailsRequest = {
      pageSize: 1,
      pageToken: '',
    };

    const pastEventsServiceClient = await this.getClient();

    return pastEventsServiceClient.getAllEncountersDetails(request, rpcOptions)
      .response;
  }
}

export default new PastEventsService();

export type PastEvent = {
  date: string;
  location?: string;
  summary: string;
  provider: string;
  notes?: string;
};
