import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {RpcInterceptor} from '@protobuf-ts/runtime-rpc';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {
  ContentCard,
  ListContentCardsRequest,
  RecordContentCardInteractionRequest,
  RecordContentCardInteractionResponse,
} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/discover_service';
import {DiscoverServiceClient} from '@verily-src/verily1-protos/verily-me/web/home/bff/api/v1/discover_service.client';
import {createSessionCache} from '@verily-src/verily1-verily-me-web-common-typescript/src/services/CachedEndpoint';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {handleGrpcError} from './ErrorHandling';

let host = '/api';

if (process.env.NODE_ENV === 'local-development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '3000';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
}

class DiscoverService {
  private discoverServiceClient!: DiscoverServiceClient;
  private listContentCardsCache = createSessionCache<ContentCard[]>(() =>
    this._listContentCards()
  );

  async getClient() {
    if (!this.discoverServiceClient) {
      const interceptors: RpcInterceptor[] = [
        await (api as typeof VerilyMeApi).auth.getInterceptor(),
      ];

      if (config.getBoolean('FEATURE_LOG_GRPC_REQUESTS_TO_CONSOLE_ENABLED')) {
        interceptors.push(new LogGrpcUnaryInterceptor());
      }

      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors,
      });

      this.discoverServiceClient = new DiscoverServiceClient(transport);
    }

    return this.discoverServiceClient;
  }

  listContentCards = this.listContentCardsCache.get;

  async recordContentCardInteraction(
    id: string
  ): Promise<RecordContentCardInteractionResponse> {
    const request: RecordContentCardInteractionRequest = {
      contentCardId: id,
    };

    const client = await this.getClient();

    try {
      return (await client.recordContentCardInteraction(request)).response;
    } catch (err) {
      throw handleGrpcError(err);
    }
  }

  private async _listContentCards(): Promise<ContentCard[]> {
    // TODO: Remove language_tag from ListContentCardsRequest
    const request: ListContentCardsRequest = {
      languageTag: '',
    };

    const discoverServiceClient = await this.getClient();

    try {
      return (await discoverServiceClient.listContentCards(request)).response
        .contentCards;
    } catch (err) {
      throw handleGrpcError(err);
    }
  }
}

export default new DiscoverService();
