import {
  Bundle,
  BundleStep,
  StepStatus,
} from '@verily-src/verily1-protos/verily-me/web/bundle/bff/api/v1/bundle_service';
import BundleService, {BundleWithStatus} from './service/BundleService';

export enum BundleStatus {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
}

/**
 * Retrieves the status of each bundle in the provided Map of bundles.
 *
 * @param {Map<string, Bundle>} bundles - The Map containing bundle information, where keys are bundle IDs and values are Bundle objects.
 * @returns {Record<string, BundleStatus>} - A Record containing bundle IDs as keys and their corresponding status as values.
 *
 * @example
 * const bundlesMap = new Map();
 * bundlesMap.set('bundle1', { steps: [{ status: StepStatus.COMPLETED }, { status: StepStatus.IN_PROGRESS }] });
 * bundlesMap.set('bundle2', { steps: [{ status: StepStatus.COMPLETED }, { status: StepStatus.COMPLETED }] });
 *
 * const statuses = getBundleStatuses(bundlesMap);
 * // Output: { 'bundle1': BundleStatus.IN_PROGRESS, 'bundle2': BundleStatus.COMPLETED }
 */
export const getBundleStatuses = (
  bundles: Map<string, BundleWithStatus>
): Record<string, BundleStatus> => {
  const res: Record<string, BundleStatus> = {};
  for (const [bundleId, bundleWithStatus] of bundles) {
    const bundle = bundleWithStatus.bundle;
    const maybeStatus = bundleWithStatus.status;
    res[bundleId] = maybeStatus ? maybeStatus : getStatusOfBundle(bundle.steps);
  }
  return res;
};

export const getStatusOfBundle = (steps: BundleStep[]): BundleStatus => {
  for (const step of steps) {
    if (step.status === StepStatus.IN_PROGRESS) {
      return BundleStatus.IN_PROGRESS;
    } else if (step.status !== StepStatus.COMPLETED) {
      return BundleStatus.NOT_STARTED;
    }
  }
  return BundleStatus.COMPLETED;
};

export async function pollListBundles(): Promise<Bundle[]> {
  const startTime = Date.now();
  // Poll for at most 10 seconds
  while (Date.now() - startTime < 10000) {
    try {
      const res = await BundleService.listBundles();
      if (res.length) return res;
    } catch (error) {
      // Continue polling on errors
      console.error(error);
    }
  }
  // Return empty list if unable to get a valid response after 10s
  return [];
}
