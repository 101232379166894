import {Box, Divider, Stack, Typography, useTheme} from '@mui/material';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {
  Card,
  CircularProgress,
  Snackbar,
  Switch,
} from '@verily-src/react-design-system';
import {
  NotificationUserPreference_NotificationCategory,
  NotificationUserPreference_NotificationChannel,
} from '@verily-src/verily1-protos/verily-me/web/userprofile/bff/api/v1/notification_user_preferences_service';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {isAuthenticationError} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/isAuthenticationError';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {navigateToUrl} from 'single-spa';
import {PreferencesOffConfirmationDialog} from '../../components/PreferencesOffConfirmationDialog/PreferencesOffConfirmationDialog';
import NotificationUserPreferencesService from '../../services/NotificationUserPreferencesService';
import logError from '../../utils/logError';

// TODO(ONEVERILY-33237): Delete non-category-based notification logic and UI
export default function NotificationPreferences() {
  const {t} = useTranslation();
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);
  const tasksInputRef = useRef<HTMLInputElement>(null);
  const messagesInputRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState<boolean>(true); // email defaults to true if not set
  const [tasks, setTasks] = useState<boolean>(true); // tasks defaults to true if not set
  const [messages, setMessages] = useState<boolean>(true); // messages defaults to true if not set
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);
  const [disablingCategory, setDisablingCategory] = useState<
    NotificationUserPreference_NotificationCategory | undefined
  >();

  const categoryNotificationsEnabled = config.getBoolean(
    'FEATURE_CATEGORY_NOTIFICATIONS_ENABLED'
  );

  const loadNotificationPreferences = async () => {
    setLoading(true);
    try {
      const preferences = (
        await NotificationUserPreferencesService.listNotificationUserPreferences()
      ).preferences;
      preferences.forEach(preference => {
        // Set the email value if previously set
        if (
          preference.channel ===
            NotificationUserPreference_NotificationChannel.EMAIL &&
          preference.statusSet === true &&
          preference.value?.value?.oneofKind === 'enabled'
        ) {
          const value = preference.value.value as {enabled: boolean};
          setEmail(value.enabled);
        }
      });
      setLoading(false);
    } catch (err: any) {
      // Continue to show the loading spinner if the error is an authentication error
      if (!isAuthenticationError(err)) {
        logError(err);
        setLoading(false);
        setLoadingError(true);
      }
    }
  };

  const listCategoryNotificationPreferences = async () => {
    setLoading(true);
    try {
      const response =
        await NotificationUserPreferencesService.listNotificationUserPreferences(
          true
        );
      response.preferences.forEach(preference => {
        if (
          preference.channel ===
            NotificationUserPreference_NotificationChannel.EMAIL &&
          preference.statusSet &&
          preference.value?.value?.oneofKind === 'enabled'
        ) {
          const value = preference.value.value as {enabled: boolean};
          switch (preference.category) {
            case NotificationUserPreference_NotificationCategory.TASK:
              setTasks(value.enabled);
              break;
            case NotificationUserPreference_NotificationCategory.MESSAGE:
              setMessages(value.enabled);
              break;
          }
        }
      });
      setLoading(false);
    } catch (err: any) {
      // Continue to show the loading spinner if the error is an authentication error
      if (!isAuthenticationError(err)) {
        logError(err);
        setLoading(false);
        setLoadingError(true);
      }
    }
  };

  const updateCategoryPreference = async (
    enabled: boolean,
    type: NotificationUserPreference_NotificationCategory
  ) => {
    // Fire the update, reset the state if it fails
    try {
      updateCategory(enabled, type);
      await NotificationUserPreferencesService.batchUpdateNotificationUserPreferences(
        {
          channel: NotificationUserPreference_NotificationChannel.EMAIL,
          value: {
            value: {
              oneofKind: 'enabled',
              enabled,
            },
          },
          statusSet: true,
          category: type,
        }
      );
    } catch (err: any) {
      logError(err);
      updateCategory(!enabled, type);
      setErrorToast(true);
    }
  };

  const updateCategory = (
    enabled: boolean,
    type: NotificationUserPreference_NotificationCategory
  ) => {
    switch (type) {
      case NotificationUserPreference_NotificationCategory.TASK:
        setTasks(enabled);
        break;
      case NotificationUserPreference_NotificationCategory.MESSAGE:
        setMessages(enabled);
        break;
    }
  };

  useEffect(() => {
    // Set page title on initial page load
    document.title = `Verily Me | ${t('notifications-header')}`;
    if (categoryNotificationsEnabled) {
      listCategoryNotificationPreferences();
    } else {
      loadNotificationPreferences();
    }
  }, []);

  useEffect(() => {
    if (!warningModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [warningModalOpen]);

  if (loadingError) {
    return (
      <ErrorView
        title={t('something-went-wrong')}
        subtitle={t('were-having-an-issue')}
        refreshText={t('refresh')}
      />
    );
  }

  if (loading) {
    return (
      <CircularProgress
        useUnlaunchedComponent
        aria-label={t('spinner-label')}
        data-testid="loading-spinner"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
        }}
      />
    );
  }

  const updateEmailPreference = async (enabled: boolean) => {
    setEmail(enabled);
    try {
      await NotificationUserPreferencesService.batchUpdateNotificationUserPreferences(
        {
          channel: NotificationUserPreference_NotificationChannel.EMAIL,
          value: {
            value: {
              oneofKind: 'enabled',
              enabled,
            },
          },
          statusSet: true,
          category: NotificationUserPreference_NotificationCategory.MESSAGE,
        },
        {
          channel: NotificationUserPreference_NotificationChannel.EMAIL,
          value: {
            value: {
              oneofKind: 'enabled',
              enabled,
            },
          },
          statusSet: true,
          category: NotificationUserPreference_NotificationCategory.TASK,
        }
      );
    } catch (err: any) {
      // revert the switch change if there is an error
      setEmail(!enabled);
      setErrorToast(true);
      logError(err);
    }
  };

  const sections: Array<{
    title?: string;
    subtitle?: string;
    cardTitle: string;
    switch: JSX.Element;
  }> = [];
  if (categoryNotificationsEnabled) {
    sections.push(
      {
        title: t('tasks'),
        subtitle: t('unsubscribe-tasks-subtitle'),
        cardTitle: t('email-box'),
        switch: (
          <Switch
            useUnlaunchedComponent
            data-testid="tasks-switch"
            inputProps={{'aria-label': t('tasks-switch')}}
            inputRef={tasksInputRef}
            checked={tasks}
            onChange={() => {
              if (tasks) {
                setDisablingCategory(
                  NotificationUserPreference_NotificationCategory.TASK
                );
                setWarningModalOpen(true);
                return;
              } else {
                updateCategoryPreference(
                  true,
                  NotificationUserPreference_NotificationCategory.TASK
                );
              }
            }}
            sx={{
              position: 'absolute',
              zIndex: 2,
              right: 0,
              // TODO - Remove `before psuedo class` once RDS supports android a11y styling
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 48,
                height: 48,
              },
            }}
            pendo-task-email-settings={
              tasks ? 'switchEmailSettingOn' : 'switchEmailSettingOff'
            }
          />
        ),
      },
      {
        title: t('messages'),
        subtitle: t('unsubscribe-messages-subtitle'),
        cardTitle: t('email-box'),
        switch: (
          <Switch
            useUnlaunchedComponent
            data-testid="messages-switch"
            inputProps={{'aria-label': t('messages-switch')}}
            inputRef={messagesInputRef}
            checked={messages}
            onChange={() => {
              if (messages) {
                setDisablingCategory(
                  NotificationUserPreference_NotificationCategory.MESSAGE
                );
                setWarningModalOpen(true);
                return;
              } else {
                updateCategoryPreference(
                  true,
                  NotificationUserPreference_NotificationCategory.MESSAGE
                );
              }
            }}
            sx={{
              position: 'absolute',
              zIndex: 2,
              right: 0,
              // TODO - Remove `before psuedo class` once RDS supports android a11y styling
              '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 48,
                height: 48,
              },
            }}
            pendo-message-email-settings={
              messages ? 'switchEmailSettingOn' : 'switchEmailSettingOff'
            }
          />
        ),
      }
    );
  } else {
    sections.push({
      cardTitle: t('notification-email'),
      switch: (
        <Switch
          useUnlaunchedComponent
          data-testid="email-switch"
          inputProps={{'aria-label': t('email-switch')}}
          inputRef={inputRef}
          checked={email}
          onChange={() => {
            if (email) {
              // If email is being turned off, show the warning modal first
              setWarningModalOpen(true);
              return;
            } else {
              updateEmailPreference(true);
            }
          }}
          pendo-email-settings={
            email ? 'switchEmailSettingOff' : 'switchEmailSettingOn'
          }
          sx={{
            position: 'absolute',
            zIndex: 2,
            right: 0,
            // TODO - Remove `before psuedo class` once RDS supports android a11y styling
            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 48,
              height: 48,
            },
          }}
        />
      ),
    });
  }

  return (
    <Layout
      header={
        <Header
          headerText={t('notification-preferences')}
          data-id="header-container"
          headerVariant="body1"
          color={theme.palette.text.default}
          hasBackButton
          onBackButtonClick={() => navigateToUrl('/me/profile')}
          backButtonDescription={t('back-button-description')}
        />
      }
    >
      <section>
        <div
          style={{
            paddingTop: theme.spacing(4),
          }}
        >
          {!categoryNotificationsEnabled && (
            <Typography
              component={'h2'}
              variant="h6"
              color={theme.palette.text.primary}
            >
              {t('notification-preferences-title')}
            </Typography>
          )}
          {!categoryNotificationsEnabled && (
            <Typography variant="body2">
              {t('notification-preferences-subtitle')}
            </Typography>
          )}
          <Box
            sx={{paddingY: theme.spacing(2)}}
            data-testid="preferences-container"
          >
            <Stack spacing={3}>
              {sections.map((section, idx) => (
                <Stack spacing={2} key={idx}>
                  {section.title && (
                    <Typography variant="display6" fontSize={'1.125rem'}>
                      {section.title}
                    </Typography>
                  )}
                  {section.subtitle && (
                    <Typography
                      variant="body2"
                      fontSize={'0.875rem'}
                      color={theme.palette.text.muted}
                    >
                      {section.subtitle}
                    </Typography>
                  )}
                  <Card
                    title={section.cardTitle}
                    useUnlaunchedComponent
                    disableFocus
                    disableButton
                    action={{
                      onClick: () => {},
                      IconComponent: section.switch,
                      hasIcon: true,
                    }}
                  />
                </Stack>
              ))}
            </Stack>
            {categoryNotificationsEnabled && (
              <Divider sx={{marginTop: theme.spacing(5)}} />
            )}
            {categoryNotificationsEnabled && (
              <Typography
                variant="body2"
                color={theme.palette.text.muted}
                sx={{marginTop: theme.spacing(5)}}
              >
                {t('notifications-disclaimer')}
              </Typography>
            )}
          </Box>
        </div>
      </section>
      <Snackbar
        ContentProps={{
          sx: {
            maxWidth: 600,
          },
        }}
        data-testid="error-toast"
        color={'general'}
        open={errorToast}
        onClose={() => setErrorToast(false)}
        text={t('error-toast')}
      />
      <>
        {warningModalOpen && (
          <PreferencesOffConfirmationDialog
            dismissAction={() => setWarningModalOpen(false)}
            confirmAction={() => {
              if (categoryNotificationsEnabled) {
                updateCategoryPreference(false, disablingCategory!);
              } else {
                updateEmailPreference(false);
              }
              setWarningModalOpen(false);
              setDisablingCategory(undefined);
            }}
          />
        )}
      </>
    </Layout>
  );
}
