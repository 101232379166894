/**
 * This acts as an intermediary service for Nav, maintaining the component's navigation items
 * between mounts and handling changes due to nested dependencies.
 * If your MFE does not interact with Nav you can remove everything from this file.
 */
import {
  DEFAULT_LOCALES,
  init as initL10n,
  NAV_NAMESPACES,
  PSEUDO_LOCALE,
} from '@verily-src/l10n/src/l10n';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
import {NavItem, type api as VerilyMeApi} from '@verily-src/verily-me-api';
// eslint-disable-next-line node/no-extraneous-import
import {TFunction} from 'i18next';
import {BehaviorSubject} from 'rxjs';
import ConfigurationService from './services/grpc/ConfigurationService';

const items = new BehaviorSubject<NavItem[]>([]);

// TODO: Delete feature flag after merged to production
const configurableCapabilitiesEnabled = config.getBoolean(
  'FEATURE_CONFIGURABLE_CAPABILITIES_ENABLED'
);

// Setup handlers for component to interact with Nav
export const addItemToNav = (route: NavItem) => {
  (api as typeof VerilyMeApi).nav.addItem(route);

  const oldNavItems = items.value;
  items.next([...oldNavItems, route]);
};

/**
 * Removes a route(NavItem) and updates the navItem list
 */
export const removeItemFromNav = (route: NavItem) => {
  // Remove the item from the Nav
  (api as typeof VerilyMeApi).nav.removeItem(route);

  // Update the list of controlled items so we can use it for our internal state
  const controlledItems = items.value.filter(item => item.name !== route.name);
  items.next(controlledItems);
};

/**
 * Use this function to wrap any initialization logic when setting up the initial nav items to register and show.
 */
function initRoutes(t: TFunction) {
  // Home Nav Item
  const homeNavItem = {
    name: t('nav:home'),
    path: '/me/home',
    order: 0,
    icon: 'HomeVerilyMeOutlinedIcon',
    selectedIcon: 'HomeVerilyMeFilledIcon',
  };

  // Discover Nav Item
  const discoverNavItem = {
    name: t('nav:discover'),
    path: '/me/discover',
    order: 1,
    icon: 'ExploreVerilyMeOutlinedIcon',
    selectedIcon: 'ExploreVerilyMeFilledIcon',
  };

  const initialRoutes = [homeNavItem, discoverNavItem];
  items.next(initialRoutes);
  initialRoutes.forEach(async route => {
    // Configurable Capabilities: JITE
    if (configurableCapabilitiesEnabled && route.path === '/me/discover') {
      if (window.location.pathname.includes('/me/')) {
        // Try-catch in case of error connecting to BFF
        try {
          // Caching for performance
          const {cached, fresh} =
            await ConfigurationService.getJiteConfiguration();
          let showDiscover = true;
          if (cached) {
            showDiscover = cached.enabled;
          } else {
            showDiscover = await fresh.then(response => response.enabled);
          }

          if (showDiscover) {
            addItemToNav(route);
          }
        } catch (error) {
          console.error(
            'Error fetching JITE configuration for Discover tab',
            error
          );
        }
      }
    } else {
      addItemToNav(route);
    }
  });
}

// Run initialization function once during import - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
initL10n(
  f => require(`../../l10n/locales/${f}`),
  PHAF_INJECTED_VALUES.INCLUDE_PSEUDO_LOCALE
    ? [...DEFAULT_LOCALES, PSEUDO_LOCALE]
    : DEFAULT_LOCALES,
  NAV_NAMESPACES
).then(t => initRoutes(t));
