import {CircularProgress} from '@mui/material';
import ErrorToast from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorToast/ErrorToast';
import ErrorView from '@verily-src/verily1-verily-me-web-common-typescript/src/components/ErrorView/ErrorView';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import {isAuthenticationError} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/isAuthenticationError';
import useIsMobile from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/useIsMobile';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import UserProfileService from '../../services/UserProfileService';
import logError from '../../utils/logError';

export default function UpdatePassword() {
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  useEffect(() => {
    async function fetchPasswordResetUrl() {
      try {
        setIsLoading(true);
        const {url} = await UserProfileService.getPasswordResetUrl();
        window.location.href = url;
      } catch (err: any) {
        logError(err);
        setErrorMessage(err.message);

        if (!isAuthenticationError(err)) {
          // Render the error page if the initial load fails
          setErrorToast(true);
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    }

    fetchPasswordResetUrl();
  }, []);

  return (
    <>
      <Layout
        header={
          <Header
            headerText={t('profile-header')}
            hasBackButton={isMobile}
            onBackButtonClick={() => (window.location.href = '/me/profile')}
            backButtonDescription={t('back-button-description')}
          />
        }
      >
        <>
          {isLoading && (
            <CircularProgress
              aria-label={t('spinner-label')}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            />
          )}
          {!isLoading && isError && (
            <ErrorView
              title={t('something-went-wrong')}
              subtitle={t('were-having-an-issue')}
              refreshText={t('refresh')}
              logOutPrompt={{
                description: t('log-out-confirmation-text'),
                logOutText: t('log-out-button'),
                cancelText: t('cancel-log-out-button'),
              }}
            />
          )}
          {errorToast && (
            <ErrorToast
              userFriendlyText={t('unknown-error-occurred')}
              errorMessage={errorMessage}
              open={errorToast}
              onClose={() => setErrorToast(false)}
            />
          )}
        </>
      </Layout>
    </>
  );
}
