import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {EnrollmentClient as Client} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server.client';
import {checkEligibility} from './checkEligibility';
import {completeEnrollmentSWRFetcher} from './completeEnrollment';
import {forceLogin} from './forceLogin';
import {getAgreementConsents} from './getAgreementConsents';
import {getConfig} from './getConfig';
import {getUserState, getUserStateSWRFetcher} from './getUserState';
import {recordEnrolleeEvent} from './recordEnrolleeEvent';
import {recordParticipantData} from './recordParticipantData';
import {reportError} from './reportError';

export const client = new Client(
  new GrpcWebFetchTransport({
    baseUrl: '/api',
  })
);

export const api = {
  forceLogin,
  completeEnrollmentSWRFetcher,
  getConfig,
  getUserState,
  getUserStateSWRFetcher,
  recordEnrolleeEvent,
  reportError,
  recordParticipantData,
  checkEligibility,
  getAgreementConsents,
};
